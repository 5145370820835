import * as request from 'utility/request'

export const url = process.env.REACT_APP_API_URL || 'https://www.culturetat.ru';
export const url_graph = `${url}/api/graphql/`;

export const MAPS_V1_URL = 'https://www.culturetat.ru/media/maps_v1';
export const MAPS_V2_URL = 'https://www.culturetat.ru/media/maps_v2';

export const isUdmurt = () => window.location.hostname === 'udmurtkyl.info'

const getUdmurtResearchUid = async () => {
  const path = 'api/research/list/';
  const body = {
    ru_name: 'Диалектологический атлас',
    __limit__: 1,
    __offset__: 0,
  }
  if (isUdmurt()) {
    const { response } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH());
    if (response && response.results && response.results[0]) {
      return response.results[0].uid
    }
  }
  return '-'
}

export const lingLayers = [
  'Lexeme',
  'Term',
  'Variant',
]

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 10,
    },
  },
};

export const UDMURT_CENTER = [56.85, 53.20]
export const udmurtResearchUid = getUdmurtResearchUid();
export const clusterRadius = (isUdmurt()) ? 1 : 10

export const GRADATION_TYPE = [
  'По словесному описанию',
  'По мелкомасштабной карте или абрису',
  'По крупномасштабной карте или снимку',
  'Точная, метровая',
  'Точная, субметровая',
]

export const getWords = (t) => [
  { subj: 'Layer', word: t('what.Layer'), count: 12211 },
  { subj: 'Site', word: t('what.Site'), count: 10329 },
  { subj: 'Radiocarbon', word: t('what.Radiocarbon'), count: 766 },
  { subj: 'Person', word: t('what.Person'), count: 477 },
  { subj: 'Locality', word: t('what.Locality'), count: 455 },
  { subj: 'Excavation', word: t('what.Excavation'), count: 422 },
  { subj: 'Culture', word: t('what.Culture'), count: 329 },
  { subj: 'Assemblage', word: t('what.Assemblage'), count: 278 },
  { subj: 'Monument', word: t('what.Building'), count: 84 },
  { subj: 'EncOrganization', word: t('what.EncOrganization'), count: 73 },
  { subj: 'Artifact', word: t('what.Artifact'), count: 57 },
  { subj: 'District', word: t('what.District'), count: 51 },
]

export const SUBJECT_TABS = [
  {
    type: 'written',
  },
  {
    type: 'created',
  },
]
