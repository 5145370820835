import archQueries from 'components/GSearchPanel/Queries/archQueries';
import encQueries from 'components/GSearchPanel/Queries/encQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import i18n from 'i18next';
import folkloreQueries from 'components/GSearchPanel/Queries/folkloreQueries.ts'
import lingQueries from '../../../GSearchPanel/Queries/lingQueries';

export const searchFields = {
  Artifact: {
    title: i18n.t('common.subjects.artifact'),
    query: listQueries.artifactSearch(),
  },
  Assemblage: {
    title: i18n.t('common.subjects.assemblage'),
    query: archQueries.assemblageSearch(false, true),
  },
  Building: {
    title: i18n.t('common.subjects.monument'),
    query: encQueries.monumentSearch(),
  },
  Culture: {
    title: i18n.t('common.subjects.culture'),
    query: archQueries.cultureSearch(),
  },
  District: {
    title: i18n.t('common.subjects.district'),
    query: encQueries.districtSearch(),
  },
  EncOrganization: {
    title: i18n.t('common.subjects.encorganization'),
    query: encQueries.encOrgsSearch(),
  },
  Excavation: {
    title: i18n.t('common.subjects.excavation'),
    query: archQueries.excavationSearch(),
    key: 'search',
  },
  Layer: {
    title: i18n.t('common.subjects.layer'),
    query: archQueries.layerSearch(),
  },
  Locality: {
    title: i18n.t('common.subjects.locality'),
    query: encQueries.localitySelectSearch(),
    key: 'commonSearch',
  },
  Monument: {
    title: i18n.t('common.subjects.monument'),
    query: encQueries.monumentSearch(),
  },
  Person: {
    title: i18n.t('common.subjects.person'),
    query: encQueries.personSearch(),
  },
  Radiocarbon: {
    title: i18n.t('common.subjects.rdate'),
    query: archQueries.radiocarbonSearch(),
  },
  Site: {
    title: i18n.t('common.subjects.site'),
    query: archQueries.siteSearch(),
  },
  FolkTale: {
    title: i18n.t(`common.subjects.folktales`),
    query: listQueries.folkTaleSearch(),
  },
  FolkVariant: {
    title: i18n.t(`common.subjects.folkvariants`),
    query: folkloreQueries.variantSearch(),
  },
  FolkPerson: {
    title: i18n.t(`common.subjects.folkpersons`),
    query: listQueries.folkPersonSearch(),
  },
  FolkCharacter: {
    title: i18n.t(`common.subjects.folkcharacters`),
    query: listQueries.folkCharacterSearch(),
  },
  // FolkGenreGroup: {
  //   title: i18n.t(`common.subjects.folkgenregroups`),
  //   query: getList.folkGenreGroupSearch(),
  // },
  // FolkClass: {
  //   title: i18n.t(`common.subjects.folkclasses`),
  //   query: folkloreQueries.folkClassSearch(),
  // },
  // FolkType: {
  //   title: i18n.t(`common.subjects.folktypes`),
  //   query: folkloreQueries.folkTypeSearch(),
  // },
  // FolkGenre: {
  //   title: i18n.t(`common.subjects.folkgenres`),
  //   query: folkloreQueries.genreSearch(),
  // },
  // Lexeme: {
  //   title: i18n.t(`common.subjects.lexemes`),
  //   query: lingQueries.lexemeSearch(),
  // },
  // Language: {
  //   title: i18n.t(`common.subjects.languages`),
  //   query: lingQueries.languageSearch(),
  // },
  // Vernacular: {
  //   title: i18n.t(`common.subjects.vernaculars`),
  //   query: lingQueries.vernacularSearch(),
  // },
  // Epoch: {
  //   title: i18n.t(`common.subjects.epochs`),
  //   query: archQueries.epochSearch(),
  // },
  // Dialect: {
  //   title: i18n.t(`common.subjects.dialects`),
  //   query: lingQueries.dialectSearch(),
  // },
}

export const availableRelations = {
  artifacts: {
    relations: [
      'assemblages',
      'cultures',
      'encOrganizations',
      'excavations',
    ],
  },
  assemblages: {
    relations: [
      'artifacts',
      'assemblages',
      'cultures',
      'excavations',
      'layers',
      'monuments',
      'rdates',
    ],
  },
  cultures: {
    relations: [
      'artifacts',
      'assemblages',
      'cultures',
      'layers',
      'monuments',
      'folktales'
    ],
  },
  districts: {
    relations: [
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'sites',
    ],
  },
  encorganizations: {
    relations: [
      'artifacts',
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
      'rdates',
    ],
  },
  excavations: {
    relations: [
      'artifacts',
      'assemblages',
      'layers',
      'monuments',
      'rdates',
      'sites',
    ],
  },
  layers: {
    relations: [
      'assemblages',
      'cultures',
      'excavations',
      'layers',
      'monuments',
    ],
  },
  localities: {
    relations: [
      'districts',
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
      'sites',
    ],
  },
  monuments: {
    relations: [
      'assemblages',
      'cultures',
      'districts',
      'encOrganizations',
      'excavations',
      'layers',
      'localities',
      'monuments',
      'persons',
      'rdates',
      'sites',
    ],
  },
  persons: {
    relations: [
      'encOrganizations',
      'localities',
      'monuments',
      'persons',
    ],
  },
  rdates: {
    relations: [
      'assemblages',
      'encOrganizations',
      'excavations',
      'monuments',
    ],
  },
  sites: {
    relations: [
      'districts',
      'excavations',
      'layers',
      'localities',
      'monuments',
    ],
  },
  folktales: {
    relations: [
      'cultures',
      'epoch',
      'folkgenregrops',
      'folkgenres',
      'folkclasses',
      'folktypes',
      'folkvariants',    

    ]
  },
  folkvariants: {
    relations: [
      'cultures',
      'lexemes',
      'language',
      'vernacular',
      'epochs',
      'dialects',
      'encorganizations',
      'localities',
      'persons',
      'folkpersons',
      'folkcharacters',
      'folktales',

    ]
  },
  folkpersons: {
    relations: [
      'folkvariants',
      'folktales',
      'persons',
    ]
  },
  folkcharacters: {
    relations: [
      'folkvariants',
      'folktales',
    ]
  },
  epochs: {
    relations: [
      'folktales',
      'folkvariants',
    ]
  },
  folkgenres: {
    relations: [
      'folktales',
    ]
  },
  folkgenregroups: {
    relations: [
      'folktales',
    ]
  },
  folkclasses: {
    relations: [
      'folktales',
    ]
  },
  folktypes: {
    relations: [
      'folktales',
    ]
  },
  lexemes: {
    relations: [
      'folkvariants',
    ]
  },
  languages: {
    relations: [
      'folkvariants',
    ]
  },
  vernaculars: {
    relations: [
      'folkvariants',
    ]
  },
  dialects: {
    relations: [
      'folkvariants',
    ]
  },
}
