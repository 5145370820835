import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';

import config from '../config';
import AutoInputGQL from '../../fields/autoInputGQL';

const FormItem = Form.Item;

class CreatePReportForm extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newPublisher: false,
      newPublisherName: ''
    };
  }

  handleSelectPublisher = (data) => {
    if (data.value === 'new') {
      this.setState({ newPublisher: true, newPublisherName: data.typed });
    } else {
      this.setState({ newPublisher: false });
    }
  }

  handleCityChange = (data) => {
    this.props.form.setFieldsValue({ city: data.uid });
  }

  handlePublisherChange = (data) => {
    this.props.form.setFieldsValue({ publisher: data.uid });
  }

  handleOrgChange = (data) => {
    this.props.form.setFieldsValue({ organization: data.uid });
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      t,
    } = this.props;

    const { newPublisher } = this.state;

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.report_type.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('report_type'
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          newObjChanged={this.handleCityChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="city_select"
          label={t('create_panel.modal.addNewReport.city.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.citySearch()}
          t={t}
        />
        <AutoInputGQL
          handleSelect={this.handleSelectPublisher}
          newObjChanged={this.handlePublisherChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="organization"
          label={t('create_panel.modal.addNewReport.publisher.title')}
          addOptionText={t('create_panel.modal.addNewReport.publisher.new')}
          rules={config.publisher}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.publisherSearch()}
          t={t}
        />
        {newPublisher === true &&
          <div>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.ru_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_ru_name', { ...config.ru_name, initialValue: this.state.newPublisherName }
              )(<Input />)}
            </FormItem>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.en_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_en_name', { ...config.en_name, initialValue: this.state.newPublisherName }
              )(<Input />)}
            </FormItem>
            <AutoInputGQL
              newObjChanged={this.handleOrgChange}
              getFieldDecorator={getFieldDecorator}
              formLayout={formItemLayout}
              selectkey="publisher-organization"
              label={t('create_panel.modal.addNewReport.publisher.organization')}
              noAddOption
              params={[{ key: 'search', val: 'value' }]}
              query={listQueries.organizationSearch()}
              t={t}
            />
          </div>
        }
        <FormItem>
          {getFieldDecorator('city', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('publisher', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('organization', {})(
            <Input hidden />)}
        </FormItem>
      </div>
    );
  }
}

export default CreatePReportForm;
