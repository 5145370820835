import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getDistrictFields(d, tabType) {
  const r = d;
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const ret = [
        createCommonField('name', getLangTitle(r)),
      ]

      if (r.interpretations.length) {
        ret.push(createCommonField('date_start', r.interpretations[0].date_start));
        ret.push(createCommonField('date_end', r.interpretations[0].date_end));
      }
      return ret;
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item, index) => {
        const itemObj = {}
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        itemObj.content = [
          createLinkField('name', item.uid, `subjects/${item.label || d.label}`,
            getLangTitle(interpretation)),
          createCommonField('date_start', interpretation.date_start),
          createCommonField('date_end', interpretation.date_end),
        ]
        itemObj.name = getLangTitle(interpretation)
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
