import React from 'react';
import { withFormik } from 'formik';
import {
  Form,
  Input,
  Button,
  Alert,
  Spin,
  Checkbox,
} from 'antd';
import ReactPhoneInput from 'react-phone-input-2'
import CountryCity from 'components/CoutryCity'


const FormItem = Form.Item;

const renderErrors = (error, t) => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`} type="error" />
    )
  });
  return result;
}

const InnerForm = ({
  handleSubmit,
  userInfo = {},
  isLoading,
  isError,
  form,
  t,
}) => {
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };


  const config = {
    phone: {
      rules: [],
      initialValue: userInfo.phone,
    },
    skype: {
      rules: [],
      initialValue: userInfo.skype,
    },
    country: {
      rules: [],
      initialValue: userInfo.country,
    },
    city: {
      rules: [],
      initialValue: userInfo.city,
    },
    skype_hide: {
      rules: [],
      initialValue: userInfo.hide_fields ? userInfo.hide_fields.skype : false,
      valuePropName: 'checked',
    },
    phone_hide: {
      rules: [],
      initialValue: userInfo.hide_fields ? userInfo.hide_fields.phone : false,
      valuePropName: 'checked',
    },
  };
  return (
    <Form onSubmit={handleSubmit} autocomplete="off" className="sign_up_form">
      {isError && renderErrors(isError, t)}
      <FormItem
        label={t('profile.form.phone.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('phone', config.phone)(
          <ReactPhoneInput
            defaultCountry="ru"
          />
        )}
      </FormItem>
      <FormItem
        {...formItemLayout}
      >
        {getFieldDecorator('phone_hide', config.phone_hide)(<Checkbox>{t('profile.form.phone.hide')}</Checkbox>)}
      </FormItem>
      <FormItem
        label={t('profile.form.skype.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('skype', config.skype)(<Input />)}
      </FormItem>
      <FormItem
        {...formItemLayout}
      >
        {getFieldDecorator('skype_hide', config.skype_hide)(<Checkbox>{t('profile.form.skype.hide')}</Checkbox>)}
      </FormItem>
      <CountryCity
        t={t}
        getFieldDecorator={getFieldDecorator}
        userInfo={userInfo}
      />
      {isLoading ?
        <Spin />
        :
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          {t('profile.form.button')}
        </Button>
      }
    </Form>
  )
};

// Wrap our form with the using withFormik HoC
const ProfileTabContact = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }
      const body = {};
      body.hide_fields = {
        skype: fieldsValue.skype_hide,
        phone: fieldsValue.phone_hide,
      };
      body.phone = fieldsValue.phone;
      body.skype = fieldsValue.skype;
      body.country = fieldsValue.country || null;
      body.city = fieldsValue.city || null;
      props.handleSubmit(body);
      setTimeout(setSubmitting(false), 1000);
    });
  },
})(InnerForm));

export default ProfileTabContact;
