import React from 'react'
import i18n from 'i18next'
import { Select } from 'antd'
import { getPropertyByNameString } from 'utility/transformData/constants'
import './index.scss'

const { Option } = Select

const AdvSearchSelect = props => {
  const { onSelect, data, value, t, isPreLoad } = props

  const handleChange = e => {
    onSelect(e.toLowerCase())
  }

  const renderOptions = () => {
    const lang = i18n.language || 'ru'
    const result = []
    data.forEach((item, key) => {
      if (isPreLoad) {
        const name = getPropertyByNameString(item, lang)
        const uid = getPropertyByNameString(item, 'uid')
        result.push(
          <Option value={uid} key={uid}>
            {name}
          </Option>,
        )
        return
      }
      if (item.type === 'Enum') {
        return
      }
      if (!item.search && !item.is_searchable) {
        return
      }
      const name = getPropertyByNameString(item, `t.${lang}`)
      if (item.model) {
        result.push(
          <Option value={key} key={key}>
            {name || key}
          </Option>,
        )
        return
      }
      result.push(
        <Option value={key} key={key}>
          {name || key}
        </Option>,
      )
    })
    return result
  }
  const options = renderOptions()

  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder={t('advanced.select.subject')}
      value={value || undefined}
      optionFilterProp="children"
      onChange={handleChange}
      filterOption={(input, option) =>
        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >=
        0
      }
    >
      {options}
    </Select>
  )
}

export default AdvSearchSelect
