import React from 'react';
import { Modal, Button, Form, Input, InputNumber, Upload, Icon } from 'antd';
import { withFormik } from 'formik';

import '../../index.scss';
import Initials from '../../../Initials';

const FormItem = Form.Item;



const InnerForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleEdit,
  isSubmitting,
  isError,
  form,
  visible,
  loading,
  hide,
  authorCreated,
  t,
}) => {
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const config = {
    birthday: {
      rules: [{
        type: 'number', message: t('create_panel.modal.addNewAuthor.birthday.error'),
      }],
    },
  };

  if (authorCreated) {
    hide()
  }

  const labels = {
    first_name: {
      error: t('create_panel.modal.addNewAuthor.first_name.error'),
    },
    last_name: {
      error: t('create_panel.modal.addNewAuthor.last_name.error'),
    },
    public_name: {
      error: t('create_panel.modal.addNewAuthor.public_name.error'),
    },
    email: {
      error: t('create_panel.modal.addNewAuthor.email.error'),
    },
  }

  if (document.getElementById('last_name') === null) {
    global.isHandleEvent = 'false'
  }

  if (visible === true)
  {
    if (global.isHandleEvent === 'false') {
      global.AutoClearNewAuthor = '0'
    }
  }



  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={700}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t('create_panel.modal.addNewAuthor.title')}
        </h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <Initials
          labels={labels}
          getFieldDecorator={getFieldDecorator}
          formItemLayout={formItemLayout}
          disablePublicName={true}
          t={t}
          form={form}
          opened_flag={visible}
        />
        {/*<FormItem*/}
        {/*  {...formItemLayout}*/}
        {/*  label={t('create_panel.modal.addNewAuthor.birthday.title')}*/}
        {/*>*/}
        {/*  {getFieldDecorator('birthday', config.birthday)(*/}
        {/*    <InputNumber min={1700} max={new Date().getFullYear()} />)}*/}
        {/*</FormItem>*/}
        <FormItem
          wrapperCol={{ offset: 10, span: 6 }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}
const AddAuthorModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      let params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key] && fieldsValue[key] !== -1) {
          params[key] = fieldsValue[key]
        }
      });

      params.coauthorId = props.newCoauthorId;

      props.handleSubmit(params);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));


export default AddAuthorModal;
