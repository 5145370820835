import React, { Component } from 'react';
import { Select, Form } from 'antd';
import fetch from 'isomorphic-fetch';
import i18n from 'i18next';
import { url } from 'utility/constants'

const { Option } = Select;
const FormItem = Form.Item;

let timeout;
let currentValue;

function fetchData(value, props, callback) {
  const { path } = props;
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  function fake() {
    let searchUrl = '';
    const ecnValue = encodeURIComponent(value);
    if (props.getSearchUrl) {
      searchUrl = props.getSearchUrl(url, path, ecnValue);
    } else {
      searchUrl =
        `${url}/${path}/?search=ru_name%3D${ecnValue}%7Cen_name%3D${ecnValue}%7Ctat_name%3D${ecnValue}`;
    }
    fetch(searchUrl, {})
      .then(response => response.json())
      .then((d) => {
        if (currentValue === value) {
          const { results } = d;
          const data = [];
          results.forEach((r) => {
            if (props.textField) {
              data.push({
                value: r.uid,
                text: props.textField(r),
              });
            } else {
              data.push({
                value: r.uid,
                text: r[`${i18n.language}_name`] || r.ru_name,
              });
            }
          });
          callback(data);
        }
      })
      .catch((error) => {
      });
  }

  timeout = setTimeout(fake, 150);
}

class FetchInputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: props.value ? props.value.text : '',
      selectedValue: props.value ? props.value.key : '',
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;
    if (initialValue && initialValue.id) {
      fetch(`${url}/${initialValue.path}/uid/${initialValue.id}/`, {})
        .then(response => response.json())
        .then((d) => {
          this.setState({
            selectedValue: initialValue.id,
            value: d.ru_name,
          })
        })
        .catch((error) => {
        });
    }
  }

  handleChange = (value) => {
    this.setState({ value });
    if (!value) {
      this.setState({
        value,
        selectedValue: '',
      });
    } else {
      this.setState({ value });
    }
    fetchData(value, this.props, data => this.setState({ data }));
  };

  handleSelect = (value, option) => {
    if (this.props.handleSelect) {
      this.props.handleSelect({
        text: value,
        key: option.key,
      });
    }
    this.setState({
      selectedValue: option.key,
    });
  };

  render() {
    const options =
      this.state.data
        .map(d => <Option key={d.value} value={d.text} title={d.text}>{d.text}</Option>);

    const {
      getFieldDecorator,
      inputName,
      rules,
      formItemLayout,
      title,
    } = this.props;
    const selectRules = rules ? [rules] : [];
    return (
      <FormItem
        {...formItemLayout}
        label={title}
      >
        <div
          title={this.state.value}
        >
          {getFieldDecorator(`${inputName}-select`, {
          rules: selectRules,
          initialValue: this.state.value,
        })(

          <Select
            mode="combobox"
            placeholder={this.props.placeholder}
            style={this.props.style}
            defaultActiveFirstOption={false}
            filterOption={false}
            showArrow={false}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {options}
          </Select>

        )}
        </div>
        {getFieldDecorator(inputName, { rules: [], initialValue: this.state.selectedValue })(<input
          type="text"
          name={inputName}
          hidden
        />)}
      </FormItem>
    );
  }
}

export default FetchInputSelect;
