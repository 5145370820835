import React, { useEffect, useState } from 'react'
import { Spin, Tabs } from 'antd'
import { graphql } from 'relay-runtime'
import {
  getPropertyByNameString,
  getLangTitleGraph,
} from 'utility/transformData/constants'
import { addToMap } from 'utility'
import { SUBJECT_TABS } from 'utility/constants'
import ProfileTabResearchTable from './Table'
import ProfileTabResearchModal from './Modal'

import './index.scss'

const { TabPane } = Tabs
const CURRENT_RES_SORT = 10

const researchFragment = graphql`
  fragment ResearchFragment on ResearchNode {
    uid
    label
    ruName
    enName
    tatName
    year
    sciTopic {
      edges {
        node {
          uid
          ruName
          issue{
            edges{
              node{
                direction{
                  edges{
                    node{
                      organization{
                        edges{
                          node{
                            ruName
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    permission
    type {
      edges {
        node {
          ruName
          enName
          tatName
          sort
        }
      }
    }
  }
`

export const RESEARCHES_QUERY = graphql`
  query ResearchQuery {
    me {
      writtenResearches(first: 10000) {
        edges {
          node {
            ...ResearchFragment @relay(mask: false)
          }
        }
      }
      researches(first: 10000) {
        edges {
          node {
            ...ResearchFragment @relay(mask: false)
          }
        }
      }
    }
  }
`

export const getUniqueItemsById = (val, path = 'uid') => {
  const result = new Map()
  val.forEach(item => {
    const key = getPropertyByNameString(item, path)
    if (!result.has(key)) {
      result.set(key, item)
    }
  })
  return [...result.values()]
}

export const researchMapper = ({ me: data }) => {
  const result = new Map()
  const current = new Map()
  const written = new Map()
  const created = new Map()
  const writtenIds = new Map()

  const writtenResearches = getPropertyByNameString(
    data,
    'writtenResearches.edges',
  )
  const researches = getPropertyByNameString(data, 'researches.edges')

  getUniqueItemsById(writtenResearches, 'node.uid').forEach(res => {
    const item = res.node
    const type = getPropertyByNameString(item, 'type.edges.0.node')
    if (type.sort === CURRENT_RES_SORT) {
      addToMap(item, current, getLangTitleGraph(type))
      writtenIds.set(item.uid)
    } else {
      addToMap(item, written, getLangTitleGraph(type))
      writtenIds.set(item.uid)
    }
  })

  getUniqueItemsById(researches, 'node.uid').forEach(res => {
    const item = res.node
    if (!writtenIds.has(item.uid)) {
      const type = getPropertyByNameString(item, 'type.edges.0.node')
      if (type.sort === CURRENT_RES_SORT) {
        addToMap(item, current, getLangTitleGraph(type))
      } else {
        addToMap(item, created, getLangTitleGraph(type))
      }
    }
  })

  result.set('written', written)
  result.set('created', created)
  result.set('current', current)
  return result
}

const ProfileTabResearch = props => {
  const { t, type, researches, isLoading, loadResearches } = props

  const [editUid, setEditUid] = useState(null)
  const [isModal, setIsModal] = useState(false)

  useEffect(() => {
    if (!researches && !isLoading) {
      loadResearches()
    }
  }, [isLoading, loadResearches, researches])

  const editAccess = research => {
    setIsModal(true)
    setEditUid(research.uid)
  }

  const handleClose = () => {
    setIsModal(false)
    setEditUid(null)
    loadResearches()
  }

  const renderTabs = () => {
    if (type === 'current') {
      return (
        <ProfileTabResearchTable
          t={t}
          type="current"
          data={researches}
          isLoading={isLoading}
          editAccess={editAccess}
        />
      )
    }
    return (
      <Tabs defaultActiveKey="0">
        {SUBJECT_TABS.map((tab, index) => (
          <TabPane tab={t(`profile.research.tabs.${tab.type}`)} key={index}>
            <ProfileTabResearchTable
              t={t}
              type={tab.type}
              data={researches}
              isLoading={isLoading}
              editAccess={editAccess}
            />
          </TabPane>
        ))}
      </Tabs>
    )
  }

  return (
    <div>
      {isModal && (
        <ProfileTabResearchModal
          t={t}
          uid={editUid}
          handleClose={handleClose}
        />
      )}
      {renderTabs()}
      {isLoading && <Spin size="large" />}
    </div>
  )
}

export default ProfileTabResearch
