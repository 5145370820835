export const commonFunctions = {
  handleAutoInputChange: data => {
    const { option } = data

    if (!option.props) {
      return
    }

    const key = option.props.objkey;
    if (data.value === 'new') {
      const keyWith = data.handleChangeStateTab({
        title: data.selectType || data.title,
        status: 'new',
        key: (data.newKeyForNew) ? -1 : key,
        value: data.leftLabel === 'SciTopic' ? data.option.props.text : data.typed,
      })
      if (data.selectType !== data.title) {
        data.handleTabAddRelation({
          title: data.title,
          leftLabel: data.leftLabel,
          rightLabel: data.rightLabel,
          connectionField: data.connectionField,
          with: data.selectType || 'research',
          status: 'new',
          key,
          keyWith,
        })
      }
    } else if (option.props.status === 'otherTab') {
      data.handleTabAddRelation({
        title: data.title,
        leftLabel: data.leftLabel,
        rightLabel: data.rightLabel,
        connectionField: data.connectionField,
        with: data.selectType || 'research',
        status: 'new',
        key: option.props.parentkey,
        keyWith: key,
      })
    } else {
      data.handleTabAddRelation({
        title: data.title,
        leftLabel: data.leftLabel,
        rightLabel: data.rightLabel,
        connectionField: data.connectionField,
        with: data.selectType || 'research',
        status: 'selected',
        uid: data.value,
        key,
      })
      if (!data.selectType) {
        data.handleChangeStateTab({
          title: data.title,
          status: 'selected',
          ru_name: option.props.text,
          value: data.leftLabel === 'SciTopic' ? data.option.props.text : data.typed,
          uid: option.props.allData.uid,
          key,
        })
      }
    }
  },
}

export default commonFunctions;
