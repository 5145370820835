import React, { useState } from 'react'
import './index.css'
import { getCookie } from '../../../../utility/cookie'

const Plan = () => {
  const [planValue, setPlaneValue] = useState('')
  const [reportValue, setReportValue] = useState('')
  return (
    <div className="main">
      <div className="block">
        <div className="title">План научной работы</div>
        <input
          className="year"
          type="text"
          value={planValue}
          placeholder="Ведите год"
          onChange={e => {
            setPlaneValue(e.target.value)
          }}
        />
        <a
          className="getBtn"
          href={`https://www.culturetat.ru/api/get_report/author/type/2/${planValue}/?token=${getCookie(
            'token',
          )}`}
          download="План"
        >
          Сформировать
        </a>
      </div>
      <div className="block">
        <div className="title">Отчет о научной работе</div>
        <input
          className="year"
          type="text"
          placeholder="Ведите год"
          value={reportValue}
          onChange={e => {
            setReportValue(e.target.value)
          }}
        />
        <a
          className="getBtn"
          href={`https://www.culturetat.ru/api/get_report/author/type/1/${reportValue}/?token=${getCookie(
            'token',
          )}`}
          download="План"
        >
          Сформировать
        </a>
      </div>
    </div>
  )
}

export default Plan
