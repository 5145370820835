/**
 * @flow
 * @relayHash 74d8f1d3525021c8cfcf3ace84a2ee68
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
export type AccessRoleEnum = "ADMIN" | "AUTHOR" | "CREATOR" | "MODERATOR" | "NOT_ACCESSED" | "PUBLIC_USER" | "SHARED_USER" | "%future added value";
export type showSelectionQueryVariables = {|
  selectionId: string
|};
export type showSelectionQueryResponse = {|
  +selection: ?{|
    +uid: ?string,
    +accessType: ?number,
    +createdAt: ?any,
    +returnedFields: ?any,
    +creator: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +uid: ?string,
          +publicName: ?string,
        |}
      |}>
    |},
    +query: ?any,
    +description: ?string,
    +model: ?string,
    +name: ?string,
    +resultCount: ?number,
    +uids: ?$ReadOnlyArray<?string>,
    +sharedUsers: ?$ReadOnlyArray<?{|
      +uid: ?string,
      +publicName: ?string,
    |}>,
    +permission: ?AccessObjectEnum,
    +isOpen: ?boolean,
    +accessRole: ?AccessRoleEnum,
    +polygonBounds: ?$ReadOnlyArray<?$ReadOnlyArray<?number>>,
    +specifications: ?any,
  |}
|};
export type showSelectionQuery = {|
  variables: showSelectionQueryVariables,
  response: showSelectionQueryResponse,
|};
*/


/*
query showSelectionQuery(
  $selectionId: ID!
) {
  selection(id: $selectionId) {
    uid
    accessType
    createdAt
    returnedFields
    creator {
      edges {
        node {
          uid
          publicName
          id
        }
      }
    }
    query
    description
    model
    name
    resultCount
    uids
    sharedUsers {
      uid
      publicName
      id
    }
    permission
    isOpen
    accessRole
    polygonBounds
    specifications
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "selectionId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "selectionId",
    "type": "ID!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessType",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "returnedFields",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v7 = [
  v2,
  v6
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "query",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resultCount",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uids",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permission",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isOpen",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accessRole",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "polygonBounds",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "specifications",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v20 = [
  v2,
  v6,
  v19
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "showSelectionQuery",
  "id": null,
  "text": "query showSelectionQuery(\n  $selectionId: ID!\n) {\n  selection(id: $selectionId) {\n    uid\n    accessType\n    createdAt\n    returnedFields\n    creator {\n      edges {\n        node {\n          uid\n          publicName\n          id\n        }\n      }\n    }\n    query\n    description\n    model\n    name\n    resultCount\n    uids\n    sharedUsers {\n      uid\n      publicName\n      id\n    }\n    permission\n    isOpen\n    accessRole\n    polygonBounds\n    specifications\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "showSelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": v7
                  }
                ]
              }
            ]
          },
          v8,
          v9,
          v10,
          v11,
          v12,
          v13,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": v7
          },
          v14,
          v15,
          v16,
          v17,
          v18
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "showSelectionQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selection",
        "storageKey": null,
        "args": v1,
        "concreteType": "SelectionNode",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "creator",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorSearchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AuthorSearchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNode",
                    "plural": false,
                    "selections": v20
                  }
                ]
              }
            ]
          },
          v8,
          v9,
          v10,
          v11,
          v12,
          v13,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "sharedUsers",
            "storageKey": null,
            "args": null,
            "concreteType": "AuthorNode",
            "plural": true,
            "selections": v20
          },
          v14,
          v15,
          v16,
          v17,
          v18,
          v19
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba3ef73c4b6bb2163587ac7cc531b52d';
module.exports = node;
