import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  createLinkField,
  fullNameToShort,
  getAttribute,
  getLangTitle,
} from 'utility/transformData/constants';
import React from 'react';
import { Badge } from 'antd';

import getEncorganizationFields from './encorganization';

function sortTypes(types) {
  const ret = {}
  types.forEach(type => {
    const num = type.attributes.EncOrganizationType[0].ru_name
    if (!ret[num]) {
      ret[num] = [];
    }
    ret[num].push(type);
  })

  return ret;
}


export default function getEncOrgTypeFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN:
      return [
        createCommonField('name', getLangTitle(d)),
        createImageField('photo', d.photo),
        createCommonField('year', d.date_start),
        createCommonField('type', getAttribute(d.attributes, 'EncOrganizationType', 'ru_name')),
        createCommonField('desc', getLangTitle(d.interpretations[0], 'desc')),
      ];
    case TAB_TYPE.COLLAPSE: {
      const result = []
      const types = sortTypes(d.results)
      Object.keys(types).forEach(key => {
        const itemObj = {}
        itemObj.childrenItem = {
          subjectType: 'encorganization',
          content: getEncorganizationFields({ results: types[key] }, TAB_TYPE.COLLAPSE,
            key),
        }
        itemObj.name = (<span>
          {key}
          <Badge
            count={types[key].length}
            overflowCount={999}
            style={{
              backgroundColor: '#fff',
              color: '#999',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
              marginLeft: '10px',
            }}
          />
        </span>)
        result.push(itemObj);
      })
      return result;
    }
    default:
      return [];
  }
}
