import L from 'leaflet';
import i18n from 'i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { List, Avatar, Pagination } from 'antd';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/heatmap';
import { url } from 'utility/constants/index';

import WhereMap from './map';
import WhenChart from './chart';
import img from './img.png';
import './index.css';

const { CRS } = L;
const tiles = [
  {
    checked: true,
    name: 'Open Street Map',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c'],
  },
]

const mapStateToProps = state => ({
  where: state.heatmap.where,
  when: state.heatmap.when,
  what: state.heatmap.what,
  bbox: state.heatmap.bbox,
  date: state.heatmap.date,
  subject: state.heatmap.subject,
  heatmap: state.heatmap.heatmap,
  chartData: state.heatmap.chartData,
  listData: state.heatmap.listData,
  listDataCount: state.heatmap.listDataCount,
});

const mapDispatchToProps = {
  whereGetHeatmap: actions.whereGetHeatmap,
  whereGetChart: actions.whereGetChart,
  whereGetData: actions.whereGetData,
  whenGetData: actions.whenGetData,
  whenGetChart: actions.whenGetChart,
  whenGetHeatmap: actions.whenGetHeatmap,
  whatGetHeatmap: actions.whatGetHeatmap,
  whatGetChart: actions.whatGetChart,
  whatGetData: actions.whatGetData,
};

const knowledges = {
  'Layer': ['arch'],
  'Site': ['arch'],
  'Radiocarbon': ['arch'],
  'Person': ['ency'],
  'Locality': ['ency'],
  'Excavation': ['arch'],
  'Culture': ['arch'],
  'Assemblage': ['arch'],
  'Monument': ['arch', 'ency'],
  'EncOrganization': ['ency'],
  'Artifact': ['arch'],
  'District': ['ency'],
}

const RenderKnowledge = (props) => {
  const knows = knowledges[props.label];
  return (
    <div>
      <span className="list-item-type">{props.t('homeResult.science')}</span>
      {knows.map (know => (
        <span className="list-item-type-value">{props.t(`homeResult.${know}`)}</span>
      ))
      }
    </div>
  )
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class HomeResultView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    pageSize: 7,
    page: 1,
    label: '',
  }

  componentDidMount() {
    if (this.props.where) {
      this.props.whereGetHeatmap({
        bbox: this.props.bbox,
      });
      this.props.whereGetChart({
        bbox: this.props.bbox,
      });
      this.props.whereGetData({
        bbox: this.props.bbox,
        limit: this.state.pageSize,
        offset: 0,
      });
    }

    if (this.props.when) {
      this.props.whenGetData({
        date: this.props.date,
        limit: this.state.pageSize,
        offset: 0,
      });
      this.props.whenGetHeatmap({
        date: this.props.date,
        bbox: {
          lpoint: {
            latitude: '35.00120178923449',
            longitude: '-6.328125000000001',
          },
          rpoint: {
            latitude: '72.18180355624855',
            longitude: '160.31250000000003',
          },
        },
      });
      this.props.whenGetChart({
        date: this.props.date,
      });
    }

    if (this.props.what) {
      this.props.whatGetHeatmap({
        subject: this.props.subject,
      });
      this.props.whatGetChart({
        subject: this.props.subject,
      });
      this.props.whatGetData({
        subject: this.props.subject,
        limit: this.state.pageSize,
        offset: 0,
      });
      this.setState({
        label: this.props.subject,
      })
    }
  }

  getLink = (item) => {
    return `${url}\\show\\subjects\\${item.label || this.state.label}\\${item.uid}`
  }

  handleChangePage = (e, size) => {
    this.setState({
      page: e,
    })
    if (this.props.where) {
      this.props.whereGetData({
        bbox: this.props.bbox,
        limit: this.state.pageSize,
        offset: this.state.pageSize * (e - 1),
      });
    }
    if (this.props.when) {
      this.props.whenGetData({
        date: this.props.date,
        limit: this.state.pageSize,
        offset: this.state.pageSize * (e - 1),
      });
    }
    if (this.props.what) {
      this.props.whatGetData({
        subject: this.props.subject,
        limit: this.state.pageSize,
        offset: this.state.pageSize * (e - 1),
      });
    }
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return null;
    } if (type === 'next') {
      return null;
    }
    return originalElement;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="home-result-container">
        <div className="left-panel">
          <div className="left-panel-where left-panel-block">
            <WhereMap
              handleChooseArea={this.handleChooseArea}
              parentMapState={{
                lat: 55.33,
                lng: 51,
                zoom: 3,
                minZoom: 1,
                crs: CRS.EPSG3857,
                maxBounds: [[52.3382986067, 60.5748103744], [57.711244829, 41.9867587146]],
              }}
              t={t}
              maps={tiles}
              heatmap={this.props.heatmap}
            />
          </div>
          <div className="left-panel-when left-panel-block">
            <WhenChart
              chartData={this.props.chartData}
            />
          </div>
        </div>
        <div className="right-panel">
          <div className="right-panel-top">
            <span className="right-panel-top-text">{t('homeResult.yourRequest')}
              <span className="right-panel-top-elem">{this.props.listDataCount} {t('homeResult.elements')}</span>
            </span>
            <Pagination
              className="home-result-pagination-top"
              size="small"
              total={this.props.listDataCount}
              onChange={this.handleChangePage}
              pageSize={this.state.pageSize}
              defaultCurrent={1}
              current={this.state.page}
            />
          </div>
          <List
            itemLayout="horizontal"
            locale={{ emptyText: t('homeResult.loading') }}
            dataSource={this.props.listData}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar className="list-item-avatar" shape="square" src={img} />}
                  title={
                    <div>
                      <a className="list-item-link" href={this.getLink(item)}>
                        {item[`${i18n.language}_name`] || item.interpretations[0][`${i18n.language}_name`]}
                      </a>
                      <div>
                        <span className="list-item-type">{t('homeResult.type')}</span>
                        <span className="list-item-type-value">{t(`subject.${item.label || this.state.label}`)}</span>
                      </div>
                      <RenderKnowledge t={t} label={item.label || this.state.label} />
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          <Pagination
            className="home-result-pagination-bot"
            size="small"
            total={this.props.listDataCount}
            onChange={this.handleChangePage}
            pageSize={this.state.pageSize}
            defaultCurrent={1}
            current={this.state.page}
          />
        </div>
      </div>
    )
  }
}

export default HomeResultView;
