import React from 'react'
import { getidentifier } from 'components/GSearchPanel/Queries/constants'
import { pageWithNoSort as page } from './queries'

export const writingQueries = {
  wrMonSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:wrMonuments(identifier_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              identifier
            }
          }
        }
      }`,
      url: 'subjects/WrMonument',
      fields: {
        name: {
          data: getidentifier,
        },
      },
    }
  },
  wrCodexSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:wrCodexes(
          identifier_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              identifier
            }
          }
        }
      }`,
      url: 'subjects/WrCodex',
      fields: {
        name: {
          data: getidentifier,
        },
      },
    }
  },
  wrWorkSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:wrWorks(
          identifier_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              identifier
            }
          }
        }
      }`,
      url: 'subjects/WrWork',
      fields: {
        name: {
          data: getidentifier,
        },
      },
    }
  },
  wrManuscriptSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:wrManuscripts(
          identifier_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              identifier
            }
          }
        }
      }`,
      url: 'subjects/WrManuscript',
      fields: {
        name: {
          data: getidentifier,
        },
      },
    }
  },
  wrEpigraphicSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:wrEpigraphics(
          identifier_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              identifier
            }
          }
        }
      }`,
      url: 'subjects/WrEpi',
      fields: {
        name: {
          data: getidentifier,
        },
      },
    }
  },
}
