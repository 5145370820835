import React from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  InputNumber,
  Upload,
  Icon,
  message,
  Select,
} from 'antd'
import { withFormik } from 'formik'
import { listQueries } from 'components/CreatePanel/queries/getList'
import i18n from 'i18next'

import '../../index.scss'
import config from './config'
import AutoInputGQL from '../fields/autoInputGQL'
import AddPeriodicalModal from './newPeriodical'

const FormItem = Form.Item
const { OptGroup, Option } = Select

const InnerForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleEdit,
  isSubmitting,
  isError,
  form,
  visible,
  loading,
  hide,
  periodicalVisible,
  periodicalHide,
  periodicalHandleSubmit,
  periodicalHandleChangeSelector,
  newPeriodicalSelected,
  newPublisher,
  newPublisherName,
  handleSelectPublisher,
  createData,
  t,
}) => {
  const { getFieldDecorator } = form

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  }

  const handlePeriodicalChange = data => {
    form.setFieldsValue({ periodical: data.uid })
  }

  if (createData.newPeriodicalIssue) {
    hide()
  }

  if (createData.newPeriodical) {
    form.setFieldsValue({
      periodical_select: createData.data.periodical[`${i18n.language}_name`],
      periodical: createData.data.periodical.uid,
    })
    newPeriodicalSelected()
  }

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>{t('create_panel.modal.addPeriodicalIssue.title')}</h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name')(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name')(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={'Год'}
        >
          {getFieldDecorator('date')(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi')(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.volume.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('volume', config.volume)(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.number.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('number', config.number)(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.edition.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('issue', config.edition)(<Input />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={periodicalHandleChangeSelector}
          newObjChanged={handlePeriodicalChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="periodical_select"
          label={t('create_panel.modal.addNewReport.periodical.title')}
          addOptionText={t('create_panel.modal.addNewReport.create.periodical')}
          rules={config.periodical}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.periodicalSearch()}
          t={t}
        />
        <FormItem>
          {getFieldDecorator('periodical', {})(<Input hidden />)}
        </FormItem>
        <FormItem wrapperCol={{ offset: 10, span: 6 }}>
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>

      <AddPeriodicalModal
        visible={periodicalVisible}
        hide={periodicalHide}
        loading={loading}
        handleSubmit={periodicalHandleSubmit}
        newPeriodical={createData.newPeriodical}
        newPublisher={newPublisher}
        newPublisherName={newPublisherName}
        handleSelectPublisher={handleSelectPublisher}
        t={t}
      />
    </Modal>
  )
}

const AddPeriodicalIssueModal = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }

        const params = {}

        Object.keys(fieldsValue).forEach(key => {
          if (fieldsValue[key]) {
            params[key] = fieldsValue[key]
          }
        })

        params.tat_name = params.ru_name
        props.handleSubmit(params)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default AddPeriodicalIssueModal
