import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  createLinkField,
  getAttribute,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getBuildingFields(d, tabType) {
  const r = d;
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const result = [
        createCommonField('name', getLangTitle(r.interpretations[0])),
        createImageField('photo', r.photo),
      ]
      const sd = r.interpretations.filter(i => i.start_date)[0]
      if (r.heritage) {
        result.push(
          createLinkField('heritage', r.heritage.uid, `subjects/Heritage`, getLangTitle(r.heritage)),
        )
      }
      if (sd && sd.start_date) {
        result.push(
          createCommonField('year', sd.start_date),
        )
      } else if (r.heritage && r.heritage.date) {
        result.push(
          createCommonField('year', r.heritage.date),
        )
      }
      if (r.address && r.address.ru_name) {
        result.push(
          createCommonField('address', r.address.ru_name)
        )
      }
      result.push(
        createCommonField('architect', r.architect)
      )
      result.push(createCommonField('style', r.style))
      const desc = r.interpretations.filter(i => i.ru_desc)[0]
      if (desc && desc.ru_desc) {
        result.push(
          createCommonField('desc', desc.ru_desc),
        )
      }

      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      const data = (d.response && d.response.results) ? d.response.results : d.results;
      return data.map((item, index) => {
        const itemObj = {}
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        itemObj.content = [
          createLinkField('name', item.uid, `subjects/${item.label || d.label}`,
            getLangTitle(interpretation)),
          createImageField('photo', item.photo),
          createCommonField('year', interpretation ? interpretation.date_start : ''),
          createCommonField('architect', item.architect),
          createCommonField('style', item.style),
          createCommonField('desc', getLangTitle(interpretation, 'desc')),
        ]
        itemObj.name = getLangTitle(interpretation)
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
