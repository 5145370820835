import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
} from 'utility/transformData/constants';
import { getVariant } from 'distLibs/images/variant/index';
import getVariantFields from 'utility/transformData/fields/variant';
import { I18n } from 'react-i18next';

function sortVariants(variants) {
  const ret = []
  variants.forEach(variant => {
    const num = (variant.node.edgeLexeme) ? variant.node.edgeLexeme.groupNumber : 1;
    if (!ret[num]) {
      ret[num] = [];
    }
    ret[num].push(variant);
  })

  return ret;
}

export default function getLexemeGroupFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      const result = [
        createCommonField('name', item.names.join(', ')),
        createCommonField('sense', item.sense),
        createLinkField('term', item.term.uid, 'subjects/Term', item.term.ru_name),
        createLinkField('lexeme', item.lexeme.uid, 'subjects/Lexeme', item.lexeme.name),
      ];
      if (item.lexeme.lexeme_attributes && item.lexeme.lexeme_attributes.Term) {
        result.push(
          createCommonField('term', item.lexeme.lexeme_attributes.Term[0].name),
        )
      }
      if (item.lexeme.lexeme_attributes && item.lexeme.lexeme_attributes.Language) {
        result.push(
          createCommonField('language', getLangTitle(item.lexeme.lexeme_attributes.Language[0])),
        )
      }
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      const result = []
      const groups = sortVariants(d.results)
      groups.forEach((item, key) => {
        const itemObj = {}
        itemObj.content = []
        itemObj.childrenItem = {
          subjectType: 'variant',
          content: getVariantFields({ results: item, lexemeIndex: d.lexemeIndex }, TAB_TYPE.COLLAPSE, key),
        }
        const image = getVariant({ lexemeIndex: d.lexemeIndex, groupIndex: (key - 1) });
        itemObj.name = (<span>
          {image && <img src={image} alt="layer" />}
          <I18n>
            {(t) => (<span>{`${t('common.fonetic')} ${key}`}</span>)}
          </I18n>
        </span>);
        result.push(itemObj);
      })
      return result;
    }
    default:
      return [];
  }
}
