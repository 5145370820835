import React, { Component, Fragment } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button, Icon } from 'antd';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import lingQueries from 'components/GSearchPanel/Queries/lingQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import CoordinatesInput from '../fields/coordinatesInput';
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class LanguageTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = `${this.newTabIndex++}`;
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state;
    newName[key] = data.typed;
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = (input) => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      createData,
      subjects,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props;

    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel header={`${t('create_panel.tab.language.language')} #${1 + key}`} key={title + item.key}>
              <AutoInputGQL
                handleSelect={(data) => {this.handleSelect(data, key)}}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.language.choose')}
                selectkey={`language-select-${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.language.add')}
                query={lingQueries.languageSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel={'Language'}
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') &&
              <div>
                {item.status === 'new' &&
                  <Fragment>
                    <FormItem
                      {...formInputLayout}
                      label={t('create_panel.tab.language.name')}
                    >
                      {getFieldDecorator(`language_name_${key}`, {
                        ...config.req,
                        initialValue: this.state.newName[key],
                      })(
                        <Input onChange={this.handleChangeName} data-objkey={item.key}/>)}
                    </FormItem>
                    <FormItem
                  {...formInputLayout}
                  label={t('common.description')}
                >
                  {getFieldDecorator(`language_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `language_desc_${key}`)} />)}
                </FormItem>
                  </Fragment>
                }
                
                <RelationComponent
                  handleAddExtraRelation={handleAddExtraRelation}
                  getFieldDecorator={getFieldDecorator}
                  formLayout={formInputLayout}
                  objkey={key}
                  title="Language"
                  tabName="languages"
                  stateTabs={stateTabs}
                  form={form}
                  t={t}
                />
              </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.language.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default LanguageTab;
