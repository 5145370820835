import React, { Component } from 'react'
import { Form, Input, DatePicker, Button, Col, Icon } from 'antd'
import moment from 'moment'

import './index.css'
import { formItemLayout } from '../../../utility/constants'

const FormItem = Form.Item

const disabledDate = current => {
  // Can not select days after today
  return current && current > moment()
}

const createMapFromCount = count => {
  const map = new Map()
  for (let i = 0; i < count; i++) {
    map.set(i, i)
  }
  return map
}

class OrganizationNameList extends Component {
  static getDerivedStateFromProps(props, state) {
    if (
      props.initialValue.previous_names &&
      props.initialValue.previous_names.length &&
      props.initialValue.previous_names.length !== state.nameIndexList.size &&
      state.nameIndexList.size === 0
    ) {
      const newNameMap = createMapFromCount(
        props.initialValue.previous_names.length
      )
      return {
        nameIndexList: newNameMap,
        lastKey: newNameMap.size,
      }
    }
    return {}
  }

  state = {
    nameIndexList: this.props.initialValue.previous_names
      ? createMapFromCount(this.props.initialValue.previous_names.length)
      : new Map(),
    lastKey: this.props.initialValue.previous_names
      ? this.props.initialValue.previous_names.length
      : 0,
  }

  getNameMap = () => this.state.nameIndexList

  handleAddButton = () => {
    const { nameIndexList, lastKey } = this.state
    nameIndexList.set(lastKey, lastKey)
    this.setState({
      nameIndexList,
      lastKey: lastKey + 1,
    })
  }

  handleRemoveItem = key => {
    const { nameIndexList } = this.state
    nameIndexList.delete(key)
    this.setState({
      nameIndexList,
    })
  }

  renderItems = () => {
    const result = []
    const { nameIndexList } = this.state
    const { t, getFieldDecorator } = this.props
    let {
      initialValue: { previous_names },
    } = this.props
    previous_names = previous_names || []
    nameIndexList.forEach((value, key) => {
      result.push(
        <div className="organization-list-item" key={`org-item-${value}`}>
          <FormItem
            {...formItemLayout}
            label={t('organizationAdd.form.prev_name.ru_title')}
          >
            {getFieldDecorator(`organization_prev_name_ru-${key}`, {
              rules: [],
              initialValue: previous_names[key]
                ? previous_names[key].ru_name
                : '',
            })(<Input />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={t('organizationAdd.form.prev_name.en_title')}
          >
            {getFieldDecorator(`organization_prev_name_en-${key}`, {
              rules: [],
              initialValue: previous_names[key]
                ? previous_names[key].en_name
                : '',
            })(<Input />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={t('organizationAdd.form.prev_name.tat_title')}
          >
            {getFieldDecorator(`organization_prev_name_tat-${key}`, {
              rules: [],
              initialValue: previous_names[key]
                ? previous_names[key].tat_name
                : '',
            })(<Input />)}
          </FormItem>
          <FormItem
            label={t('organizationAdd.form.prev_name.date.title')}
            {...formItemLayout}
          >
            <Col span={11}>
              <FormItem>
                {getFieldDecorator(`organization_date_from-${key}`, {
                  rules: [],
                  initialValue: previous_names[key]
                    ? moment(previous_names[key].year_since, 'YYYY')
                    : undefined,
                })(<DatePicker disabledDate={disabledDate} format="YYYY" />)}
              </FormItem>
            </Col>
          </FormItem>
          <Icon type="close" onClick={() => this.handleRemoveItem(key)} />
        </div>
      )
    })
    return result
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        {this.renderItems()}
        <Button
          type="default"
          className="organization-add-button"
          onClick={this.handleAddButton}
        >
          {t('organizationAdd.form.prev_name.addButton')}
        </Button>
      </React.Fragment>
    )
  }
}

export default OrganizationNameList
