import React from 'react'
import { Link } from 'react-router-dom'
import { Tag, Button, Table, Empty } from 'antd'
import {
  getResearchUrl,
  getLangTitleGraph,
} from 'utility/transformData/constants'
import { PERMISSION_COLORS } from 'utility/access'
import CustomPanel from 'components/Custom/Panel'
import CustomCollapse from 'components/Custom/Collapse'

import './index.scss'

const ProfileTabResearchTable = props => {
  const { t, data, type, editAccess, isLoading } = props

  const currentData = data ? data.get(type) : new Map()
  const COLUMNS = [
    {
      title: t('profile.research.title'),
      dataIndex: 'ru_name',
      key: 'title',
      sorter: (a, b) =>
        getLangTitleGraph(a).localeCompare(getLangTitleGraph(b)),
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link
            key={record.uid}
            to={`/show/${getResearchUrl(record.label)}/${record.uid}/`}
          >
            {getLangTitleGraph(record)}
          </Link>
          <span>{record.sciTopic && record.sciTopic.edges.length > 0 ? `Научная тема: ${record.sciTopic.edges[0].node.ruName}` : ''}</span>
          <span>{record.sciTopic && record.sciTopic.edges.length > 0 &&
          record.sciTopic.edges[0].node.issue.edges[0].node.direction.edges[0].node.organization.edges.length
            ? `Организация, в которой выполнен проект: ${record.sciTopic.edges[0].node.issue.edges[0].node.direction.edges[0].node.organization.edges[0].node.ruName}` : ''}</span>
        </div>

      ),
    },
    {
      title: t('profile.research.year'),
      dataIndex: 'year',
      sorter: (a, b) => a.year - b.year,
      key: 'year',
    },
    {
      title: t('access.name'),
      dataIndex: 'permission',
      sorter: (a, b) => a.permission.localeCompare(b.permission),
      key: 'permission',
      render: permission => (
        <Tag color={PERMISSION_COLORS[permission]}>
          {t(`access.${permission}`)}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button onClick={() => editAccess(record)}>
            {t('profile.research.access-edit')}
          </Button>
        </span>
      ),
    },
  ]

  const renderPanels = () => {
    const result = []
    currentData.forEach((value, key) => {
      result.push(
        <CustomPanel count={value.length} title={key} key={key}>
          <Table
            loading={isLoading}
            columns={COLUMNS}
            dataSource={value}
            rowKey="uid"
          />
        </CustomPanel>,
      )
    })
    return result
  }

  if (!isLoading && (!currentData || currentData.size === 0)) {
    return <Empty />
  }

  return (
    <div>
      <CustomCollapse>{renderPanels()}</CustomCollapse>
    </div>
  )
}

export default ProfileTabResearchTable
