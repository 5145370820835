import {
  TAB_TYPE,
  createCommonField,
  createChartField,
  createLinkField,
  getAttribute,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getPopulationFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.COLLAPSE: {
      const data = d.data.sort((a, b) => parseInt(a.date, 10) - parseInt(b.date, 10));
      const result = [];
      data.forEach(r => {
        const desc = r.description || 'чел.'
        result.push(createCommonField('empty', `${r.date} г. – ${r.population} ${desc}`))
      })
      result.push(createChartField('empty', data))
      return result
    }
    default:
      return [];
  }
}
