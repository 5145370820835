import React, { PureComponent } from 'react';
import { Modal, List } from 'antd';

import './index.scss';
import SiteTab from './templates/tabs/sites';
import LayerTab from './templates/tabs/layers';
import ArtifactTab from './templates/tabs/artifacts';
import ExcavationTab from './templates/tabs/excavations';
import RdateTab from './templates/tabs/rdates';
import AssemblageTab from './templates/tabs/assemblages';
import PersonTab from './templates/tabs/persons';
import SciTopicTab from './templates/tabs/sciTopic';
import LocalityTab from './templates/tabs/localities';
import BuildingTab from './templates/tabs/buildings';
import EncorganizationTab from './templates/tabs/encorganizations';
import LexemTab from './templates/tabs/lexems';
import CultureTab from './templates/tabs/culture';
import DistrictTab from './templates/tabs/district';
import LanguageTab from './templates/tabs/languages';
import FolkTaleTab from './templates/tabs/folktales';
import FolkVariantTab from './templates/tabs/folkvariants';
import FolkPersonTab from './templates/tabs/folkpersons';
import FolkCharacterTab from './templates/tabs/folkcharacters';

const tabsData = [
  {
    title: 'sciTopic',
    content: SciTopicTab,
  },
  {
    title: 'persons',
    content: PersonTab,
  },
  {
    title: 'districts',
    content: DistrictTab,
  },
  {
    title: 'localities',
    content: LocalityTab,
  },
  {
    title: 'monuments',
    content: BuildingTab,
  },
  {
    title: 'encorganizations',
    content: EncorganizationTab,
  },

  {
    title: 'sites',
    content: SiteTab,
  },
  {
    title: 'layers',
    content: LayerTab,
  },
  {
    title: 'assemblages',
    content: AssemblageTab,
  },
  {
    title: 'excavations',
    content: ExcavationTab,
  },
  {
    title: 'artifacts',
    content: ArtifactTab,
  },
  {
    title: 'rdates',
    content: RdateTab,
  },
  {
    title: 'cultures',
    content: CultureTab,
  },

  {
    title: 'lexemes',
    content: LexemTab,
  },
  // {
  //   title: 'languages',
  //   content: LanguageTab,
  // },
  // {
  //   title: 'folktales',
  //   content: FolkTaleTab,
  // },
  // {
  //   title: 'folkvariants',
  //   content: FolkVariantTab,
  // },
  // {
  //   title: 'folkpersons',
  //   content: FolkPersonTab,
  // },
  // {
  //   title: 'folkcharacters',
  //   content: FolkCharacterTab,
  // },
]

class AddTabModal extends PureComponent {
  render() {
    const { addNewTab, hide, t } = this.props

    return (
      <Modal
        visible={this.props.visible}
        footer={null}
        mask={false}
        closable={false}
        onCancel={hide}
        width={250}
        className="create-panel-add-tab-modal"
      >
        <List
          itemLayout="horizontal"
          dataSource={tabsData}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={t(`tab.${item.title}`)}
                onClick={() => addNewTab(item)}
              />
            </List.Item>
          )}
        />
      </Modal>
    )
  }
}

export default AddTabModal
