import fetch from 'isomorphic-fetch'
import { fetchQuery, commitMutation } from 'relay-runtime'
import { url, url_graph } from 'utility/constants'
import environment from 'graphqlEnv'
import axios from 'axios'
import { store } from 'index'
import i18n from 'i18next'

import { getCookie } from './cookie'

const CORS_CREDENTIALS = {
  credentials: 'include',
}

export const GET_HEADER_EMPTY = {
  headers: {
    Language: i18n.language,
  },
}

export const GET_HEADERS = () => {
  if (getCookie('token')) {
    return {
      headers: {
        Authorization: `Token ${getCookie('token')}`,
        Language: i18n.language,
      },
    }
  }
  return GET_HEADER_EMPTY
}

export const GET_HEADERS_SMALL = () => {
  if (getCookie('token')) {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `token ${getCookie('token')}`,
      },
    }
  }
  return GET_HEADER_EMPTY
}

export const HEADERS_POST_ADDITIONAL = (additionalData = {}) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Language: i18n.language,
    ...additionalData,
  },
})

export const HEADERS_POST_ADDITIONAL_SMALL = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const HEADERS_POST = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Language: i18n.language,
  },
})

export const HEADERS_POST_AUTH = () => {
  return getCookie('token')
    ? HEADERS_POST_ADDITIONAL({ Authorization: `Token ${getCookie('token')}` })
    : HEADERS_POST()
}

export const HEADERS_POST_AUTH_SMALL = () => {
  return getCookie('token')
    ? HEADERS_POST_ADDITIONAL_SMALL({
        Authorization: `token ${getCookie('token')}`,
      })
    : HEADERS_POST()
}

const getApi = async (
  path,
  headers = GET_HEADERS(),
  method = 'GET',
  destinationURL = url,
) => {
  const response = await fetch(`${destinationURL}/${path}`, {
    ...headers,
    method,
  })
  if (response.ok) {
    if (response.statusText === 'No Content') {
      return { response: [] }
    }
    const data = await response.json()
    return { response: data }
  }
  const data = await response.json()
  return { error: data.error || data }
}

const newGetApi = async (
  path,
  headers = GET_HEADERS(),
  method = 'GET',
  destinationURL = url,
) => {
  const response = await axios({
    ...headers,
    method,
    url: `${destinationURL}/${path}`,
  })
  if (response.status === 204) {
    return { response: 'ok' }
  }
  return { error: true }
}

const postApi = async (
  path,
  formData,
  headers = HEADERS_POST_ADDITIONAL(),
  method = 'POST',
  destinationURL = url,
) => {
  const response = await fetch(`${destinationURL}/${path}`, {
    method,
    ...headers,
    ...CORS_CREDENTIALS,
    body: formData ? JSON.stringify(formData) : {},
  })
  const data = await response.json()
  if (response.ok) {
    return { response: data }
  }
  return { error: data.error || data }
}

const postApiBlob = async (
  path,
  formData,
  headers = HEADERS_POST_ADDITIONAL(),
  method = 'POST',
  destinationURL = url,
) => {
  const response = await axios(`${destinationURL}/${path}`, {
    method,
    ...headers,
    ...CORS_CREDENTIALS,
    responseType: 'blob',
    data: formData ? JSON.stringify(formData) : {},
  })
  return response
}

const postFormImage = async (
  path,
  formData,
  headers = HEADERS_POST_ADDITIONAL(),
  method = 'POST',
  destinationURL = url,
) => {
  const response = await fetch(`${destinationURL}/${path}`, {
    method,
    ...headers,
    ...CORS_CREDENTIALS,
    body: formData || {},
  })
  const data = await response.json()
  if (response.ok) {
    return { response: data }
  }
  return { error: data.error || data }
}

const postFormDataApi = async (
  path,
  fileInfo = null,
  formData,
  headers = HEADERS_POST_ADDITIONAL(),
  method = 'POST',
  action = null,
  destinationURL = url,
) => {
  await axios
    .request({
      ...headers,
      method: 'put',
      url: `${destinationURL}/${path}`,
      data: formData,
      onUploadProgress: p => {
        const percent = p.loaded / p.total
        if (action && fileInfo) {
          store.dispatch(
            action({
              fileId: fileInfo.fileId,
              fileLabel: fileInfo.label,
              percent,
            }),
          )
        }
      },
    })
    .then(response => {
      return { response }
    })
    .catch(error => {
      return { error }
    })
}

export const fetchGrapqlRequest = (query, variables) => {
  return fetchQuery(environment, query, variables)
    .catch(e => console.error('fetchGrapqlRequest error', e))
    .then(data => {
      return data || {}
    })
}

export const fetchGrapqlMutation = (
  mutation,
  variables,
  onCompleted = () => {},
  onError = () => {},
) => {
  commitMutation(environment, {
    mutation,
    variables,
    onCompleted,
    onError,
  })
}

const fetchGraphqlCustom = async (query, variables) => {
  const headers = {
    ...HEADERS_POST_AUTH(),
    method: 'POST',
    body: JSON.stringify({
      query,
      variables,
    }),
  }
  const response = await fetch(url_graph, headers)
  const data = await response.json()
  if (response.ok) {
    return data
  }
  return { error: data.error || data }
}

export const delay = ms => new Promise(res => setTimeout(res, ms))

const tryCatchWrapper = handleError => reqFn => (...args) =>
  reqFn(...args).catch(handleError)

const handleError = e => {
  return { response: false, error: e }
}

const errorHandlerWrapper = tryCatchWrapper(handleError)

export const safelyGet = errorHandlerWrapper(getApi)
export const safelyNewGet = errorHandlerWrapper(newGetApi)
export const safelyPost = errorHandlerWrapper(postApi)
export const safelyPostBlob = errorHandlerWrapper(postApiBlob)
export const safelyFetchGraphqlCustom = errorHandlerWrapper(fetchGraphqlCustom)
export const safelyPostFormData = errorHandlerWrapper(postFormDataApi)
export const safelyPostFormImage = errorHandlerWrapper(postFormImage)
