import React from 'react';
import { Modal, Form, Button, Select, Input } from 'antd';
import { withFormik } from 'formik';
import { getFiles } from 'components/CreatePanel/functions/collectData';
import { getPropertyByNameString } from 'utility/transformData/constants'

const FormItem = Form.Item;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     visible,
                     loading,
                     hide,
                     selections,
                     options,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  const formInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const handleChangeSelection = (data) => {
    form.setFieldsValue({ selection: data });
  }
  const labels = [];
  labels.push(options.label)
  const selectOptions = selections.filter(sel => labels.includes(sel.node.model)).map(d => {
    const author = getPropertyByNameString(d, 'node.creator.edges.0.node.publicName')
    const title = `${d.node.name} (${author})`
    return <Option key={d.node.uid} value={d.node.uid} data-node={d.node} data-form={form}>{title}</Option>
  });

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t(`dataPanel.button.add_selection`)}
        </h2>
      </div>

      <FormItem
        label={t('tools.selection')}
        {...formInputLayout}
      >
        {getFieldDecorator('selections', {
          rules: [{ required: true }],
        })(
          <Select
            showSearch
            placeholder={t('tools.selection_placeholder')}
            defaultActiveFirstOption={false}
            notFoundContent={null}
            onChange={handleChangeSelection}
            className="tools-select"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectOptions}
          </Select>,
        )}
      </FormItem>

      <FormItem>
        {getFieldDecorator('selection', {})(
          <Input hidden />)}
      </FormItem>

      <FormItem
        wrapperCol={{ offset: 10, span: 6 }}
      >
        <Button
          type="primary"
          onClick={handleSubmit}
          className="create-form-button"
          disabled={loading}
        >
          {t('common.add')}
        </Button>
      </FormItem>
    </Modal>
  );
}

const AddSelectionModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      params.selectionUid = params.selection;
      params.subjectUid = [props.objUid];
      props.addToSelection(params);
      setTimeout(setSubmitting(false), 1000);
      props.hide()
    })
  },
})(InnerForm));

export default AddSelectionModal;
