import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as request from 'utility/request';

const en = require('./en.json');
const ru = require('./ru.json');

const lngDetector = new LanguageDetector()
lngDetector.addDetector({
  name: 'customDetector',
  lookup(options) {
    let lng = localStorage.getItem('i18nextLng');
    if (lng === null) {
      if (navigator.languages.includes('ru')) {
        lng = 'ru';
      } else {
        lng = 'en';
      }
      request.safelyPost('api/locale/', { locale: lng });
    }
    return lng;
  },

  cacheUserLanguage(lng, options) {
  },
})

i18n
  .use(lngDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    detection: {
      order: ['customDetector', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    },
    resources: {
      en: {
        translation: en,
      },
      ru: {
        translation: ru,
      },
    },
  });

export default i18n;
