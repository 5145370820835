import React, { PureComponent } from 'react';
import { Tabs } from 'antd';
import { translate } from 'react-i18next';

import AuthorTabComponent from './authorTabComponent'

@translate()
class DataPanelAuthor extends PureComponent {
  render() {
    const {
      t,
      isLoading,
      author,
      tabs,
    } = this.props;
    const { TabPane } = Tabs;

    return (
      <Tabs type="card">
        {!isLoading && tabs.map(tab => (
          <TabPane tab={t(`tab.${tab.name}`)} key={tab.name}>
            <AuthorTabComponent
              tab={tab}
              author={author}
            />
          </TabPane>))
        }
      </Tabs>
    )
  }
}

export default DataPanelAuthor;
