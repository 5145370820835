import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu, Select, Icon } from 'antd';
import { translate } from 'react-i18next';
import i18n from 'i18next';

const { Option } = Select;

@translate()
class Header extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    handleExit: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location !== state.location) {
      return {
        isOpen: false,
        location: props.location,
      }
    }
    return {}
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      // check location to detect page change and close mobile menu
      location: props ? props.location : {},
    };
  }

  handleLangChange = (value) => {
    i18n.changeLanguage(value);
    this.props.changeLanguage({
      locale: value,
    });
  };

  handleExit = (e) => {
    e.preventDefault();
    this.props.handleExit();
  };

  handleToggleMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  renderMenuItems = (t, isMain) => {
    let array = [];
    if (isMain) {
      array = array.filter(item => item.type === 'common')
    }
    return array.map(item => (
      <Menu.Item key={`/${item.link}`}>
        <Link to={`/${item.link}`}>{t(item.label)}</Link>
      </Menu.Item>
    ));
  }

  renderProfileLink = (isAuthenticated, role, t) => {
    const array = [];
    if (isAuthenticated) {
      array.push(
        <Menu.Item key="/profile">
          <Link to="/profile">{t('nav.profile')}</Link>
        </Menu.Item>
      );
      array.push(<Menu.Item key="exit">
        <button
          className="link"
          onClick={(e) => this.handleExit(e)}
        >
          {t('nav.signOut')}
        </button>
      </Menu.Item>);
    } else {
      array.push(
        <Menu.Item key="login">
          <Link to="/login">{t('nav.login')}</Link>
        </Menu.Item>
      );
      array.push(
        <Menu.Item key="sign_up">
          <Link to="/signup">{t('nav.signUp')}</Link>
        </Menu.Item>
      );
    }
    array.push(this.renderLanguageSelect());
    return array;
  }

  renderMainLinks = (isAuthenticated, role, t) => {
    const array = [];
    array.push(
      <Menu.Item key="/" className="main-link">
        <Link to="/">udmurtkyl</Link>
      </Menu.Item>
    );
    array.push(
      <Menu.Item key="/about">
        <Link to="/about">О проекте</Link>
      </Menu.Item>
    );
    array.push(
      <Menu.Item key="/quick-search">
        <Link to="/quick-search">{t('nav.search')}</Link>
      </Menu.Item>
    );
    if (isAuthenticated) {
      array.push(
        <Menu.Item key="/data-entry">
          <Link to="/data-entry">{t('nav.importData')}</Link>
        </Menu.Item>
      );
    }
    return array;
  }

  renderLanguageSelect = () => (
    <Select key="lang" defaultValue={i18n.language} onChange={this.handleLangChange}>
      <Option value="ru" key="ru">Рус</Option>
      <Option value="en" key="en">Eng</Option>
      <Option value="tat" key="tat" disabled>Тат</Option>
    </Select>
  );

  render() {
    const { t, isAuthenticated, role } = this.props;
    const { isOpen } = this.state;
    const { pathname } = this.props.location;
    const isMain = pathname === '/';
    // const items = this.renderMenuItems(t, isMain);
    const mainLinks = this.renderMainLinks(isAuthenticated, role, t);
    const profileLinks = this.renderProfileLink(isAuthenticated, role, t);
    // const langSelect = this.renderLanguageSelect();
    return (
      <div className={`header-container dark${isOpen ? ' opened' : ''}${isMain ? ' is-main' : ''}`}>
        <div className="header-main-link">
          <Menu
            mode="horizontal"
          >
            <Menu.Item key="/" className="main-link">
              <Link to="/">Culturetat</Link>
            </Menu.Item>
          </Menu>
        </div>
        {
          isOpen ?
            <Icon
              type="menu-unfold"
              onClick={this.handleToggleMenu}
            />
            :
            <Icon
              type="menu-fold"
              onClick={this.handleToggleMenu}
            />
        }
        <Menu
          selectedKeys={[pathname]}
          mode={isOpen ? 'vertical' : 'horizontal'}
        >
          {mainLinks}
          <Menu
            className="right-submenu"
            selectedKeys={[pathname]}
            mode={isOpen ? 'vertical' : 'horizontal'}
          >
            {profileLinks}
          </Menu>
        </Menu>
      </div>

    );
  }
}

export default Header;
