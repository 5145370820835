import i18n from 'i18next'
import { getLangName, getItem } from 'components/GSearchPanel/Queries/constants'
import { page } from './queries'

const lang = i18n.language

const lingQueries = {
  termSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:terms(${lang}Name_Icontains: $search,
         ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/Term',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },

  lexemeSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:lexemes(name_Icontains: $search,
         ${page.params}) {
          totalCount
          edges {
            node {
              uid
              name
            }
          }
        }
      }`,
      url: 'subjects/Lexeme',
      fields: {
        name: {
          data: getItem('name'),
          sort: 'name',
        },
      },
    }
  },

  vernacularSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:vernaculars(${lang}Name_Icontains: $search,
         ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/Vernacular',
      fields: {
        name: {
          data: getLangName,
          sort: `${lang}_name`,
        },
      },
    }
  },

  variantSearch: () => {
    return {
      query: `query postQuery($search: String="", $names: String="", ${page.def}) {
        results:variants(sense: $search, names: $names ${page.params}) {
          totalCount
          edges {
            node {
              uid
              names
              sense
            }
          }
        }
      }`,
      url: 'linguistic/variant/search',
      fields: {
        name: {
          data: getItem('names'),
          sort: 'names',
        },
      },
    }
  },
  languageSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:languages(${lang}Name_Icontains: $search,
         ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}name
            }
          }
        }
      }`,
      url: 'subjects/Language',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
  dialectSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:dialects(${lang}Name_Icontains: $search,
         ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/Dialect',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
}

export default lingQueries
