import React, { Component } from 'react';
import { Input, Select, Form, DatePicker, Collapse, Button } from 'antd';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import encQueries from 'components/GSearchPanel/Queries/encQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters';
import moment from 'moment';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import FileUpload from '../fields/fileUpload';
import CoordinatesInput from '../fields/coordinatesInput';
import { commonFunctions } from '../../functions/commonFunctions';
import RelationComponent from '../relations/relations'

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class HeritageTab extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.state = {
      newName: [],
      newDoc: null,
    }
  }

  handleSiteChange = (data) => {
    this.props.form.setFieldsValue({ heritage_site: data.uid });
  }

  handleBuildingChange = (data) => {
    this.props.form.setFieldsValue({ heritage_building: data.uid });
  }

  handleDocumentChange = (data) => {
    if (data.uid) {
      this.setState({ newDoc: data.uid })
      this.props.form.setFieldsValue({ heritage_official_doc_uid: data.uid });
    }
    if (data.uid !== 'new' && data.allData.node.dateOfIssue) {
      this.props.form.setFieldsValue({ heritage_date: moment(data.allData.node.dateOfIssue, 'DD.MM.YYYY')});
      this.props.form.setFieldsValue({ heritage_date_string: data.allData.node.dateOfIssue });
    }
  }

  handleDateChange = (data, string) => {
    this.props.form.setFieldsValue({ heritage_date_string: string });
  }

  render() {
    const {
      handleAddExtraRelation,
      getFieldDecorator,
      createData,
      formInputLayout,
      title,
      interactiveMarkers,
      handleAddMarker,
      filelistChanged,
      form,
      t,
    } = this.props;

    const { newDoc } = this.state;
    const config = {
      req: {
        rules: [{
          required: true, message: t('common.default_error'),
        }],
      },
    }

    return (
      <div>
        <div>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.name')}
          >
            {getFieldDecorator(`heritage_name`, {
              ...config.req,
            })(
              <Input />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.date')}
          >
            {getFieldDecorator(`heritage_date`, {})(
              <DatePicker format="DD.MM.YYYY" placeholder="" onChange={this.handleDateChange} />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.status.title')}
          >
            {getFieldDecorator(`heritage_status`, {
              ...config.req
            })(
              <Select>
                <Option value={0}>{t('create_panel.tab.heritage.status.in_registry')}</Option>
                <Option value={1}>{t('create_panel.tab.heritage.status.revealed')}</Option>
              </Select>)}
          </FormItem>
          <AutoInputGQL
            newObjChanged={this.handleSiteChange}
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            title={this.props.title}
            leftLabel={'Heritage'}
            rightLabel={'Site'}
            connectionField={'site'}
            label={t('create_panel.tab.heritage.site')}
            selectkey={`heritage_site_select`}
            selectType="sites"
            stateTabs={false}
            noAddOption
            query={archQueries.siteSearch()}
            params={[{ key: 'search', val: 'value' }]}
            t={t}
          />
          <AutoInputGQL
            newObjChanged={this.handleBuildingChange}
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            title={this.props.title}
            leftLabel={'Heritage'}
            rightLabel={'Monument'}
            connectionField={'monument'}
            label={t('create_panel.tab.heritage.monument')}
            selectkey={`heritage_monument_select`}
            selectType="monuments"
            stateTabs={false}
            noAddOption
            query={encQueries.monumentSearch()}
            params={[{ key: 'search', val: 'value' }]}
            t={t}
          />
          <CoordinatesInput
            defaultActive
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            title={title}
            subjname="heritage"
            objkey={0}
            handleAddMarker={handleAddMarker}
            interactiveMarkers={interactiveMarkers}
            form={form}
            t={t}
          />
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.address')}
          >
            {getFieldDecorator(`heritage_address`, {
            })(
              <TextArea />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.preservation_boundaries_description')}
          >
            {getFieldDecorator(`preservation_boundaries_description`, {
            })(
              <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `preservation_boundaries_description`)} />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.subject_of_preservation')}
          >
            {getFieldDecorator(`subject_of_preservation`, {
            })(
              <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `subject_of_preservation`)} />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('create_panel.tab.heritage.usability_regime')}
          >
            {getFieldDecorator(`usability_regime`, {
            })(
              <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `usability_regime`)} />)}
          </FormItem>
          <FormItem
            {...formInputLayout}
            label={t('common.description')}
          >
            {getFieldDecorator(`heritage_desc`, {
            })(
              <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `heritage_desc`)} />)}
          </FormItem>
          {newDoc !== 'new' &&
            <AutoInputGQL
              newObjChanged={this.handleDocumentChange}
              getFieldDecorator={getFieldDecorator}
              formLayout={formInputLayout}
              title={this.props.title}
              label={t('common.official_document.title')}
              selectkey={`heritage_official_doc_select`}
              selectType="officialDoc"
              stateTabs={false}
              rules={config.req}
              addOptionText={t('create_panel.tab.document.add')}
              query={listQueries.officialDocSearch()}
              params={[{key: 'search', val: 'value'}]}
              t={t}
            />
          }
          {newDoc === 'new' &&
            <FileUpload
              getFieldDecorator={getFieldDecorator}
              formInputLayout={formInputLayout}
              filelistChanged={filelistChanged}
              fileLists={createData.sourceFilelist}
              dontNeedBufferField={true}
              label={`heritage_files`}
              fileType={`officialDocument`}
              title={t(`common.official_document.title`)}
              t={t}
            />
          }
          <FileUpload
            getFieldDecorator={getFieldDecorator}
            formInputLayout={formInputLayout}
            filelistChanged={filelistChanged}
            fileLists={createData.sourceFilelist}
            label={`heritage_topo`}
            fileType={`photo`}
            title={t(`common.topo.heritage`)}
            t={t}
          />
          <FormItem>
            {getFieldDecorator('heritage_site', {})(
              <Input hidden />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('heritage_building', {})(
              <Input hidden />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('heritage_date_string', {})(
              <Input hidden />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('heritage_official_doc_uid', {})(
              <Input hidden />)}
          </FormItem>
        </div>
      </div>
    );
  }
}

export default HeritageTab;
