import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import encQueries from 'components/GSearchPanel/Queries/encQueries';
import { listQueries } from 'components/CreatePanel/queries/getList';

import config from '../config';
import AutoInputGQL from '../../fields/autoInputGQL';

const FormItem = Form.Item;

class CreateResearchReportForm extends Component  {
  constructor(props) {
    super(props);
  }

  handleOrgChange = (data) => {
    this.props.form.setFieldsValue({ org_id: data.uid });
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      newName,
      t,
    } = this.props;

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', {
              ...config.ru_name,
              initialValue: newName,
            }
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          textField="ru_name"
          selectkey="org"
          newObjChanged={this.handleOrgChange}
          label={t('create_panel.modal.addNewReport.org.title2')}
          noAddOption
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.organizationSearch()}
          t={t}
        />
        <FormItem>
          {getFieldDecorator('org_id', {})(
            <Input hidden />)}
        </FormItem>
      </div>
    );
  }
}

export default CreateResearchReportForm;
