/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SourceFragment$ref: FragmentReference;
export type SourceFragment = {|
  +uid: ?string,
  +ruName: ?string,
  +enName: ?string,
  +tatName: ?string,
  +year: ?number,
  +author: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +publicName: ?string,
        +uid: ?string,
      |}
    |}>
  |},
  +coauthors: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +publicName: ?string,
        +uid: ?string,
      |}
    |}>
  |},
  +label: ?string,
  +$refType: SourceFragment$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "AuthorSearchNodeEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "AuthorSearchNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "publicName",
            "args": null,
            "storageKey": null
          },
          v0
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "SourceFragment",
  "type": "SourceSearchNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ruName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "enName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "tatName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "year",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "author",
      "storageKey": null,
      "args": null,
      "concreteType": "AuthorSearchNodeConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "coauthors",
      "storageKey": null,
      "args": null,
      "concreteType": "AuthorSearchNodeConnection",
      "plural": false,
      "selections": v1
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f948ede694c48bbd93db376b16eb16c';
module.exports = node;
