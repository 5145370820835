import React, { useEffect } from 'react'
import {
 Tabs, Icon, Badge, Spin
} from 'antd'

import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import ExistingUsers from 'components/ExistingUsers'
import { useDataManualApi, useDataApi } from 'utility/fetchGraphHook'
import { actions } from '../../../redux/modules/profile'
import ProfileTabGeneral from '../Tab/General'
import ProfileTabContact from '../Tab/Contact'
import ProfileTabAbout from '../Tab/About'
import ProfileTabResearch, {
  RESEARCHES_QUERY,
  researchMapper,
} from '../Tab/Research'
import ProfileTabSource, { SOURCES_QUERY, sourceMapper  } from '../Tab/Source'
import ProfileTabUserConfirmation from '../Tab/UserConfirmation'
import ProfileTabUserConnection from '../Tab/UserConnection'
import ProfileTabSelection from '../Tab/Selection'
import ProfileTabMessages from '../Tab/Messages'
import Plan from '../Tab/Plan'
import { TO_ME_QUERY, toMeMapper } from '../Tab/Messages/ToMe'
import Statistics from "../Tab/Statistics";

const { TabPane } = Tabs

const ADMIN_ROLE = 'ad'

const GET_TABS_LABELS = (t, messages) => ({
  general: (
    <span>
      <Icon type="idcard" />
      {t('profile.tabs.general')}
    </span>
  ),
  contact: (
    <span>
      <Icon type="phone" />
      {t('profile.tabs.contact')}
    </span>
  ),
  about: (
    <span>
      <Icon type="solution" />
      {t('profile.tabs.about')}
    </span>
  ),
  messages: (
    <span>
      <Icon type="mail" />
      {t('profile.tabs.myMessages')}
      {messages.isLoading && <Spin />}
      {messages.data && messages.data.length > 0 ? (
        <Badge count={messages.data.length} />
      ) : null}
    </span>
  ),
  projects: (
    <span>
      <Icon type="snippets" />
      {t('profile.tabs.research')}
    </span>
  ),
  current_projects: (
    <span>
      <Icon type="form" />
      {t('profile.tabs.myCurrentResearches')}
    </span>
  ),
  selections: (
    <span>
      <Icon type="book" />
      {t('profile.tabs.mySamples')}
    </span>
  ),
  sources: (
    <span>
      <Icon type="inbox" />
      {t('profile.tabs.source')}
    </span>
  ),
  confirmation: (
    <span>
      <Icon type="team" />
      {t('profile.tabs.confirmationUsers')}
    </span>
  ),
  connection: (
    <span>
      <Icon type="fork" />
      {t('profile.tabs.connectionUsers')}
    </span>
  ),
  statistics: (
    <span>
      <Icon type="inbox" />
      {t('profile.tabs.statistics')}
    </span>
  ),
  plan: (
    <span>
      <Icon type="inbox" />
      {t('profile.tabs.plan')}
    </span>
  ),
})

const mapStateToProps = state => ({
  user: state.profile,
})

const mapDispatchToProps = {
  getUserInfo: actions.getUserInfo,
  changeUserInfo: actions.changeUserInfo,
  loadResearches: actions.loadResearches,
  loadSources: actions.loadSources,
  handleOrganizationCountChange: actions.handleOrganizationCountChange,
  // removePhoto: actions.removePhoto,
}

const errorLabels = {
  city: 'contact',
  country: 'contact',
  phone: 'contact',
  skype: 'contact',
  scientific_interests: 'about',
  organizations: 'about',
}

const ProfileResearcher = props => {
  const {
    t,
    handleOrganizationCountChange,
    // removePhoto,
    isMobile,
    role,
    user,
    history,
    section,
    getUserInfo,
    changeUserInfo,
  } = props
  const { error, info, organizationsNum, organizations, tags } = user
  const {
    data: toMeData,
    isLoading: toMeIsLoading,
    isError: toMeIsError,
  } = useDataApi(TO_ME_QUERY, null, null, null, toMeMapper)
  const {
    data: resData,
    isLoading: resIsLoading,
    isError: resIsError,
    doFetch: fetchRes,
  } = useDataManualApi(RESEARCHES_QUERY, null, researchMapper)

  const {
    data: srcData,
    isLoading: srcIsLoading,
    isError: srcIsError,
    doFetch: fetchSrc,
  } = useDataManualApi(SOURCES_QUERY, null, sourceMapper)

  const keys = error ? Object.keys(error) : []
  const groupedError = {}
  keys.forEach(item => {
    groupedError[errorLabels[item] || 'general'] = {
      [item]: error[item],
    }
  })

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const handleSubmit = body => {
    const sendBody = { ...user.info, ...body }
    changeUserInfo(sendBody)
  }

  const handleChangeTab = key => {
    if (history.location.pathname.includes('profile/')) {
      history.push(key)
    } else {
      history.push(`profile/${key}`)
    }
  }

  const TABS_LABELS = GET_TABS_LABELS(t, {
    isLoading: toMeIsLoading,
    data: toMeData,
  })

  return (
    <Tabs
      defaultActiveKey={section || 'general'}
      tabPosition={isMobile ? 'top' : 'left'}
      onChange={handleChangeTab}
    >
      {info && <ExistingUsers t={t} user={info} />}
      <TabPane tab={TABS_LABELS.general} key="general">
        <ProfileTabGeneral
          t={t}
          userInfo={info}
          isError={groupedError.general}
          handleSubmit={handleSubmit}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.contact} key="contact">
        <ProfileTabContact
          t={t}
          userInfo={info}
          isError={groupedError.contact}
          handleSubmit={handleSubmit}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.about} key="about">
        <ProfileTabAbout
          t={t}
          userInfo={info}
          isError={groupedError.about}
          organizations={organizations}
          tags={tags}
          organizationsNum={organizationsNum}
          handleSubmit={handleSubmit}
          handleOrganizationCountChange={handleOrganizationCountChange}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.messages} key="messages">
        <ProfileTabMessages t={t} />
      </TabPane>
      <TabPane tab={TABS_LABELS.projects} key="projects">
        <ProfileTabResearch
          t={t}
          isLoading={resIsLoading}
          loadResearches={fetchRes}
          researches={resData}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.current_projects} key="current_projects">
        <ProfileTabResearch
          t={t}
          type="current"
          isLoading={resIsLoading}
          loadResearches={fetchRes}
          researches={resData}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.selections} key="selections">
        <ProfileTabSelection t={t} user={info} />
      </TabPane>
      <TabPane tab={TABS_LABELS.sources} key="sources">
        <ProfileTabSource
          t={t}
          isLoading={srcIsLoading}
          loadSources={fetchSrc}
          sources={srcData}
        />
      </TabPane>
      <TabPane tab={TABS_LABELS.confirmation} key="confirmation">
        <ProfileTabUserConfirmation t={t} />
      </TabPane>
      {role && role === ADMIN_ROLE && (
        <TabPane tab={TABS_LABELS.connection} key="connection">
          <ProfileTabUserConnection t={t} />
        </TabPane>
      )}
      <TabPane tab={TABS_LABELS.plan} key="plan">
        <Plan t={t} />
      </TabPane>
      <TabPane tab={TABS_LABELS.statistics} key="statistics">
        <Statistics t={t} />
      </TabPane>
    </Tabs>
  )
}

export default translate()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileResearcher),
)
