import {
  put,
  fork,
  takeLatest,
  call,
  cancel,
} from 'redux-saga/effects'
// eslint-disable-next-line import/no-cycle
import { url } from 'utility/constants'
import { graphqlPostEnv } from 'redux/api/graphql'
import {
  getPropertyByNameString,
  getResearchUrl,
} from 'utility/transformData/constants'

// eslint-disable-next-line import/extensions
import { sleep } from './lib.js'

// eslint-disable-next-line import/no-cycle
import * as createFunctions from '../api/create'
import {
  constants as createConstants,
  actions as createActions,
} from '../modules/create'
import { ResearchSubjectsQuery } from '../queries/create'

function getSubjectRequestBody(label, res_id, data) {
  if (label === 'SciTopic') {
    return {
      label,
      res_id: res_id,
      status: data.status,
      uid: data.uid,
    }
  }
  else {
    return {
      label,
      res_id,
      status: data.status,
      uid: data.uid,
      files: data.files,
      files2: data.files2,
      subj_data: {
        ...data.subj_data.node_data,
        research: {
          uid: res_id,
          interpretation: data.subj_data.research,
        },
        attributes: data.subj_data.attributes,
      },
      address: data.subj_data.address,
      sr_data: data.sr_data,
      population: data.population,
    }
  }
}

export function* fetchUploadSpatials(action) {
  const { payload } = action

  yield call(createFunctions.createSpatialReference, payload)
}

export function* fetchUploadFiles(action) {
  const { payload } = action
  const type = action.payload.source ? 'source' : 'subject'
  const fileType = action.payload.source ? 'SourceFile' : payload.type
  const fileLabel = payload.label

  if (payload && payload.fileList) {
    for (let i = 0; i < payload.fileList.length; i++) {
      yield call(
        createFunctions.uploadFile,
        {
          file: payload.fileList[i],
          fileId: i,
          resId: payload.objUid,
          fileType: fileLabel,
          allData: payload,
        },
        type,
        fileType,
      )
    }
    yield put(createActions.uploadFilesSuccess())
  }
}

export function* fetchCreateSite(action) {
  let ruid
  if (action.status === 'new' || action.status === 'selected') {
    let r = {}
    if (action.status === 'new') {
      r = yield call(createFunctions.createSite, action)
    } else {
      if (action.label === 'SciTopic') {
        r = yield call(createFunctions.createInterpretationSciTopic, action)
      }
    else {
        r = yield call(createFunctions.createInterpretation, action)
      }
    }
    const { response } = r

    if (response) {
      ruid = response.uid || action.uid
      if (
        action.sr_data &&
        action.sr_data.points &&
        action.sr_data.points[0] &&
        action.sr_data.points[1]
      ) {
        action.sr_data.subject = ruid
        action.sr_data.research = action.res_id
        const { response, error } = yield call(
          createFunctions.createSpatialReference,
          action,
        )
      }

      if (action.files && action.files.fileList) {
        for (let i = 0; i < action.files.fileList.length; i++) {
          yield call(
            createFunctions.uploadFile,
            {
              file: action.files.fileList[i],
              fileId: i,
              resId: ruid,
              fileType: action.files.label,
              allData: action.files,
            },
            'subject',
            action.files.type,
          )
        }
      }
      if (action.files2 && action.files2.fileList) {
        for (let i = 0; i < action.files2.fileList.length; i++) {
          yield call(
            createFunctions.uploadFile,
            {
              file: action.files2.fileList[i],
              fileId: i,
              resId: ruid,
              fileType: action.files2.label,
              allData: action.files2,
            },
            'subject',
            action.files2.type,
          )
        }
      }
    } else {
    }
  } else {
    yield cancel()
  }
  return ruid
}

export function* fetchCreateCulture(action) {
  let ruid

  const { response, error } = yield call(createFunctions.createCulture, action)

  if (response) {
    ruid = response.uid
  } else {
  }

  return ruid
}

export function* fetchCreateEnsemble(action) {
  let ruid

  const { response, error } = yield call(createFunctions.createEnsemble, action)

  if (response) {
    ruid = response.uid
  } else {
  }

  return ruid
}

export function* fetchCreateSubjRelations(action) {

  const { response, error } = yield fork(
    createFunctions.createSubjRelationV2,
    action.payload.relations[0],
  )

  if (response) {
  } else {
  }
}

export function* fetchCreateRelations(payload, resId) {
  const rels = payload.relations
  const allTasks = []

  for (let i = 0; i < rels.length; i++) {
    const relation = {
      connection_field: rels[i].connectionField,
      left: {
        label: rels[i].leftLabel,
        uid: payload[rels[i].title][rels[i].key].uid,
      },
      right: {
        label: rels[i].rightLabel,
      },
      base: {},
      research: resId,
    }

    if (rels[i].status === 'selected') {
      relation.right.uid = rels[i].uid
    } else if (rels[i].status === 'new') {
      const connectWith = rels[i].with.split('_')[0]
      relation.right.uid = payload[connectWith][rels[i].keyWith].uid
    }

    const task = yield fork(createFunctions.createSubjRelationV2, relation)
    yield sleep(500)
  }

  const eRels = payload.extraRelations
  const keys = Object.keys(eRels)

  for (let q = 0; q < keys.length; q++) {
    for (let i = 0; i < eRels[keys[q]].length; i++) {
      for (let t = 0; t < eRels[keys[q]][i].length; t++) {
        const rel = { ...eRels[keys[q]][i][t] }
        const tabName = rel.tabName
        rel.research = resId
        rel.left.uid = payload[tabName][i].uid

        yield fork(createFunctions.createSubjRelationV2, rel)
      }
    }
  }
}

export function* fetchCreateAddress(address) {
  const { response, error } = yield call(createFunctions.createAddress, address)

  if (response) {
    return response.uid
  }
  return null
}

export function* fetchCreatePopulation(population) {
  const { response, error } = yield call(
    createFunctions.createPopulation,
    population,
  )

  if (response) {
    return response.uid
  }
  return null
}

export function* fetchCreateHeritage(data) {
  const body = getSubjectRequestBody('Heritage', '', data.payload)
  const { response, error } = yield call(createFunctions.createSite, body)

  if (response) {
    body.sr_data.subject = response.uid
    yield call(createFunctions.createSpatialReference, body)

    if (body.files && body.files.fileList) {
      for (let i = 0; i < body.files.fileList.length; i++) {
        yield call(
          createFunctions.uploadFile,
          {
            file: body.files.fileList[i],
            fileId: i,
            resId: response.official_document[0].uid,
            fileType: 'heritage_files',
            allData: body.files,
          },
          'source',
          body.files.type,
        )
      }
    }

    if (body.files2 && body.files2.fileList) {
      for (let i = 0; i < body.files2.fileList.length; i++) {
        yield call(
          createFunctions.uploadFile,
          {
            file: body.files2.fileList[i],
            fileId: i,
            resId: response.uid,
            fileType: 'heritage_topo',
            allData: body.files2,
          },
          'heritage',
          body.files2.type,
        )
      }
    }
    window.open(`${url}/show/subjects/Heritage/${response.uid}/`, '_blank')
  }
}

export function* fetchCreateResearch(action) {
  let resp = {}
  if (action.payload.researchUid) {
    resp.uid = action.payload.researchUid
    resp.label = action.payload.researchLabel
  } else {
    const r = yield call(createFunctions.createResearch, {
      data: action.payload.research,
      researchType: action.payload.researchStudySubject,
    })
    yield sleep(500)
    resp = r.response
  }
  const resId = resp.uid

  const {
    sciTopic,
    sites,
    layers,
    culture,
    cultures,
    ensemble,
    artifacts,
    excavations,
    assemblages,
    persons,
    encorganizations,
    monuments,
    localities,
    rdates,
    heritages,
    districts,
    folkvariants,
    folktales,
    folkcharacters,
    folkpersons,
  } = action.payload
  for (let i = 0; i < sites.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Site', resId, sites[i])
    sites[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < layers.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Layer', resId, layers[i])
    layers[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < artifacts.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Artifact', resId, artifacts[i])
    artifacts[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < excavations.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Excavation', resId, excavations[i])
    excavations[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < assemblages.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Assemblage', resId, assemblages[i])
    assemblages[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < rdates.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Radiocarbon', resId, rdates[i])
    rdates[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < heritages.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Heritages', resId, heritages[i])
    heritages[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < persons.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Person', resId, persons[i])
    persons[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < folktales.length; i++) {
    const id = i
    const body = getSubjectRequestBody('FolkTale', resId, persons[i])
    persons[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < folkvariants.length; i++) {
    const id = i
    const body = getSubjectRequestBody('FolkVariant', resId, persons[i])
    persons[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < folkcharacters.length; i++) {
    const id = i
    const body = getSubjectRequestBody('FolkCharacter', resId, persons[i])
    persons[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < folkpersons.length; i++) {
    const id = i
    const body = getSubjectRequestBody('FolkPerson', resId, persons[i])
    persons[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < sciTopic.length; i++) {
    const id = i
    const body = getSubjectRequestBody('SciTopic', resId, sciTopic[i].data)
    sciTopic[id].data.uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < localities.length; i++) {
    const id = i

    const population = []
    const populationUids = []
    const body = getSubjectRequestBody('Locality', resId, localities[i])
    let counter = 0

    while (body.population[counter]) {
      populationUids.push(
        yield call(fetchCreatePopulation, {
          ...body.population[counter],
          description: '1',
        }),
      )
      population.push({ uid: populationUids[counter] })

      counter++
    }


    if (populationUids.length) {
      body.subj_data.attributes.Population = population
    }


    localities[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < monuments.length; i++) {
    const id = i
    const body = getSubjectRequestBody('Monument', resId, monuments[i])
    let addressUid = null
    if (body.address && (body.address.ru_name || body.address.en_name)) {
      addressUid = yield call(fetchCreateAddress, body.address)
    }
    if (addressUid) {
      body.subj_data.address = [addressUid]
    }
    monuments[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < encorganizations.length; i++) {
    const id = i
    const body = getSubjectRequestBody(
      'EncOrganization',
      resId,
      encorganizations[i],
    )
    encorganizations[id].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < culture.length; i++) {
    const id = i
    culture[id].uid = yield call(fetchCreateCulture, {
      res_id: resId,
      data: culture[i].data,
    })
    yield sleep(500)
  }
  for (let i = 0; i < cultures.length; i++) {
    const body = getSubjectRequestBody('Culture', resId, cultures[i])
    cultures[i].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < districts.length; i++) {
    const body = getSubjectRequestBody('Disctrict', resId, districts[i])
    districts[i].uid = yield call(fetchCreateSite, body)
    yield sleep(500)
  }
  for (let i = 0; i < ensemble.length; i++) {
    const id = i
    ensemble[id].uid = yield call(fetchCreateEnsemble, {
      res_id: resId,
      data: ensemble[i].data,
    })
    yield sleep(500)
  }
  yield call(fetchCreateRelations, action.payload, resId)
  window.open(`${url}/show/${getResearchUrl(resp.label)}/${resId}/`, '_blank')
}

export function* fetchCreateAuthor(action) {
  const { response, error } = yield call(
    createFunctions.createAuthor,
    action.payload,
  )

  response.coauthorId = action.payload.coauthorId

  if (response) {
    yield put(createActions.createAuthorSuccess(response))
  } else {
    yield put(createActions.createAuthorFail())
  }
}

export function* fetchCreatePublisher(action) {
  const org = action.payload.organization
  const body = {
    ru_name: action.payload.publisher_ru_name,
    en_name: action.payload.publisher_en_name,
    tat_name: action.payload.publisher_ru_name,
  }
  if (org && org !== 'new') {
    body.organization = org
  }
  const { response, error } = yield call(createFunctions.createPublisher, body)

  if (response) {
    return response.uid
  }
  return null
}

export function* fetchCreateSource(action) {
  let publisherUid = action.payload.publisher
  if (publisherUid === 'new') {
    publisherUid = yield call(fetchCreatePublisher, action)
  }
  const { response, error } = yield call(createFunctions.createSource, {
    ...action.payload,
    publisher: publisherUid,
  })

  if (response) {
    if (action.payload.files) {
      for (let i = 0; i < action.payload.files.length; i++) {
        yield call(
          createFunctions.uploadFile,
          {
            file: action.payload.files[i],
            fileId: i,
            resId: response.uid,
            fileType: 'source',
            allData: action.payload.files,
          },
          'source',
          'SourceFile',
        )
      }
    }

    if (response) {
      yield put(createActions.createSourceSuccess(response))
    }
  } else {
    yield put(createActions.createSourceFail())
  }
}

export function* fetchCreatePeriodicalIssue(action) {
  const { response, error } = yield call(
    createFunctions.createPeriodicalIssue,
    action.payload,
  )

  if (response) {
    yield put(createActions.createPeriodicalIssueSuccess(response))
  } else {
    yield put(createActions.createPeriodicalIssueFail())
  }
}

export function* fetchCreatePeriodical(action) {
  let publisherUid = action.payload.publisher
  if (publisherUid === 'new') {
    publisherUid = yield call(fetchCreatePublisher, action)
  }
  const { response, error } = yield call(createFunctions.createPeriodical, {
    ...action.payload,
    publisher: publisherUid,
  })

  if (response) {
    yield put(createActions.createPeriodicalSuccess(response))
  } else {
    yield put(createActions.createPeriodicalFail())
  }
}

export function* fetchCreateBookSeries(action) {
  const { response, error } = yield call(
    createFunctions.createSource,
    action.payload,
  )

  if (response) {
    yield put(createActions.createBookSeriesSuccess(response))
  } else {
    yield put(createActions.createBookSeriesFail())
  }
}

export function* fetchCreateBook(action) {
  let publisherUid = action.payload.publisher
  if (publisherUid === 'new') {
    publisherUid = yield call(fetchCreatePublisher, action)
  }
  const { response, error } = yield call(createFunctions.createSource, {
    ...action.payload,
    publisher: publisherUid,
  })

  if (response) {
    yield put(createActions.createBookSuccess(response))
  } else {
    yield put(createActions.createBookFail())
  }
}

export function* fetchSelectResearch(action) {
  const ruid = `ResearchNode:${action.payload.uid}`
  const response = yield call(graphqlPostEnv, {
    query: ResearchSubjectsQuery,
    vars: { ruid },
  })
  const records = getPropertyByNameString(response, 'research.subjects.edges')
  const uids = []

  records.forEach(r => {
    uids.push(r.node.uid)
  })
  yield put(createActions.setResearchSubjects(uids))
  return { uid: action.payload.uid, label: action.payload.label }
}

function* watchUserAccountActions() {
  yield takeLatest(createConstants.CREATE_RESEARCH, fetchCreateResearch)
  yield takeLatest(createConstants.CREATE_HERITAGE, fetchCreateHeritage)
  yield takeLatest(createConstants.CREATE_AUTHOR, fetchCreateAuthor)
  yield takeLatest(createConstants.CREATE_SOURCE, fetchCreateSource)
  yield takeLatest(createConstants.CREATE_SITE, fetchCreateSite)
  yield takeLatest(
    createConstants.CREATE_SUBJECT_RELATIONS,
    fetchCreateSubjRelations,
  )
  yield takeLatest(
    createConstants.CREATE_PERIODICAL_ISSUE,
    fetchCreatePeriodicalIssue,
  )
  yield takeLatest(createConstants.CREATE_PERIODICAL, fetchCreatePeriodical)
  yield takeLatest(createConstants.CREATE_BOOK_SERIES, fetchCreateBookSeries)
  yield takeLatest(createConstants.CREATE_BOOK, fetchCreateBook)

  yield takeLatest(createConstants.UPLOAD_FILES, fetchUploadFiles)
  yield takeLatest(createConstants.UPLOAD_SPATIALS, fetchUploadSpatials)

  yield takeLatest(createConstants.SELECT_RESEARCH, fetchSelectResearch)
}

export const createSaga = [fork(watchUserAccountActions)]
