import { graphql } from 'relay-runtime'

export const SelectionQuery = graphql`
  query toolsSelectionQuery {
    selections {
      edges {
        node {
          uid
          createdAt
          query
          description
          model
          name
          resultCount
          returnedFields
          creator {
            edges {
              node {
                publicName
              }
            }
          }
        }
      }
    }
  }
`
