import React, { Component } from 'react'
import { Form, Tabs, Button, Input, Badge } from 'antd'
import { withFormik } from 'formik'

import './index.scss'
import AddTabModal from './addTabModal'
import { collectData } from './functions/collectData'

const { TabPane } = Tabs
const FormItem = Form.Item

const InnerForm = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  handleChangeStateTab,
  handleTabAddSubj,
  handleTabAddRelation,
  handleAddExtraRelation,
  handleChangeInteractiveMarker,
  handleAddMarker,
  interactiveMarkers,
  handleEdit,
  isSubmitting,
  isError,
  form,
  state,
  onChangeTab,
  showModal,
  hideModal,
  onEditTab,
  addNewTab,
  filelistClear,
  filelistChanged,
  createData,
  t,
}) => {
  const { getFieldDecorator } = form

  const formInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  }

  const operations = (
    <Button onClick={showModal}>{t('create_panel.add_tab')}</Button>
  )

  const renderTabTitle = (title, count) => {
    return (
      <span>
        {title}
        {count && (
          <Badge
            count={count}
            overflowCount={999}
            style={{
              color: 'red',
              backgroundColor: '#fff',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
            }}
          />
        )}
      </span>
    )
  }
  return (
    <div className="create-panel">
      <h1 className="create-panel-header">{t('create_panel.title')}</h1>

      <Form onSubmit={handleSubmit} className="create-form">
        <Tabs
          onChange={onChangeTab}
          activeKey={state.activeKey}
          type="editable-card"
          hideAdd
          onEdit={onEditTab}
          tabBarExtraContent={operations}
        >
          {state.tabs
            .filter(tab => tab.render)
            .map(tab => (
              <TabPane
                tab={renderTabTitle(
                  t(`tab.${tab.title}`),
                  createData.errors[tab.title],
                )}
                key={tab.key}
                closable={tab.closable}
              >
                <tab.content
                  stateTabs={state.tabs}
                  subjects={tab.subj}
                  title={tab.title}
                  handleChangeStateTab={handleChangeStateTab}
                  handleTabAddSubj={handleTabAddSubj}
                  handleTabAddRelation={handleTabAddRelation}
                  handleAddExtraRelation={handleAddExtraRelation}
                  handleChangeInteractiveMarker={handleChangeInteractiveMarker}
                  handleAddMarker={handleAddMarker}
                  interactiveMarkers={interactiveMarkers}
                  getFieldDecorator={getFieldDecorator}
                  formInputLayout={formInputLayout}
                  filelistClear={filelistClear}
                  filelistChanged={filelistChanged}
                  createData={createData}
                  form={form}
                  t={t}
                />
              </TabPane>
            ))}
        </Tabs>
        <FormItem wrapperCol={{ span: 16, offset: 4 }}>
          <Button
            className="create-form-button"
            type="primary"
            htmlType="submit"
            disabled={
              createData.requestSent ||
              (createData.dataType !== 'heritage' &&
                !(createData.newResearch || createData.selectedResearch))
            }
          >
            {t('create_panel.create_button')}
          </Button>
        </FormItem>
      </Form>
      <AddTabModal
        visible={state.addTabVisible}
        hide={hideModal}
        addNewTab={addNewTab}
        t={t}
      />
    </div>
  )
}

const CreatePanel = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          const errors = {}
          Object.keys(err).forEach(key => {
            const label = `${key.split('_')[0]}s`
            if (!errors[label]) {
              errors[label] = 0
            }
            errors[label] += 1
          })
          props.updateErrorsInSubjectFields(errors)
          setSubmitting(false)
          return
        }
        props.updateErrorsInSubjectFields({})

        const params = {}

        Object.keys(fieldsValue).forEach(key => {
          if (fieldsValue[key]) {
            params[key] = fieldsValue[key]
          }
        })

        if (fieldsValue.dataTypeRadio === 'heritage') {
          const body = collectData.heritages(
            fieldsValue,
            props.createData,
            0,
            -1,
            'new',
          )
          props.handleCreateHeritage(body)
        }

        if (fieldsValue.dataTypeRadio === 'research') {
          const tabsData = {
            sciTopic: [],
            sites: [],
            layers: [],
            artifacts: [],
            excavations: [],
            assemblages: [],
            rdates: [],
            heritages: [],
            persons: [],
            monuments: [],
            localities: [],
            encorganizations: [],
            relations: [],
            culture: [],
            cultures: [],
            ensemble: [],
            districts: [],
            folkTales: [],            
            folkVariants: [],
            folkCharacters: [],
            folkPersons: [],
          }

          props.state.tabs.forEach(tab => {
            if (tab.title === 'research') {
              return 0
            }
           tab.subj.length > 0 && tab.subj.forEach((sub, id) => {
              if (sub.title === 'culture' || sub.title === 'ensemble' || sub.title === 'sciTopic') {
                tabsData[sub.title].push({
                  data: {
                    // attributes: {
                    //   Epoch: [{ uid: fieldsValue[`layer_epoch_${id}`] }],
                    // },
                    research: {},
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    ru_name: sub.value || sub.ru_name,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    en_name: sub.value || sub.ru_name,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    tat_name: sub.value || sub.ru_name,
                    uid: sub.uid,
                    ...sub,
                  },
                })
                return 1
              }
              if (sub.status === 'new' || sub.status === 'selected') {
                const uid =
                  sub.status === 'selected'
                    ? tab.relations[id].research.uid || tab.relations[id].uid
                    : -1
                tabsData[sub.title].push(
                  collectData[sub.title](
                    fieldsValue,
                    props.createData,
                    id,
                    uid,
                    sub.status,
                  ),
                )
              }
              return 1
            })

            Object.keys(tab.relations).map(id => {
              Object.keys(tab.relations[id]).map(key => {
                if (key !== 'research') {
                  tabsData.relations.push(tab.relations[id][key])
                }
                return 1
              })
              return 1
            })
            return 1
          })

          const body = {
            ...tabsData,
            extraRelations: props.state.extraRelations || '',
          }
          if (props.createData.newResearch) {
            body.researchStudySubject = params.study_subject
            body.research = {
              // eslint-disable-next-line @typescript-eslint/camelcase
              ru_name: params.ru_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              en_name: params.en_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              tat_name: params.ru_name,
              // eslint-disable-next-line @typescript-eslint/camelcase
              ru_desc: params.research_desc,
              // eslint-disable-next-line @typescript-eslint/camelcase
              en_desc: params.research_desc,
              // eslint-disable-next-line @typescript-eslint/camelcase
              tat_desc: params.research_desc,
              year: params.res_year,
              // eslint-disable-next-line @typescript-eslint/camelcase
              access_type: params.access,
              type: params.research_type,
              knowledge: params.study_subject,
              author: props.createData.data.author.uid,
              collaborators: props.createData.data.collaborators.map(
                c => c.uid,
              ),
              description: 'test',
            }
            if (
              params.research_type !== 'da836d00-c455-40a8-8064-3342fc4fcadf'
            ) {
              body.research.source_ = props.createData.data.source.uid
            }
          } else {
            body.researchLabel = props.createData.data.researchLabel
            body.researchUid = props.createData.data.researchUid
          }
          props.handleCreateResearch(body)
        }
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default CreatePanel
