export const generateConfig = (...props) => {
  const {
    knowledgeType, searchType, t, objectTypes,
  } = props[0];
  return {
    selectKnowledge: {
      initialValue: knowledgeType,
      rules: [{
        message: t('search_panel.form.knowledge_type.error'),
      }],
    },
    selectObject: {
      initialValue: searchType || objectTypes[0] || 'research',
      rules: [{
        message: t('search_panel.form.select.error'),
      }],
    },
  };
}
