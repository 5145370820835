import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
  newLineReg,
} from 'utility/transformData/constants';

export default function getRadiocarbonFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      return [
        createLinkField('author', item.creator ? item.creator.uid : '', 'author',
          item.creator ? fullNameToShort(item.creator) : '-'),
        createCommonField('name', getLangTitle(item)),
        createCommonField('description', <pre>{item.description.replace(newLineReg, '\n')}</pre>),
        ...Object.keys(item.attributes).map(key => (
          createCommonField(item.attributes[key].label, getLangTitle(item.attributes[key]))
        )),
      ];
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item, index) => {
        const itemObj = {}
        const research = item.research || item.interpretations[0] || {}
        itemObj.name = getLangTitle(item)
        itemObj.name += item.r_date && item.dispersion ? ` (${item.r_date} ± ${item.dispersion})` : ''
        itemObj.name += research.context_desc ? `, ${research.context_desc}` : '';
        itemObj.content = [
          createLinkField('name', item.uid, 'subjects/Radiocarbon',
            itemObj.name),
          createCommonField('description', <pre>{item.description.replace(newLineReg, '\n')}</pre>),
          ...Object.keys(item.attributes).map(key => (
            createCommonField(key, item.attributes[key].map(attr => (
              getLangTitle(attr)
            ))
            ))),
        ]
        if (item.creator) {
          itemObj.content.unshift(
            createLinkField('author', item.creator.uid, 'author',
              fullNameToShort(item.creator))
          )
        } else {
          itemObj.content.unshift(
            createCommonField('author', '-'),
          )
        }
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
