import React from 'react';
import { Modal, Form, Button, Input } from 'antd';
import { withFormik } from 'formik';
import CoordinatesInput from 'components/CreatePanel/templates/fields/coordinatesInput';
import AutoInputGQL from 'components/CreatePanel/templates/fields/autoInputGQL';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { getCoords } from 'components/CreatePanel/functions/collectData';

const MapPanel = React.lazy(() => import('components/MapPanel'))


import './index.scss';

const FormItem = Form.Item;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     visible,
                     loading,
                     hide,
                     interactiveMarkers,
                     handleChangeInteractiveMarker,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  const formInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const config = {
    req: {
      rules: [{
        required: true, message: t('common.default_error'),
      }],
    },
  };

  const handleAddMarker = (e) => {
    handleChangeInteractiveMarker({
      key: e.target.dataset.key,
      title: e.target.dataset.title,
    })
  }

  const handleSelectResearch = (e) => {
    form.setFieldsValue({ research: e.value });
  }

  return (
    <Modal
      visible={visible}
      className="create-panel-modal-half"
      onCancel={hide}
      width={1200}
      footer={null}
    >
      <div className='add-spatials-modal-window'>
        <Form onSubmit={handleSubmit} className="add-spatials-modal-left-panel">
          <div className="create-panel-modal-header">
            <h2>
              {t(`dataPanel.button.add_spatials`)}
            </h2>
          </div>

          <CoordinatesInput
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            objkey={0}
            title="addSpatialsModal"
            subjname="addSpatialsModal"
            handleAddMarker={handleAddMarker}
            interactiveMarkers={interactiveMarkers}
            form={form}
            t={t}
          />

          <AutoInputGQL
            handleSelect={handleSelectResearch}
            getFieldDecorator={getFieldDecorator}
            formLayout={formInputLayout}
            label={t('create_panel.tab.research.choose')}
            selectkey="research_select"
            noAddOption
            query={listQueries.researchSearch()}
            params={[{ key: 'search', val: 'value' }]}
            rules={config.req}
            t={t}
          />

          <FormItem>
            {getFieldDecorator('research', {})(
              <Input hidden />)}
          </FormItem>

          <FormItem
            wrapperCol={{ offset: 10, span: 6 }}
          >
            <Button
              type="primary"
              onClick={handleSubmit}
              className="create-form-button"
              disabled={loading}
            >
              {t('common.add')}
            </Button>
          </FormItem>
        </Form>

        <MapPanel
          content={[]}
          className={'add-spatials-modal-map-panel'}
          interactiveMarkers={interactiveMarkers}
          handleChangeInteractiveMarker={handleChangeInteractiveMarker}
          t={t}
        />
      </div>
    </Modal>
  );
}

const AddSpatialsModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      const body = {}

      body.sr_data = getCoords(params, 'addSpatialsModal', 0);
      body.sr_data.knowledge = props.knowledge;
      body.sr_data.research = params.research
      body.sr_data.subject = props.objUid

      props.uploadSpatials(body);
      setTimeout(setSubmitting(false), 1000);
      props.hide();
    })
  },
})(InnerForm));

export default AddSpatialsModal;
