import React from 'react'
import { withFormik } from 'formik'
import { Form, Input, Button, Alert, Spin } from 'antd'
import ChangePasswordModal from 'components/ChangePassword/Modal';

// import { formItemLayout } from '../../../../utility/constants'
import OrganizationNameList from '../../../Organization/NameList'
import OrganizationCreateNew from '../../../Organization/Create/New'

const FormItem = Form.Item

const nameListRef = React.createRef()

const renderErrors = (error, t) => {
  const keys = Object.keys(error)
  const result = []
  keys.forEach(item => {
    result.push(
      <Alert
        key={item}
        message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`}
        type="error"
      />
    )
  })
  return result
}

const InnerForm = ({
  handleSubmit,
  organization,
  isLoading,
  form,
  t,
}) => {
  const { getFieldDecorator } = form
  return (
    <Form onSubmit={handleSubmit} className="ant-form ant-form-horizontal sign_up_form">
      {organization.isError && renderErrors(organization.isError, t)}
      <ChangePasswordModal
        t={t}
      />
      <OrganizationCreateNew
        ref={nameListRef}
        t={t}
        getFieldDecorator={getFieldDecorator}
        initialValue={organization.info}
      />
      {isLoading ? (
        <Spin />
      ) : (
        <Button type="primary" htmlType="submit" className="login-form-button">
          {t('Сохранить')}
        </Button>
      )}
    </Form>
  )
}

// Wrap our form with the using withFormik HoC
const ProfileTabName = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }
        const body = {
          id: props.organization.info.uid,
        }
        const account = {};
        account.tat_name = fieldsValue.tat_name
        account.ru_name = fieldsValue.ru_name
        account.en_name = fieldsValue.en_name
        account.ru_desc = fieldsValue.ru_desc
        account.en_desc = fieldsValue.en_desc
        account.tat_desc = fieldsValue.tat_desc
        if (fieldsValue.inn) {
          account.inn = fieldsValue.inn
        }
        account.type = fieldsValue.type
        account.previous_names = []

        const organizationNameMap = nameListRef.current.getNameMap();
        organizationNameMap.forEach((value, i) => {
          if (fieldsValue[`organization_prev_name_ru-${i}`]) {
            const prev = {}
            prev.year_since = fieldsValue[`organization_date_from-${i}`] ?
              fieldsValue[`organization_date_from-${i}`].format('YYYY') : ''
            prev.ru_name = fieldsValue[`organization_prev_name_ru-${i}`] || ''
            prev.en_name = fieldsValue[`organization_prev_name_en-${i}`] || ''
            prev.tat_name =
              fieldsValue[`organization_prev_name_tat-${i}`] || ''
            account.previous_names.push(prev)
          }
        })
        // if (account.previous_names.length === 0) {
        //   delete account.previous_names
        // }
        body.account = account;

        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm)
)

export default ProfileTabName
