import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import encQueries from 'components/GSearchPanel/Queries/encQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import { listQueries } from 'components/CreatePanel/queries/getList';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/create';
import i18n from 'i18next';

import config from '../config';
import AutoInputGQL from '../../fields/autoInputGQL';
import AddBookSeriesModal from '../newBookSeriesModal'

const FormItem = Form.Item;

const mapStateToProps = state => ({
  createData: state.create,
});

const mapDispatchToProps = {
  createBookSeries: actions.createBookSeries,
  newBookSeriesSelected: actions.newBookSeriesSelected,
};

@connect(mapStateToProps, mapDispatchToProps)
class CreateBookForm extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newBookSeriesModal: false,
      newPublisher: false,
      newPublisherName: ''
    };
  }

  handleSelectPublisher = (data) => {
    if (data.value === 'new') {
      this.setState({ newPublisher: true, newPublisherName: data.typed });
    } else {
      this.setState({ newPublisher: false });
    }
  }

  handleChangeBookSeriesSelector = (data) => {
    if (data.value === 'new') {
      this.setState({ newBookSeriesModal: true });
    } else {
      this.setState({ newBookSeriesModal: false });
    }
  }

  hideNewBookSeriesModal = (e) => {
    this.setState({ newBookSeriesModal: false });
    if (this.props.form.getFieldValue('series_select') === 'new') {
      this.props.form.setFieldsValue({
        series_select: null,
      });
    }
  }

  handlePublisherChange = (data) => {
    this.props.form.setFieldsValue({ publisher: data.uid });
  }

  handleOrgChange = (data) => {
    this.props.form.setFieldsValue({ organization: data.uid });
  }

  handleSeriesChange = (data) => {
    this.props.form.setFieldsValue({ series: data.uid });
  }

  handleCityChange = (data) => {
    this.props.form.setFieldsValue({ city: data.uid });
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      createBookSeries,
      newBookSeriesSelected,
      createData,
      t,
    } = this.props;

    const { newPublisher } = this.state;

    if (createData.newBookSeries) {
      this.props.form.setFieldsValue({
        series_select: createData.data.bookSeries[`${i18n.language}_name`],
        series: createData.data.bookSeries.uid,
      });
      newBookSeriesSelected();
    }

    return (
      <div>
        <CommonSelect
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          inputName={`edition_type`}
          label={t('create_panel.modal.addNewReport.edition_type.title')}
          selectQuery={attributeQueries.defaultSearch('editionTypes', false)}
          dontNeedEmptyOption
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.isbn.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('isbn', config.isbn
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={this.handleChangeBookSeriesSelector}
          newObjChanged={this.handleSeriesChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="series_select"
          label={t('create_panel.modal.addNewReport.series.title')}
          addOptionText={t('create_panel.modal.addNewReport.create.bookSeries')}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.seriesSearch()}
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.volume_book.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('volume', config.volume
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          newObjChanged={this.handleCityChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="city_select"
          label={t('create_panel.modal.addNewReport.city.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.citySearch()}
          t={t}
        />
        <AutoInputGQL
          handleSelect={this.handleSelectPublisher}
          newObjChanged={this.handlePublisherChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="publisher_select"
          label={t('create_panel.modal.addNewReport.publisher.title')}
          addOptionText={t('create_panel.modal.addNewReport.publisher.new')}
          rules={config.publisher}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.publisherSearch()}
          t={t}
        />
        {newPublisher === true &&
          <div>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.ru_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_ru_name', { ...config.ru_name, initialValue: this.state.newPublisherName }
              )(<Input />)}
            </FormItem>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.en_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_en_name', { ...config.en_name, initialValue: this.state.newPublisherName }
              )(<Input />)}
            </FormItem>
            <AutoInputGQL
              newObjChanged={this.handleOrgChange}
              getFieldDecorator={getFieldDecorator}
              formLayout={formItemLayout}
              selectkey="publisher-organization"
              label={t('create_panel.modal.addNewReport.publisher.organization')}
              noAddOption
              params={[{ key: 'search', val: 'value' }]}
              query={listQueries.organizationSearch()}
              t={t}
            />
          </div>
        }
        <FormItem>
          {getFieldDecorator('series', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('city', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('publisher', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('organization', {})(
            <Input hidden />)}
        </FormItem>

        <AddBookSeriesModal
          visible={this.state.newBookSeriesModal}
          hide={this.hideNewBookSeriesModal}
          loading={createData.isLoading}
          bookSeriesCreated={createData.newBookSeries}
          handleSubmit={createBookSeries}
          t={t}
        />
      </div>
    );
  }
}

export default CreateBookForm;
