import React from 'react'
import { Link } from 'react-router-dom'
import { withFormik } from 'formik'
import { Form, Input, Button, Alert, DatePicker, Spin } from 'antd'
import ReCAPTCHA from 'react-google-recaptcha'
import moment from 'moment'

import './index.css'
import Initials from '../../Initials'
import Confirmation from '../../Confirmation'

import getUrl from '../../../utility/pdfs'

const regInst = getUrl('instructions/reg_instr.pdf')

const FormItem = Form.Item

const renderErrors = (error, t) => {
  const keys = Object.keys(error)

  const result = []
  keys.forEach(item => {
    result.push(
      <Alert
        key={item}
        message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`}
        type="error"
      />
    )
  })
  return result
}

const InnerForm = ({
  handleSubmit,
  isCaptchaOk,
  signUpHandleCaptcha,
  isLoading,
  isError,
  form,
  t,
}) => {
  const { getFieldDecorator } = form

  const disabledDate = current => {
    // Can not select days after today
    return current && current > moment()
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }

  const compareToFirstPassword = (rule, val, callback) => {
    if (val && val !== form.getFieldValue('password')) {
      callback(t('signUp.form.password.error_confirm'))
    } else {
      callback()
    }
  }
  const validateToNextPassword = (rule, val, callback) => {
    if (val) {
      form.validateFields(['password_confirm'], { force: true })
    }
    callback()
  }

  const handleReCaptcha = () => {
    signUpHandleCaptcha()
  }

  const config = {
    email: {
      rules: [
        {
          type: 'email',
          message: t('signUp.form.email.error.valid'),
        },
        {
          required: true,
          message: t('signUp.form.email.error.empty'),
        },
      ],
    },
    password: {
      rules: [
        {
          required: true,
          message: t('signUp.form.password.error'),
        },
        {
          validator: validateToNextPassword,
        },
      ],
    },
    password_confirm: {
      rules: [
        {
          required: true,
          message: t('signUp.form.password_confirm.error'),
        },
        {
          validator: compareToFirstPassword,
        },
      ],
    },
    birthday: {
      rules: [
        {
          type: 'object',
          required: true,
          message: t('signUp.form.birthday.error'),
        },
      ],
    },
  }
  return (
    <Form onSubmit={handleSubmit} className="sign_up_form">
      {isError && renderErrors(isError, t)}
      <div className="instr_link">
        <a href={regInst} target="_blank" >Инструкция по регистрации</a>
      </div>
      <FormItem label={t('signUp.form.email.title')} {...formItemLayout}>
        {getFieldDecorator('email', config.email)(<Input />)}
      </FormItem>
      <FormItem label={t('signUp.form.password.title')} {...formItemLayout}>
        {getFieldDecorator('password', config.password)(
          <Input type="password" />
        )}
      </FormItem>
      <FormItem
        label={t('signUp.form.password_confirm.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('password_confirm', config.password_confirm)(
          <Input type="password" />
        )}
      </FormItem>
      <Initials
        getFieldDecorator={getFieldDecorator}
        formItemLayout={formItemLayout}
        t={t}
        form={form}
      />
      <FormItem {...formItemLayout} label={t('signUp.form.birthday.title')}>
        {getFieldDecorator('birthday', config.birthday)(
          <DatePicker
            placeholder={t('signUp.form.birthday.placeholder')}
            format="DD.MM.YYYY"
            style={{ width: '100%' }}
            disabledDate={disabledDate}
          />
        )}
      </FormItem>
      <Confirmation
        getFieldDecorator={getFieldDecorator}
        formItemLayout={formItemLayout}
        t={t}
        form={form}
      />
      <div className="capthca">
        <ReCAPTCHA
          sitekey="6LdqRV0UAAAAAC1V8j4_VlfFYuIy9dCdebGSh8yN"
          onChange={handleReCaptcha}
        />
      </div>
      {isLoading ? (
        <Spin />
      ) : (
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          disabled={!isCaptchaOk}
        >
          {t('signUp.form.button')}
        </Button>
      )}
      
      <div className="footer_link">
        <Link to="/login">{t('logIn.form.title')}</Link>
      </div>
    </Form>
  )
}

// Wrap our form with the using withFormik HoC
const SignUpForm = Form.create()(
  withFormik({
    // Submission handler
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }
        const body = {}
        body.organizations = []
        body.email = fieldsValue.email
        body.password = fieldsValue.password
        body.first_name = fieldsValue.first_name
        body.last_name = fieldsValue.last_name
        body.parent_name = fieldsValue.parent_name
        body.public_name = fieldsValue.public_name
        body.birthday = fieldsValue.birthday
          ? fieldsValue.birthday.format('DD.MM.YYYY')
          : ''
        const { organization } = fieldsValue
        const { userOne } = fieldsValue
        if (organization) {
          body.confirmation_users = [organization]
        } else {
          body.confirmation_users = userOne.map(item => item.key)
        }
        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm)
)

export default SignUpForm
