import React, { PureComponent } from 'react';
import { Button, Tooltip, Popconfirm } from 'antd';
import { translate } from 'react-i18next';
import { url } from 'utility/constants'
import { actions } from 'redux/modules/create';
import { connect } from 'react-redux';
import { actions as actionsE } from 'redux/modules/example';
import { actions as actionsT } from 'redux/modules/tools';
import getUrl from 'utility/images'
import { withRouter } from 'react-router-dom';

import AddPhotoModal from './methods/addPhoto'
import AddSourceModal from './methods/addSource'
import AddSpatialsModal from './methods/addSpatials'
import AddConnectionModal from './methods/addConnection'
import AddSelectionModal from './methods/addSelection'

const mapStateToProps = state => ({
  createData: state.create,
  interactiveMarkers: state.example.interactiveMarkers,
  userEditMode: state.example.editMode,
  newConnections: state.example.editModeNewConnections,
  selections: state.tools.selections.data,
});
const mapDispatchToProps = {
  filelistChanged: actions.sourceFilelistChanged,
  uploadFiles: actions.uploadFiles,
  uploadFilesStart: actions.uploadFilesStart,
  uploadSpatials: actions.uploadSpatials,
  updateSpatials: actionsE.editUpdateSpatial,
  handleChangeInteractiveMarker: actionsE.changeInteractiveMarker,
  addConnection: actionsE.editAddConnection,
  clearConnections: actionsE.editClearConnections,
  createConnections: actions.createSubjectRelations,
  getSelections: actionsT.findSelections,
  addToSelection: actionsE.editAddToSelection,
  deleteSubject: actionsE.editDeleteSubject,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class LeftPanelButton extends PureComponent {
  constructor(props) {
    super(props);
    this.newTabIndex = 1;
    this.state = {
      modalVisible: false,
    }
  }

  showModal = (method) => {
    if (method === 'addSelection') {
      this.props.getSelections()
    }
    this.props.uploadFilesStart()
    this.setState({ modalVisible: true })
  }

  hideModal = () => {
    this.setState({ modalVisible: false })
  }

  handleDelete = (uid, options) => {
    if (options.label) {
      this.props.deleteSubject({ uid, label: options.label })

      this.props.history.push(`/`)
    }
  }

  getMethod = (method, uid, options) => {
    if (method === 'updateSpatial') {
      return () => {
        this.props.updateSpatials({ uid, label: options.label })
      }
    }
    return () => {
      this.showModal(method)
    }
  }

  render() {
    const {
      t,
      content,
      userEditMode,
      filelistChanged,
      createData,
      newConnections,
      addConnection,
      clearConnections,
      createConnections,
      uploadFiles,
      uploadSpatials,
      interactiveMarkers,
      handleChangeInteractiveMarker,
      selections,
      addToSelection,
    } = this.props;
    const { buttonText, method, objUid, knowledge, options } = content;
    const tooltipText = t(`dataPanel.button.${buttonText}`);
    return (
      <div>
        {method === 'delete' &&
          <Popconfirm
            title={t(`merge.success.delete_text_singular`)}
            onConfirm={() => this.handleDelete(objUid, options)}
            okText={t(`merge.success.yes`)}
            cancelText={t(`merge.success.no`)}
            placement="right"
          >
            <Tooltip placement="right" title={tooltipText}>
              <Button type={options.buttonType || 'primary'} className="edit-button"
                      onClick={() => this.showModal(method)}>
                <img src={getUrl(`common_icons/${buttonText}.webp`)} width={30}/>
              </Button>
            </Tooltip>
          </Popconfirm>
        }

        {method !== 'delete' &&
          <Tooltip placement="right" title={tooltipText}>
            <Button type={options.buttonType || 'primary'} className="edit-button" onClick={this.getMethod(method, objUid, options)}>
              <img src={getUrl(`common_icons/${buttonText}.webp`)} width={30}/>
            </Button>
          </Tooltip>
        }

        {method === 'addPhoto' &&
        <AddPhotoModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          imageType='Photo'
          uploadFiles={uploadFiles}
          filelistChanged={filelistChanged}
          createData={createData}
          objUid={objUid}
          t={t}
        />
        }

        {method === 'addTopoplan' &&
        <AddPhotoModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          imageType='TopographicPlan'
          uploadFiles={uploadFiles}
          filelistChanged={filelistChanged}
          createData={createData}
          objUid={objUid}
          t={t}
        />
        }

        {method === 'addSource' &&
        <AddSourceModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          uploadFiles={uploadFiles}
          filelistChanged={filelistChanged}
          createData={createData}
          objUid={objUid}
          t={t}
        />
        }

        {method === 'addSpatials' &&
        <AddSpatialsModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          uploadSpatials={uploadSpatials}
          interactiveMarkers={interactiveMarkers}
          handleChangeInteractiveMarker={handleChangeInteractiveMarker}
          knowledge={knowledge}
          objUid={objUid}
          t={t}
        />
        }

        {method === 'addConnection' &&
        <AddConnectionModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          newConnections={newConnections}
          addConnection={addConnection}
          clearConnections={clearConnections}
          createConnections={createConnections}
          objUid={objUid}
          options={options}
          t={t}
        />
        }

        {method === 'addSelection' &&
        <AddSelectionModal
          visible={this.state.modalVisible}
          hide={this.hideModal}
          objUid={objUid}
          addToSelection={addToSelection}
          selections={selections}
          options={options}
          t={t}
        />
        }
      </div>
    )
  }
}

export default withRouter(LeftPanelButton);
