import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
  getCultures,
} from 'utility/transformData/constants';

function getDescription(item) {
  if (item.ru_desc) {
    return getLangTitle(item, 'desc');
  }
  if (item.interpretations && item.interpretations[0] && item.interpretations[0].description) {
    return item.interpretations[0].description;
  }
  if (item.interpretations && item.interpretations[0] && item.interpretations[0].ru_desc) {
    return getLangTitle(item.interpretations[0], 'desc');
  }
  return item.etim_desc;
}

export default function getResearchFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const { author, source_ } = d;
      const result = [
        createLinkField('author', author.uid, 'author',
          fullNameToShort(author)),
        createCommonField('year', d.year),
        createCommonField('type', (d.type[0]) ? d.type[0].ru_name : ''),
        createCommonField('description', getLangTitle(d, 'desc')),
      ];
      if (source_) {
        let sourceName = getLangTitle(source_);
        sourceName += source_.year ? `, ${source_.year}` : '';
        sourceName += source_.author && source_.author.public_name ?
          `, ${source_.author.public_name}` : '';
        result.push(createLinkField('source', source_.uid, `source/${source_.label}`,
          sourceName))
      }
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const itemObj = {}
        itemObj.content = [
          createLinkField('title', item.uid, 'research',
            'Перейти к исследованию'),
          createCommonField('description', getDescription(item)),
        ]
        if (item.culture) {
          itemObj.content.push(
            createCommonField('culture', getCultures(item.culture.response.results))
          )
        }
        if (item.etim_desc) {
          itemObj.content.push(
            createCommonField('etim_description', item.etim_desc)
          )
        }
        itemObj.name = item.author ? `${item.author.public_name}, ` : ''
        itemObj.name += `${item.year}, ${getLangTitle(item)}`
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
