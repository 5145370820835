import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'

import {
  getResearchUrl,
  getPropertyByNameString,
} from 'utility/transformData/constants'

class ResultPanel extends Component {
  getLink = (type, item = {}) => {
    switch (type) {
      case 'heritage': {
        const label = item.label || getPropertyByNameString(item, 'node.label')
        return label === 'Building' ? 'subjects/Building' : 'subjects/Site'
      }
      case 'research': {
        const label = item.label || getPropertyByNameString(item, 'node.label')
        return getResearchUrl(label)
      }
      case 'source/Publication': {
        return `source/${item.node.label}`
      }
      case 'linguistic/variant/search':
        return 'subjects/Variant'
      default:
        return type
    }
  }

  onChange = (pagination, filters, sorter, extra) => {
    const { resultFields } = this.props
    const { sort } = resultFields[sorter.field] || {}
    this.props.handleSort({
      ...sorter,
      field: sort,
    })
  }

  renderResults = (results, t) => {
    const { resultFields, url } = this.props
    const columns = []

    Object.keys(resultFields).forEach(key => {
      columns.push({
        title: t(`search.${key}`),
        dataIndex: key,
        sorter: !!resultFields[key].sort,
      })
    })
    const data = results.data.map(item => {
      const res = {}
      if (item.node) {
        res.key = item.uid
        Object.keys(resultFields).forEach(key => {
          res[key] = (
            <Link
              to={`/show/${results.objLink ||
              this.getLink(url, item)}/${item.uid || item.node.uid}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {resultFields[key].data
                ? resultFields[key].data(item.node)
                : resultFields[key](item.node)}
            </Link>
          )
        })
      }
      return res
    })

    return (
      <Table
        columns={columns}
        dataSource={data}
        onChange={this.onChange}
        pagination={false}
        loading={results.isLoading}
      />
    )
  }

  render() {
    const { results, t } = this.props
    return <div id="render_result">{this.renderResults(results, t)}</div>
  }
}

export default ResultPanel
