import React from 'react';
import moment from 'moment';
import { withFormik } from 'formik';
import { Form, Input, Button, Alert, DatePicker, Spin } from 'antd';
import { getFullName, getEmail } from 'utility/name'

import AvatarUpload from '../../../AvatarUpload';
import ChangePasswordModal from '../../../ChangePassword/Modal';

const FormItem = Form.Item;
const dateFormat = 'DD.MM.YYYY';

const renderErrors = (error, t) => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`} type="error" />
    )
  });
  return result;
}

const InnerForm = ({
  handleSubmit,
  userInfo = {},
  removePhoto,
  isLoading,
  isError,
  form,
  t,
}) => {
  const { getFieldDecorator } = form;

  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment();
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const compareToFirstPassword = (rule, val, callback) => {
    if (val && val !== form.getFieldValue('new_password')) {
      callback(t('signUp.form.new_password.error_confirm'));
    } else {
      callback();
    }
  }
  const validateToNextPassword = (rule, val, callback) => {
    if (val) {
      form.validateFields(['new_password_confirm'], { force: true });
    }
    callback();
  }

  const config = {
    password: {
      rules: [{
        validator: validateToNextPassword,
      }],
    },
    new_password: {
      rules: [{
        validator: validateToNextPassword,
      }],
    },
    new_password_confirm: {
      rules: [{
        validator: compareToFirstPassword,
      }],
    },
    birthday: {
      rules: [],
      initialValue: userInfo.birthday ? moment(userInfo.birthday, dateFormat) : undefined,
    },
    first_name: {
      rules: [],
      initialValue: userInfo.first_name,
    },
    last_name: {
      rules: [],
      initialValue: userInfo.last_name,
    },
    public_name: {
      rules: [],
      initialValue: userInfo.public_name,
    },
    public_name_en: {
      rules: [],
      initialValue: userInfo.public_name_en,
    },
    parent_name: {
      rules: [],
      initialValue: userInfo.parent_name,
    },
  };
  return (
    <Form onSubmit={handleSubmit} className="sign_up_form">
      {isError && renderErrors(isError, t)}
      <div className="account_confirm">
        {
          (userInfo.email_confirmed === false) &&
          <Alert message={t('profile.notConfirm.email')} type="warning" showIcon />
        }
        {
          (userInfo.account_confirmed === false) &&
          <Alert
            message={t('profile.notConfirm.account')}
            type="warning"
            description={
              <div>
                <div>{t('profile.notConfirm.mustConfirm')}</div>
                {
                  userInfo.must_confirm && userInfo.must_confirm.map(userConfirm => (
                    <div className="user-must-confirm">{`${getFullName(userConfirm)} (${getEmail(userConfirm)})`}</div>
                  ))
                }
              </div>
            }
            showIcon
          />
        }
      </div>
      <AvatarUpload
        imageUrl={userInfo.photo}
        removePhoto={removePhoto}
      />
      <ChangePasswordModal
        t={t}
      />
      <FormItem
        label={t('profile.form.second_name.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('last_name', config.last_name
        )(<Input />)}
      </FormItem>
      <FormItem
        label={t('profile.form.first_name.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('first_name', config.first_name
        )(<Input />)}
      </FormItem>
      <FormItem
        label={t('profile.form.parent_name.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('parent_name', config.parent_name
        )(<Input />)}
      </FormItem>
      <FormItem
        label={t('profile.form.public_name.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('public_name', config.public_name,
        )(<Input
          placeholder={t('profile.form.public_name.placeholder')}
        />)}
      </FormItem>
      <FormItem
        label={t('profile.form.public_name_en.title')}
        {...formItemLayout}
      >
        {getFieldDecorator('public_name_en', config.public_name_en,
        )(<Input
          placeholder={t('profile.form.public_name_en.placeholder')}
        />)}
      </FormItem>
      <FormItem
        {...formItemLayout}
        label={t('profile.form.birthday.title')}
      >
        {getFieldDecorator('birthday', config.birthday)(<DatePicker
          placeholder={t('profile.form.birthday.placeholder')}
          format="YYYY-MM-DD"
          style={{ width: '100%' }}
          disabledDate={disabledDate}
        />)}
      </FormItem>
      {isLoading ?
        <Spin />
        :
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          {t('profile.form.button')}
        </Button>
      }
    </Form>
  )
};

// Wrap our form with the using withFormik HoC
const ProfileTabGeneral = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }
      const body = {};
      body.last_name = fieldsValue.last_name;
      body.first_name = fieldsValue.first_name;
      body.parent_name = fieldsValue.parent_name;
      body.public_name = fieldsValue.public_name;
      body.public_name_en = fieldsValue.public_name_en;
      body.birthday = fieldsValue.birthday ? fieldsValue.birthday.format('DD.MM.YYYY') : '';
      props.handleSubmit(body);
      setTimeout(setSubmitting(false), 1000);
    });
  },
})(InnerForm));

export default ProfileTabGeneral;
