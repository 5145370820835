import * as request from '../../utility/request';

export const getWhenMain = async () => {
  const path = 'api/heatmap/graphic/plot/main';
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}


export const postBboxHeatmap = async (data) => {
  const path = 'api/heatmap/map/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postBboxChart = async (data) => {
  const path = 'api/heatmap/map/graphic/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postBboxData = async (data) => {
  const path = 'api/heatmap/map/list/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postBboxDataCount = async (data) => {
  const path = 'api/heatmap/map/count/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhenHeatmap = async (data) => {
  const path = 'api/heatmap/graphic/heatmap/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhenChart = async (data) => {
  const path = 'api/heatmap/graphic/plot/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhenData = async (data) => {
  const path = 'api/heatmap/graphic/list/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhenDataCount = async (data) => {
  const path = 'api/heatmap/graphic/count/';
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhatHeatmap = async (data) => {
  const path = `api/heatmap/cloud/${data}/map/`;
  const { response, error } = await request.safelyGet(path);
  return { response, error };
}

export const postWhatChart = async (data) => {
  const path = `api/heatmap/cloud/${data.subject}/graphic/`;
  const { response, error } = await request.safelyPost(path, { date_start: -10000, date_end: 2018 });
  return { response, error };
}

export const postWhatData = async (data) => {
  const path = `api/heatmap/cloud/${data.subject}/list/`;
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}

export const postWhatDataCount = async (data) => {
  const path = `api/heatmap/cloud/${data.subject}/count/`;
  const { response, error } = await request.safelyPost(path, data);
  return { response, error };
}
