import React from 'react'
import i18n from 'i18next'
import {
  getLangTitleGraph,
  getPropertyByNameString,
  removeEmptyElements,
} from 'utility/transformData/constants'
import { getLangName } from 'components/GSearchPanel/Queries/constants'
import { page as sortPage, pageWithNoSort as page } from './queries'

const lang = i18n.language

export const folkloreQueries = {
  variantSearch: () => {
    return {
      query: `query postQuery($search: String="", ${page.def}) {
        results:folkVariants(commonRuName_Icontains: $search, ${page.params}) {
          totalCount
          edges {
            node {
              uid
              ruName: commonRuName
              enName: commonEnName
              tatName: commonTatName
              recordDate
              locality {
                edges {
                  node {
                    ${i18n.language}Name
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/FolkVariant',
      fields: {
        name: {
          data: getLangName,
        },
        // year: {
        //   data: data => data.recordDate.substr(0, 4) || '-',
        // },
        locality: {
          data: data => {
            const locality = getPropertyByNameString(
              data,
              'locality.edges.0.node',
            )
            return (locality && getLangTitleGraph(locality)) || '-'
          },
        },
      },
    }
  },
  taleSearch: () => {
    return {
      query: `query postQuery($search: String="",
              $tale_type: UUID,
              $tale_genre: UUID,
              $tale_variety: String,
              ${page.def}) {
        results:folkTales(
          commonRuName_Icontains: $search,
          folkType_Has: $tale_type,
          folkGenre_Has: $tale_genre,
          folktaleGenreVariety_Icontains: $tale_variety,
          ${page.params}
        ) {
          totalCount
          edges {
            node {
              uid
              ruName: commonRuName
              enName: commonEnName
              tatName: commonTatName
              folktaleGenreVariety
              folkType {
                edges {
                  node {
                    uid
                    ruName
                    enName
                  }
                }
              }
              folkGenre {
                edges {
                  node {
                    uid
                    ruName
                    enName
                  }
                }
              }
            }
          }
        }
      }`,
      url: 'subjects/FolkTale',
      fields: {
        name: {
          data: getLangName,
        },
        type: {
          data: data => {
            const type = getPropertyByNameString(data, 'folkType.edges.0.node')
            return (type && getLangTitleGraph(type)) || '-'
          },
        },
        genre: {
          data: data => {
            const genre = getPropertyByNameString(
              data,
              'folkGenre.edges.0.node',
            )
            return (genre && getLangTitleGraph(genre)) || '-'
          },
        },
        genreVariety: {
          data: data => data.folktaleGenreVariety,
        },
      },
    }
  },
  personSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folkPersons(folkPersonNameIcontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              folkPersonName
            }
          }
        }
      }`,
      url: 'subjects/FolkPerson',
      fields: {
        name: {
          data: getLangName,
          sort: 'folk_person_name',
        },
      },
    }
  },
  characterSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folkCharacters(folkCharacterNameIcontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              folkCharacterName
            }
          }
        }
      }`,
      url: 'subjects/FolkCharacter',
      fields: {
        name: {
          data: getLangName,
          sort: 'folk_character_name',
        },
      },
    }
  },
  genreSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folkGenres(${lang}Icontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/folkGenre',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
  genreGroupSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folkGenreGroups(${lang}Name_Icontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/folkGenreGroup',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
  folkClassSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folkClasses(${lang}Name_Icontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/folkClass',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
  folkTypeSearch: () => {
    return {
      query: `query postQuery($search: String="", ${sortPage.def}) {
        results:folktypes(${lang}Name_Icontains: $search, ${sortPage.params}) {
          totalCount
          edges {
            node {
              uid
              ${lang}Name
            }
          }
        }
      }`,
      url: 'subjects/folkType',
      fields: {
        name: {
          data: getLangName,
          sort: '${lang}_name',
        },
      },
    }
  },
}

export default folkloreQueries;
