import L from 'leaflet';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/heatmap';
import WhereMap from './map';
import './index.css';

const { CRS } = L;
const tiles = [
  {
    checked: true,
    name: 'Open Street Map',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
    subdomains: ['a', 'b', 'c'],
    maxZoom: 19,
  },
]
const url = process.env.REACT_APP_API_URL || 'https://www.culturetat.ru';

const mapStateToProps = state => ({
  mapState: state.heatmap.heatmap,
});

const mapDispatchToProps = {
  getHeatmap: actions.whereSubmit,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class WhereView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  state = {
    tempPoints: [],
  }

  componentDidMount() {
    const { selectType } = this.props;

    fetch(`${url}/api/heatmap/map/`, {})
      .then(response => response.json())
      .then((d) => {
        this.setState({ tempPoints: d })
      })
      .catch((error) => {
      });
  }

  handleChooseArea = (bounds) => {
    this.props.getHeatmap({
      bbox: {
        lpoint: {
          latitude: bounds._southWest.lat,
          longitude: bounds._southWest.lng,
        },
        rpoint: {
          latitude: bounds._northEast.lat,
          longitude: bounds._northEast.lng,
        },
        label: 'AS',
      },
      history: this.props.history,
    })
    // this.props.history.push('/home_result')
  }

  render() {
    const { t } = this.props;

    return (
      <div className="where-container">
        <WhereMap
          handleChooseArea={this.handleChooseArea}
          parentMapState={{
            lat: 55.33,
            lng: 51,
            zoom: 3,
            minZoom: 1,
            crs: CRS.EPSG3857,
            maxBounds: [[52.3382986067, 60.5748103744], [57.711244829, 41.9867587146]],
          }}
          t={t}
          maps={tiles}
          isIndex
          tempPoints={this.state.tempPoints}
        />
      </div>
    )
  }
}

export default WhereView;
