import React, { useState, useEffect } from 'react'
import { Upload, Icon, message } from 'antd'
import { translate } from 'react-i18next'
import { removePhoto } from 'redux/api/author'
import { url } from 'utility/constants'
import { safelyPostFormImage, GET_HEADERS } from 'utility/request'
import './index.css'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    callback(reader.result)
  })
  reader.readAsDataURL(img)
}

const AvatarUpload = props => {
  const { imageUrl, t } = props
  const [image, setImage] = useState(
    imageUrl ? `${url}/${imageUrl}` : undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (imageUrl) {
      setImage(`${url}/${imageUrl}`)
    }
  }, [imageUrl])

  const beforeUpload = file => {
    const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJPGorPNG) {
      message.error(t('profile.form.photo.error.type'))
    }
    const isLt2M = file.size / 1024 / 1024 < 10
    if (!isLt2M) {
      message.error(t('profile.form.photo.error.size'))
    }
    return isJPGorPNG && isLt2M
  }

  const uploadPhoto = e => {
    setIsLoading(true)
    const loading = message.loading(t('profile.form.photo.loading'), 0)
    const formData = new FormData()
    const { file } = e
    const headers = {
      ...GET_HEADERS(),
      'Content-Type': 'multipart/form-data',
    }
    formData.append('photo', file, file.name)
    safelyPostFormImage(
      'api/author/profile/photo/',
      formData,
      headers,
      'PUT',
    ).then((response, error) => {
      if (response) {
        loading()
        message.success(t('profile.form.photo.success'))
        getBase64(file, imageUrl => {
          setIsLoading(false)
          setImage(imageUrl)
        })
      }
    })
  }

  const remove = () => {
    const removing = message.loading(t('profile.form.photo.removing'), 0)
    removePhoto().then(({ response, error }) => {
      if (response) {
        setImage(null)
        removing()
      } else {
        const errorRemove = message.loading(
          t('profile.form.photo.removedError'),
          1000,
        )
      }
    })
  }

  const uploadButton = (
    <div>
      <Icon type={isLoading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">{t('profile.form.photo.button')}</div>
    </div>
  )

  return (
    <div className="ant-row ant-form-item">
      <div className="avatar-upload">
        <Upload
          name="photo"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={uploadPhoto}
          beforeUpload={beforeUpload}
        >
          {image ? <img src={image} alt="avatar" /> : uploadButton}
        </Upload>
        {image && <Icon type="close" onClick={remove} />}
      </div>
    </div>
  )
}

export default translate()(AvatarUpload)
