export const getChartData = (data) => {
  if (!data) {
    return {};
  }
  const ret = { labels: [], datasets: [] }
  const tmp = [];
  Object.keys(data).forEach(key => {
    tmp.push([parseInt(key.split(':')[0], 10), data[key]])
  })
  tmp.sort((a, b) => a[0] - b[0])
  tmp.forEach(d => {
    ret.labels.push(d[0])
    ret.datasets.push(d[1])
  })
  return ret
}

export default getChartData
