import React, { Component, Fragment } from 'react'
import { Input, Select, Form, Collapse } from 'antd'
import '../../index.scss'
// eslint-disable-next-line import/no-cycle
import AutoInputGQL from '../fields/autoInputGQL'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse

class SciTopicTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
      text: '',
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
      text: data.option.props.text,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      getFieldDecorator,
      createData,
      formInputLayout,
      subjects,
      title,
      t,
    } = this.props
    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.sciTopic.sciTopic')}`}
              key={title + item.key}
            >
              <AutoInputGQL
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.sciTopic.choose')}
                selectkey={`sciTopic-select-${item.key}`}
                addOptionText={t('create_panel.tab.sciTopic.add')}
                objkey={item.key}
                params={[{ key: 'search', val: 'value' }]}
                query="SciTopic"
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="SciTopic"
                t={t}
              />
              {(item.status === 'new' || item.status === 'selected') && (
                <div>
                  <Fragment>
                    <FormItem
                      {...formInputLayout}
                      label={t('create_panel.tab.sciTopic.name')}
                    >
                      {getFieldDecorator(`sciTopic_name_${key}`, {
                        ...config.req,
                        initialValue: this.state.text,
                      })(
                        <Input
                          onChange={this.handleChangeName}
                          data-objkey={item.key}
                        />,
                      )}
                    </FormItem>
                  </Fragment>
                </div>
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    )
  }
}

export default SciTopicTab
