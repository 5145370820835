import {
  Environment,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';

import { url } from 'utility/constants';
import { HEADERS_POST_AUTH } from 'utility/request';

function fetchQuery(
  operation,
  variables,
) {
  const { headers } = HEADERS_POST_AUTH();
  return fetch(`${url}/api/graphql/`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => {
    return response.json();
  }).then(json => {
    if (json && json.errors) {
      const result = {
        ...json,
        data: {
          ...json.data,
          errors: json.errors,
        },
      }
      return result;
    }
    return json;
  }).catch((e) => console.error(e));
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
});

export default environment;
