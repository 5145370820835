/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type AccessObjectEnum = "PRIVATE" | "PUBLIC" | "SHARED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResearchFragment$ref: FragmentReference;
export type ResearchFragment = {|
  +uid: ?string,
  +label: ?string,
  +ruName: ?string,
  +enName: ?string,
  +tatName: ?string,
  +year: ?number,
  +sciTopic: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +uid: ?string,
        +ruName: ?string,
        +issue: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +direction: ?{|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +organization: ?{|
                      +edges: $ReadOnlyArray<?{|
                        +node: ?{|
                          +ruName: ?string,
                          +uid: ?string,
                        |}
                      |}>
                    |}
                  |}
                |}>
              |}
            |}
          |}>
        |},
      |}
    |}>
  |},
  +permission: ?AccessObjectEnum,
  +type: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +ruName: ?string,
        +enName: ?string,
        +tatName: ?string,
        +sort: ?number,
      |}
    |}>
  |},
  +$refType: ResearchFragment$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ruName",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "enName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tatName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ResearchFragment",
  "type": "ResearchNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    v1,
    v2,
    v3,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "year",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "sciTopic",
      "storageKey": null,
      "args": null,
      "concreteType": "SciTopicNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "SciTopicNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "SciTopicNode",
              "plural": false,
              "selections": [
                v0,
                v1,
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "issue",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SciIssueNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "SciIssueNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "SciIssueNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "direction",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "SciDirectionNodeConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "SciDirectionNodeEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "SciDirectionNode",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "organization",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "OrganizationNodeConnection",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "edges",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "OrganizationNodeEdge",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "kind": "LinkedField",
                                                  "alias": null,
                                                  "name": "node",
                                                  "storageKey": null,
                                                  "args": null,
                                                  "concreteType": "OrganizationNode",
                                                  "plural": false,
                                                  "selections": [
                                                    v1,
                                                    v0
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permission",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "type",
      "storageKey": null,
      "args": null,
      "concreteType": "ResearchTypeNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ResearchTypeNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ResearchTypeNode",
              "plural": false,
              "selections": [
                v1,
                v2,
                v3,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sort",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b0b3dd9dbbd5266f060da8c234545ad';
module.exports = node;
