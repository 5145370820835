import { createAction, handleActions } from 'redux-actions'
import { getMarkers, getPolygons } from '../../utility/points'

const SEARCH_PAGE_CLEAN = 'quickSearch/SEARCH_PAGE_CLEAN'
const SEARCH_CLEAN = 'quickSearch/SEARCH_CLEAN'
const SEARCH = 'quickSearch/SEARCH'
const SEARCH_DATA = 'quickSearch/SEARCH_DATA'
const SEARCH_START = 'quickSearch/SEARCH_START'
const SEARCH_FAIL = 'quickSearch/SEARCH_FAIL'
const SEARCH_SAVE_QUERY = 'quickSearch/SAVE_QUERY'
const SEARCH_SPATIALS = 'quickSearch/SPATIALS'

const SEARCH_SPATIAL = 'quickSearch/SPATIAL'
const SEARCH_SPATIALS_DELETE = 'quickSearch/SPATIALS_DELETE'
const SEARCH_SPATIAL_AUTOINPUT_SUCCESS = 'quickSearch/SPATIAL_AUTOINPUT_SUCCESS'
const SEARCH_SPATIAL_AUTOINPUT_DELETE = 'quickSearch/SPATIAL_AUTOINPUT_DELETE'

export const constants = {
  SEARCH,
  SEARCH_PAGE_CLEAN,
  SEARCH_DATA,
  SEARCH_CLEAN,
  SEARCH_START,
  SEARCH_FAIL,
  SEARCH_SAVE_QUERY,
  SEARCH_SPATIALS,
  SEARCH_SPATIAL,
  SEARCH_SPATIALS_DELETE,
  SEARCH_SPATIAL_AUTOINPUT_SUCCESS,
  SEARCH_SPATIAL_AUTOINPUT_DELETE,
}

// ------------------------------------
// Actions
// ------------------------------------
export const simpleSearchForceClean = createAction(SEARCH_PAGE_CLEAN)
export const simpleSearch = createAction(SEARCH)
export const simpleSearchClean = createAction(SEARCH_CLEAN)
export const simpleSearchStart = createAction(SEARCH_START)
export const simpleSearchData = createAction(SEARCH_DATA)
export const simpleSearchFail = createAction(SEARCH_FAIL)
export const simpleSearchSaveQuery = createAction(SEARCH_SAVE_QUERY)
export const simpleSearchSpatials = createAction(SEARCH_SPATIALS)

export const simpleSearchSpatial = createAction(SEARCH_SPATIAL)
export const simpleSearchDeleteSpatials = createAction(SEARCH_SPATIALS_DELETE)
export const simpleSearchSpatialAutoinputSuccess = createAction(
  SEARCH_SPATIAL_AUTOINPUT_SUCCESS,
)
export const simpleSearchSpatialAutoInputDelete = createAction(
  SEARCH_SPATIAL_AUTOINPUT_DELETE,
)

export const actions = {
  simpleSearchForceClean,
  simpleSearch,
  simpleSearchClean,
  simpleSearchStart,
  simpleSearchData,
  simpleSearchFail,
  simpleSearchSaveQuery,
  simpleSearchSpatials,
  simpleSearchSpatial,
  simpleSearchDeleteSpatials,
  simpleSearchSpatialAutoinputSuccess,
  simpleSearchSpatialAutoInputDelete,
}

export const reducers = {
  [SEARCH_SPATIALS]: (state, { payload }) => ({
    ...state,
    spatials: {
      isLoading: false,
      data: [
        ...(state.spatials ? state.spatials.data : []),
        {
          name: payload.name,
          markers: getMarkers(payload.points, payload.type, payload.name),
          polygons: getPolygons(payload.points, payload.type, payload.name),
        },
      ],
    },
  }),
  [SEARCH_SPATIALS_DELETE]: state => ({
    ...state,
    oldSpatials: state.spatials,
    spatials: {
      isLoading: false,
      data: [],
    },
  }),
  [SEARCH_SPATIAL_AUTOINPUT_SUCCESS]: (state, { payload }) => {
    const newSpatials = state.oldSpatials.data.filter(
      d => d.key !== payload.key,
    )
    newSpatials.push({
      key: payload.key,
      name: payload.pointsObj.name,
      markers: getMarkers(
        payload.pointsObj.points,
        payload.pointsObj.type,
        payload.pointsObj.name,
      ),
      polygons: getPolygons(
        payload.pointsObj.points,
        payload.pointsObj.type,
        payload.pointsObj.name,
      ),
    })

    return {
      ...state,
      spatials: {
        isLoading: false,
        data: [...newSpatials],
      },
    }
  },
  [SEARCH_SPATIAL_AUTOINPUT_DELETE]: (state, { payload }) => {
    let {
      spatials: { data: spatials },
    } = state
    if (payload.selectkey) {
      spatials = spatials.filter(
        item => item.key && item.key !== payload.selectkey,
      )
    }
    return {
      ...state,
      spatials: {
        ...state.spatials,
        isLoading: false,
        data: spatials,
      },
    }
  },
  [SEARCH_SAVE_QUERY]: (state, { payload }) => ({
    ...state,
    savedQuery: {
      params: {
        ...payload.params,
      },
      count: payload.count,
      query: payload.query,
      url: payload.url,
    },
  }),
  [SEARCH_FAIL]: state => ({
    ...state,
    searchResults: {
      isLoading: false,
      data: [],
      error: true,
    },
    savedQuery: {
      ...state.savedQuery,
      count: -1,
    },
  }),
  [SEARCH_START]: state => ({
    ...state,
    spatials: {
      isLoading: true,
      data: [],
    },
    searchResults: {
      ...state.searchResults,
      error: false,
      isLoading: true,
      data: state.searchResults ? state.searchResults.data : [],
    },
  }),
  [SEARCH_CLEAN]: state => ({
    ...state,
    searchResults: {
      isLoading: false,
      error: false,
      count: -1,
      data: [],
      resultsType: null,
    },
    spatials: {
      isLoading: true,
      data: [],
    },
    savedQuery: {
      count: -1,
    },
  }),
  [SEARCH_DATA]: (props, { payload }) => ({
    ...props,
    searchResults: {
      isLoading: false,
      error: false,
      count: payload.results.totalCount,
      data: [...payload.results.edges],
      resultsType: props.type || null,
    },
    savedQuery: {
      ...props.savedQuery,
      count: props.savedQuery.count || payload.results.totalCount || 0,
    },
  }),
}

export const initialState = () => {
  return {
    savedQuery: {
      count: -1,
      url: null,
    },
    spatials: {
      isLoading: true,
      data: [],
    },
    oldSpatials: {
      isLoading: true,
      data: [],
    },
    searchResults: {
      isLoading: false,
      error: false,
      count: -1,
      data: [],
      resultsType: null,
    },
  }
}

export default handleActions(reducers, initialState())
