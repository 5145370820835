import React, { Component  } from 'react';
import { Select, Form } from 'antd';
import {
  getPropertyByNameString,
} from 'utility/transformData/constants';
import i18n from 'i18next';

import * as request from 'utility/request';

const FormItem = Form.Item;
const { Option } = Select;
const url = 'https://www.culturetat.ru';

let timeout;
let currentValue;

function getSearchUrl(path, params, value, method) {
  const searchString = (params && method === 'GET') ?
    `?search=${params.join(`=${value}|`)}=${value}`
    :
    '';

  return `${url}/${path}/${searchString}`
}

function fetchData(value, props, callback) {
  const { path, params, textField } = props;
  const method = (props.method) ? props.method : 'GET';

  const body = {}

  if (method === 'POST') {
    const parts = params[0].split('.')
    let pointer = body;
    for (let i = 0; i < parts.length; i++) {
      if (i + 1 === parts.length) {
        pointer[parts[i]] = value;
      } else {
        pointer[parts[i]] = {};
        pointer = pointer[parts[i]]
      }
    }
  }

  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }

  currentValue = value;

  function fake() {
    const ecnValue = encodeURIComponent(value);
    const searchUrl = getSearchUrl(path, params, ecnValue, method);
    const headers = {
      ...request.HEADERS_POST_AUTH(),
      method,
    }
    if (method === 'POST') {
      headers.body = JSON.stringify(body);
    }
    fetch(searchUrl, headers)
      .then(response => response.json())
      .then((d) => {
        if (currentValue === value) {
          const { results } = d;
          const data = [];
          results.forEach((r) => {
            if (typeof (textField) === 'string') {
              data.push({
                value: r.uid,
                text: getPropertyByNameString(r, textField),
              });
            } else {
              data.push({
                value: r.uid,
                text: textField(r),
              });
            }
          });
          callback(data)
        }
      })
      .catch((error) => {
      });
  }

  timeout = setTimeout(fake, 150);
}

class AutoInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      typed: '',
      value: '',
      selectedValue: '',
    };
  }

  handleSearch = (value) => {
    this.setState({ data: [] });
    if (value.length > 0) {
      this.setState({ typed: value });
      fetchData(value, this.props, data => this.setState({ data }));
    }
  };

  handleChange = (value, option) => {
    if (this.props.handleSelect) {
      this.props.handleSelect({
        value,
        option,
        selectType: this.props.selectType,
        title: this.props.title,
        handleTabAddRelation: this.props.handleTabAddRelation,
        handleChangeStateTab: this.props.handleChangeStateTab,
        newKeyForNew: this.props.newKeyForNew,
      });
    }

    if (this.props.newObjChanged) {
      this.props.newObjChanged({ uid: option.key });
    }
  };

  render() {
    const {
      getFieldDecorator,
      objkey,
      selectkey,
      label,
      formItemLayout,
      placeholder,
      addoptiontext,
      noAddOption,
      rules,
      selectType,
      stateTabs,
      t,
    } = this.props;

    const options =
      this.state.data
        .map(d =>
          <Option key={d.value} selectkey={selectkey} objkey={`${objkey}`}>{d.text}</Option>
        );

    if (selectType && stateTabs) {
      const stateTab = stateTabs.filter(tab => tab.title === selectType);
      if (stateTab[0] && stateTab[0].subj) {
        const addOptions = stateTab[0].subj
          .filter(sub => sub.status === 'new')
          .map(d => (
            <Option
              key={d.key}
              status="otherTab"
              objkey={`${d.key}`}
              parentkey={`${objkey}`}
              objtype={selectType}
              value={d.value}
            >{d.value} (Новый)</Option>
          ));
        options.push(...addOptions)
      }
    }

    const valueFormatter = (e, q) => {
      if (e === 'new') {
        return e;
      }
      if (q.props) {
        return q.props.children
      }
      return [];
    }

    const subjName = (selectType === 'culture') ?
      this.state.typed :
      null

    return (
      <FormItem
        {...formItemLayout}
        label={label}
      >
        {getFieldDecorator(selectkey, {
          getValueFromEvent: valueFormatter,
          ...rules,
        })(
          <Select
            showSearch
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            notFoundContent={null}
          >
            {options}
            {!noAddOption &&
              <Option
                key="new"
                value="new"
                objkey={`${objkey}`}
                className="auto-input-option-new"
              >
                {subjName || addoptiontext}
              </Option>
            }
          </Select>)}
      </FormItem>
    );
  }
}

export default AutoInput;
