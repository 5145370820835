import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import getUrl from 'utility/images'

import './index.css';

const indexBgW = getUrl('bolgar_categories/bg.webp')
const cat1W = getUrl('bolgar_categories/Icon1.webp')
const cat2W = getUrl('bolgar_categories/Icon2.webp')
const cat3W = getUrl('bolgar_categories/Icon3.webp')
const cat4W = getUrl('bolgar_categories/Icon4.webp')
const cat5W = getUrl('bolgar_categories/Icon5.webp')
const cat6W = getUrl('bolgar_categories/Icon6.webp')
const cat7W = getUrl('bolgar_categories/Icon7.webp')
const cat8W = getUrl('bolgar_categories/Icon8.webp')
const cat9W = getUrl('bolgar_categories/Icon9.webp')
const indexBg = getUrl('bolgar_categories/bg.png')
const cat1 = getUrl('bolgar_categories/Icon1.png')
const cat2 = getUrl('bolgar_categories/Icon2.png')
const cat3 = getUrl('bolgar_categories/Icon3.png')
const cat4 = getUrl('bolgar_categories/Icon4.png')
const cat5 = getUrl('bolgar_categories/Icon5.png')
const cat6 = getUrl('bolgar_categories/Icon6.png')
const cat7 = getUrl('bolgar_categories/Icon7.png')
const cat8 = getUrl('bolgar_categories/Icon8.png')
const cat9 = getUrl('bolgar_categories/Icon9.png')


const mapStateToProps = state => ({});

const mapDispatchToProps = {};

const locationNames = {
  BOLGAR: 'search_panel.location.bolgar',
  SVIYAZHSK: 'search_panel.location.sviyazhsk',
  FOLKLORE: 'search_panel.location.folklore'
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class FolkloreCategoriesView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  getCategories = (t) => [
    { img: cat1, imgW: cat1W, text: t('folklore-cat.cat1') },
    { img: cat2, imgW: cat2W, text: t('folklore-cat.cat2') },
    { img: cat3, imgW: cat3W, text: t('folklore-cat.cat3') },
    { img: cat4, imgW: cat4W, text: t('folklore-cat.cat4') },
  ];

  goToPage = (e) => {
    e.preventDefault();
    const categoryId = e.target.dataset.catid
    const { location } = this.props.match.params;
    global.QSBool = 'false'
    const pageUrl = `/folklore/?sh_cmd=${categoryId}`;
    this.props.history.push(pageUrl)
    // const pageUrl = `/quick-search/${location}/category/${categoryId}`;
  }

  render() {
    const { t, match } = this.props;
    const location = (match && match.params && match.params.location) ?
      locationNames[match.params.location] : '';
    const categories = this.getCategories(t);

    return (
      <div className="container">
        <div className="index-bg bolgar-index-bg">
          <picture>
            <source srcSet={indexBgW} type="image/webp" />
            <source srcSet={indexBg} type="image/png" />
            <img className="index-bg-img bolgar-index-bg-img" src={indexBg} alt="culturetat" />
          </picture>
        </div>
        <main className="bolgar-categories-main">
          <header>
            <h1>
              {`${t(location)}: ${t('bolgar.categories')}`}
            </h1>
            <div className="bolgar-categories-flex">
              {
                categories.map((item, id) => (
                  <div
                    className="bolgar-category"
                    data-catid={id}
                    onClick={this.goToPage}
                    role="button"
                    key={item.text}
                    tabIndex={0}
                  >
                    <picture>
                      <source srcSet={item.imgW} type="image/webp" />
                      <source srcSet={item.img} type="image/png" />
                      <img className="bolgar-category-img" data-catid={id} src={item.img} alt="culturetat" />
                    </picture>
                    <span className="bolgar-category-text" data-catid={id}>
                      {item.text}
                    </span>
                  </div>
                ))
              }
            </div>
          </header>
        </main>
      </div>
    )
  }
}

export default FolkloreCategoriesView;
