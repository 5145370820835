import React from 'react';
import { Modal, Form, Input, Button, Alert, message } from 'antd';

import { safelyPost, HEADERS_POST_AUTH } from '../../../utility/request';
import './index.css';

const FormItem = Form.Item;

const renderErrors = (error, t) => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`profile.form.passwordChange.${item}`)}: ${error[item]}`} type="error" />
    )
  });
  return result;
}

class ChangePasswordModal extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    isError: {},
    password: {
      old: '',
      new: '',
      confirm: '',
    },
  }

  compareToFirstPassword = (rule, val, callback) => {
    const { t, form } = this.props;
    if (val && val !== form.getFieldValue('password')) {
      callback(t('profile.form.passwordChange.errorConfirm'));
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, val, callback) => {
    const { form } = this.props;
    if (val) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  }

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  config = {
    password: {
      rules: [{
        required: true, message: this.props.t('profile.form.passwordChange.empty'),
      }],
      initialValue: this.state.password.old,
    },
    new_password: {
      rules: [{
        required: true, message: this.props.t('profile.form.passwordChange.empty'),
      }, {
        validator: this.validateToNextPassword,
      }],
      initialValue: this.state.password.new,
    },
    new_password_confirm: {
      rules: [{
        required: true, message: this.props.t('profile.form.passwordChange.empty'),
      }, {
        validator: this.compareToFirstPassword,
      }],
      initialValue: this.state.password.confirm,
    },
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    const { form } = this.props;
    this.setState({
      confirmLoading: true,
    });
    form.validateFields((err, fieldsValue) => {
      if (err) {
        this.setState({
          confirmLoading: false,
        });
        return;
      }
      const body = {
        old_password: fieldsValue.old_password,
        password: fieldsValue.password,
      };
      safelyPost('api/author/profile/change_password/', body, HEADERS_POST_AUTH())
        .then(({ response, error }) => {
          if (response) {
            this.setState({
              visible: false,
              confirmLoading: false,
            });
            message.success(this.props.t('profile.form.passwordChange.success'));
          } else {
            this.setState({
              confirmLoading: false,
              isError: error,
            });
          }
        })
    });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
      isError: '',
    });
  }

  render() {
    const { visible, confirmLoading, isError } = this.state;
    const { t, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className="change-password">
        <Button onClick={this.showModal}>
          {t('profile.form.passwordChange.button')}
        </Button>
        {visible &&
          <Modal
            title={t('profile.form.passwordChange.title')}
            visible={visible}
            onOk={this.handleOk}
            confirmLoading={confirmLoading}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>{t('profile.form.passwordChange.cancel')}</Button>,
              <Button key="submit" type="primary" loading={confirmLoading} onClick={this.handleOk}>
                {t('profile.form.passwordChange.submit')}
              </Button>,
            ]}
          >
            {isError && renderErrors(isError, t)}
            <Form onSubmit={this.handleOk}>
              <FormItem
                label={t('profile.form.passwordChange.old_password')}
                {...this.formItemLayout}
              >
                {getFieldDecorator('old_password', this.config.password
                )(<Input type="password" />)}
              </FormItem>
              <FormItem
                label={t('profile.form.passwordChange.password')}
                {...this.formItemLayout}
              >
                {getFieldDecorator('password', this.config.new_password
                )(<Input type="password" />)}
              </FormItem>
              <FormItem
                label={t('profile.form.passwordChange.confirmPassword')}
                {...this.formItemLayout}
              >
                {getFieldDecorator('confirmPassword', this.config.new_password_confirm
                )(<Input type="password" />)}
              </FormItem>
            </Form>
          </Modal>
        }
      </div>
    );
  }
}

export default Form.create()(ChangePasswordModal);
