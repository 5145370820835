import i18n from 'i18next';

export const relayDefinition = (item, field) => {
  if (item[field] && item[field].edges) {
    return item[field].edges.map((edge) => {
      return edge.node
    });
  }
  return [];
}

export const getAttributes = (item) => {
  const attrs = {};
  if (item.attributes && item.attributes.edges.length) {
    item.attributes.edges.forEach((attr, id) => {
      attrs[attr.node.label.toLowerCase()] = { name: attr.node[`${i18n.language}Name`], sort: attr.node.sort }
    })
  }
  return attrs;
}
