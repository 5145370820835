import {
  TAB_TYPE,
  createCommonField,
  createImageField,
  createLinkField,
  getLangTitle,
} from 'utility/transformData/constants';

export default function getPersonFields(d, tabType) {
  const r = d;

  const { years, years_desc } = (r.date_of_death) ? {
    years: `${r.date_of_birth}—${r.date_of_death}`,
    years_desc: 'years',
  } : {
    years: `${r.date_of_birth}`,
    years_desc: 'birth_year',
  }

  switch (tabType) {
    case TAB_TYPE.MAIN:
      return [
        createCommonField('name', getLangTitle(r)),
        createImageField('photo', r.photo),
        createCommonField(years_desc, years),
        createCommonField('role', r.role),
        createCommonField('info', r.info),
        createCommonField('desc', getLangTitle(r.interpretations[0], 'desc')),
      ];
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const { y, y_desc } = (item.date_of_death) ? {
          y: `${item.date_of_birth}—${item.date_of_death}`,
          y_desc: 'years',
        } : {
          y: `${item.date_of_birth}`,
          y_desc: 'birth_year',
        }
        const interpretation = (item.interpretations) ? item.interpretations[0] : item.research;
        const itemObj = {}
        itemObj.content = [
          createLinkField('name', item.uid, `subjects/${item.label || d.label}`,
            getLangTitle(item)),
          createImageField('photo', item.photo),
          createCommonField(y_desc, y),
          createCommonField('role', item.role),
          createCommonField('info', item.info),
          createCommonField('desc', getLangTitle(interpretation, 'desc')),
        ]
        itemObj.name = getLangTitle(item)
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
