import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { listQueries } from 'components/CreatePanel/queries/getList';
import archQueries  from 'components/GSearchPanel/Queries/archQueries';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import { connect } from 'react-redux';
import { actions } from 'redux/modules/create';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import i18n from 'i18next';

import config from '../config';
import AutoInputGQL from '../../fields/autoInputGQL';

const FormItem = Form.Item;

const mapStateToProps = state => ({
  createData: state.create,
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
class CreateThesisAbstractForm extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      newPublisher: false,
      newPublisherName: ''
    };
  }

  handleSelectPublisher = (data) => {
    if (data.value === 'new') {
      this.setState({ newPublisher: true, newPublisherName: data.typed });
    } else {
      this.setState({ newPublisher: false });
    }
  }

  handleSelectAdvisor = (data) => {
    this.props.form.setFieldsValue({ advisor: data.uid });
  }

  handleSelectOfficialOpponent = (data) => {
    this.props.form.setFieldsValue({ official_opponent: data.uid });
  }

  handleSelectExternalReviewer = (data) => {
    this.props.form.setFieldsValue({ external_reviewer: data.uid });
  }

  handlePublisherChange = (data) => {
    this.props.form.setFieldsValue({ publisher: data.uid });
  }

  handleOrgChange = (data) => {
    this.props.form.setFieldsValue({ organization: data.uid });
  }

  render() {
    const {
      getFieldDecorator,
      formItemLayout,
      createData,
      t,
    } = this.props;

    const { newPublisher } = this.state;

    return (
      <div>
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <CommonSelect
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          inputName={`degree`}
          label={t('create_panel.modal.addNewReport.degree.title')}
          selectQuery={attributeQueries.defaultSearch('dissertationScienceDegrees')}
          dontNeedEmptyOption="true"
          t={t}
        />
        <CommonSelect
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          inputName={`speciality`}
          label={t('create_panel.modal.addNewReport.speciality.title')}
          selectQuery={attributeQueries.defaultSearch('dissertationSpecialities')}
          dontNeedEmptyOption="true"
          t={t}
        />
        <AutoInputGQL
          newObjChanged={this.handleSelectAdvisor}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          label={t('create_panel.modal.addNewReport.advisor.title')}
          selectkey="source_advisor"
          noAddOption
          query={archQueries.authorSearch()}
          params={[{ key: 'search', val: 'value' }]}
          t={t}
        />
        <AutoInputGQL
          newObjChanged={this.handleSelectOfficialOpponent}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          label={t('create_panel.modal.addNewReport.official_opponent.title')}
          selectkey="source_official_opponent"
          noAddOption
          query={archQueries.authorSearch()}
          params={[{ key: 'search', val: 'value' }]}
          t={t}
        />
        <AutoInputGQL
          newObjChanged={this.handleSelectExternalReviewer}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          label={t('create_panel.modal.addNewReport.external_reviewer.title')}
          selectkey="source_external_reviewer"
          noAddOption
          query={listQueries.organizationSearch()}
          params={[{ key: 'search', val: 'value' }]}
          t={t}
        />
        <FormItem>
          {getFieldDecorator('advisor', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('official_opponent', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('external_reviewer', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('publisher', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('organization', {})(
            <Input hidden />)}
        </FormItem>
      </div>
    );
  }
}

export default CreateThesisAbstractForm;
