import React, { Component, Fragment } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'
import folkloreQueries from '../../../GSearchPanel/Queries/folkloreQueries'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class FolkCharacterTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.folkcharacter.folkcharacter')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                key={`folkcharacter_select_${key}`}
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.folkcharacter.choose')}
                selectkey={`folkcharacter_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.folkcharacter.add')}
                query={folkloreQueries.characterSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="FolkCharacter"
                t={t}
              />  
              {(item.status === 'new' || item.status === 'selected') &&   (
                <div>                    
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkcharacter.name')}
                  >
                    {getFieldDecorator(`folkcharacter_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                  {...formInputLayout}
                  label={t('common.rudescription')}
                  >
                  {getFieldDecorator(`folkcharacter_ru_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folkcharacter_rudescription_${key}`)} />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.endescription')}
                >
                  {getFieldDecorator(`folkcharacter_en_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folkcharacter_endescription_${key}`)} />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.tatdescription')}
                >
                  {getFieldDecorator(`folkcharacter_tat_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folkcharacter_tatdescription_${key}`)} />)}
                </FormItem>
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="FolkCharacter"
                    tabName="folkcharacters"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.folkcharacter.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default FolkCharacterTab
