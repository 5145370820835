import React, { Component, Fragment } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'
import folkloreQueries from '../../../GSearchPanel/Queries/folkloreQueries'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import lingQueries from '../../../GSearchPanel/Queries/lingQueries'
import archQueries from '../../../GSearchPanel/Queries/archQueries'

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class FolkVariantTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.folkvariant.folkvariant')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                key={`folkvariant_select_${key}`}
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.folkvariant.choose')}
                selectkey={`folkvariant_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.folkvariant.add')}
                query={folkloreQueries.variantSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="FolkVariant"
                t={t}
              />  
              {(item.status === 'new' || item.status === 'selected') &&   (
                <div>                    
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.runame')}
                  >
                    {getFieldDecorator(`folkvariant_ru_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.enname')}
                  >
                    {getFieldDecorator(`folkvariant_en_name_${key}`, {
                    })(
                      <Input/>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.tatname')}
                  >
                    {getFieldDecorator(`folkvariant_tat_name_${key}`, {
                    })(
                      <Input/>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.rudesc')}
                  >
                    {getFieldDecorator(`folkvariant_ru_desc_${key}`, {
                    })(
                      <Input/>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.endesc')}
                  >
                    {getFieldDecorator(`folkvariant_tat_en_name_${key}`, {
                    })(
                      <Input/>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.tatdesc')}
                  >
                    {getFieldDecorator(`folkvariant_tat_desc_${key}`, {
                    })(
                      <Input/>,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.recorddate')}
                  >
                    {getFieldDecorator(
                      `folkvariant_record_date_${key}`,{
                        ...config.req,},
                    )(
                      <div>
                        <Input type="date" min={0} max={new Date().getFullYear()} />
                      </div>,
                    )}
                  </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('create_panel.tab.folkvariant.recordtype')}
                >
                  {getFieldDecorator(`folkvariant_record_type_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folkvariant_recordtype_${key}`)} />)}
                </FormItem>
                <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folkvariant.text')}
                  >
                    {getFieldDecorator(
                      `folkvariant_text_${key}`,
                      {},
                    )(
                      <TextArea
                        onPaste={e =>
                          textFieldFormatters.onTextAreaPaste(
                            e,
                            form,
                            `folkvariant_text_${key}`,
                          )
                        }
                      />,
                    )}
                  </FormItem>
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Culture"
                    dontShowOnMap
                    connectionField="culture"
                    label={t('common.culture')}
                    selectkey={`folkvariant_culture_${key}`}
                    selectType="cultures"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={archQueries.cultureSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  {item.status === 'new' &&
                  <Fragment>
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folkvariant_epoch_${key}`}
                      label={t('common.epoch')}
                      selectQuery={attributeQueries.defaultSearch('epoches')}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folkvariant_language_${key}`}
                      label={t('common.language')}
                      selectQuery={lingQueries.languageSearch()}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                  </Fragment>
                }
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Vernacular"
                    connectionField="vernacular"
                    label={t('common.vernacular')}
                    selectkey={`folkvariant_vernacular_${key}`}
                    selectType="vernacular"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={lingQueries.vernacularSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Lexeme"
                    connectionField="lexeme"
                    label={t('common.lexeme')}
                    selectkey={`folkvariant_lexeme_${key}`}
                    selectType="lexeme"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={lingQueries.lexemeSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Dialect"
                    connectionField="dialect"
                    label={t('common.dialect')}
                    selectkey={`folkvariant_dialect_${key}`}
                    selectType="dialect"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={lingQueries.dialectSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="EncOrganisation"
                    connectionField="encorganization"
                    label={t('common.encorganization')}
                    selectkey={`folkvariant_encorganization_${key}`}
                    selectType="encorganization"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={encQueries.encOrgsSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Person"
                    connectionField="person"
                    label={t('create_panel.tab.folkvariant.person')}
                    selectkey={`folkvariant_person_${key}`}
                    selectType="person"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={encQueries.personSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="FolkPerson"
                    connectionField="folkperson"
                    label={t('create_panel.tab.folkvariant.folkperson')}
                    selectkey={`folkvariant_folkperson_${key}`}
                    selectType="folkperson"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.personSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="FolkCharacter"
                    connectionField="folkcharacter"
                    label={t('create_panel.tab.folkvariant.folkcharacter')}
                    selectkey={`folkvariant_folkcharacter_${key}`}
                    selectType="folkcharacter"
                    stateTabs={stateTabs}
                    objkey={key}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.characterSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkVariant"
                    rightLabel="Locality"
                    connectionField="locality"
                    label={t('common.subjects.locality')}
                    selectkey={`folkvariant_locality_${key}`}
                    selectType="localities"
                    stateTabs={stateTabs}
                    objkey={key}
                    noAddOption
                    query={encQueries.localitySelectSearch()}
                    params={[{ key: 'commonSearch', val: 'value' }]}
                    t={t}
                  />
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`folkvariant_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="FolkVariant"
                    tabName="FolkVariant"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.folkvariant.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default FolkVariantTab
