import React, { Component  } from 'react';
import { Select, Form, Tag, Input } from 'antd';
import { getPropertyByNameString } from 'utility/transformData/constants';
import { url } from 'utility/constants'
import * as request from 'utility/request';
import { getRenderTitle } from 'components/GSearchPanel/Results/renderTitle'
import './index.scss';

const FormItem = Form.Item;
const { Option } = Select;
const { CheckableTag } = Tag;

class SelectableTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  handleChange = checked => {
    this.setState({ checked });
    this.props.onChange(this.props['data-uid']);
  };

  render() {
    return (
      <CheckableTag {...this.props} checked={this.state.checked} onChange={this.handleChange} />
    );
  }
}

class SimpleMultiInput extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      checkedOptions: [],
      showBlocks: [],
    };
  }

  componentDidMount() {
    const { query } = this.props;
    const searchUrl = `${url}/api/graphql/`

    const headers = {
      ...request.HEADERS_POST_AUTH(),
      method: 'POST',
      body: JSON.stringify({
        query: query.query,
      }),
    }

    fetch(searchUrl, headers)
      .then(response => response.json())
      .then((d) => {
        const results = getPropertyByNameString(d, 'data.results.edges');
        const retData = [];
        const renderFunc = (query.url) ? getRenderTitle(query.url) : query.render;

        results.forEach(r => {
          const data = {
            value: r.node.uid,
            sort: r.node.sort,
            text: renderFunc(r),
          }

          const blockSort = getPropertyByNameString(r, 'node.block.edges.0.node.sort');
          data.blockSort = (blockSort) ? blockSort : null;

          retData.push(data)
        });
        this.setState({data: retData})
      })
      .catch((error) => {
      });
  }

  handleUpdateValues = (uid, block) => {
    const { selectkey } = this.props;
    const { checkedOptions, showBlocks } = this.state;

    let newOptions;
    if (checkedOptions.includes(uid)) {
      newOptions = checkedOptions.filter(el => el !== uid)
    } else {
      checkedOptions.push(uid);
      newOptions = checkedOptions;
    }

    if (block && !showBlocks.includes(block)) {
      showBlocks.push(block)
    }

    this.setState({checkedOptions: newOptions, showBlocks})
    this.props.form.setFieldsValue({ [selectkey]: newOptions});
  }

  render() {
    const {
      getFieldDecorator,
      selectkey,
      selectType,
      label,
      formLayout,
      rules,
    } = this.props;

    const { showBlocks } = this.state;

    let lastRenderedBlock = -1;
    const tags =
      this.state.data
        .map(d => {
          let hidden = false;
          const className = (d.blockSort) ? `${selectType}-${d.blockSort}` : `${selectType}`

          if (d.blockSort && !showBlocks.includes(d.blockSort) && d.blockSort === lastRenderedBlock) {
            hidden = true;
          }

          if (d.blockSort && d.blockSort !== lastRenderedBlock) {
            lastRenderedBlock = d.blockSort;
          }

          return (
            <SelectableTag
              onChange={(uid) => this.handleUpdateValues(uid, d.blockSort)}
              data-uid={d.value}
              key={d.value}
              sort={d.sort}
              block={d.blockSort}
              hidden={hidden}
              className={`tag-attribute ${className}`}
            >
              {d.text}
            </SelectableTag>
          )}
        );

    lastRenderedBlock = -1;
    return (
      <FormItem
        {...formLayout}
        label={label}
      >
        {getFieldDecorator(selectkey, {
          ...rules,
        })(
          <div className={'simple-multiple-input'}>
            {tags.map((item, key) => {
              if (key > 0 && item.props.block !== lastRenderedBlock) {
                lastRenderedBlock = item.props.block;
                return (
                  <span>
                    <br/>
                    {item}
                  </span>
                )
              }
              return (
                <span>
                  {item}
                </span>
              )
            })}
          </div>)}
      </FormItem>
    );
  }
}

export default SimpleMultiInput;
