import React from 'react'
import i18n from 'i18next'
import capitalize from 'lodash/capitalize'
import cyrillicToTranslit from 'distLibs/CyrillicToTranslit'
import { getLayer } from 'distLibs/images/monTypes/index'
import { getPropertyByNameString } from 'utility/transformData/constants'
import MapIcon from 'components/MapIcon'
import { getLayerCulture } from 'components/GSearchPanel/Queries/constants'

import { relayDefinition } from 'utility/graphql'
import { removeEmptyElements } from 'utility/transformData/constants'

export const getTitleWithFewNames = item => {
  if (!item.node || (!item.node.interpretation && !item.node.commonName)) {
    return ''
  }
  if (item.node.interpretation) {
    const names = item.node.interpretation
    const mainName = names.length > 0 ? names[0][`${i18n.language}Name`] : 'NA'
    const otherNames =
      names.length > 1
        ? names
            .slice(1)
            .map(i => i[`${i18n.language}Name`])
            .join(', ')
        : null
    return otherNames ? `${mainName} (${otherNames})` : `${mainName}`
  }
  const node = item.node.commonName
  const mainName = node[`${i18n.language}Name`]
  return mainName
}

export const getSourceName = item => {
  if (!item.node) {
    return 'Error'
  }
  const year = item.node.year ? item.node.year : 'Год незвестен'
  const title = item.node[`${i18n.language}Name`]
    ? item.node[`${i18n.language}Name`]
    : 'Нет названия'
  const author = item.node.author.edges[0]
    ? item.node.author.edges[0].node.publicName
    : 'Автор неизвестен'
  return `${title} (${author}, ${year})`
}

export const getRenderTitle = (type, lang = i18n.language) => {
  // return single function
  switch (type) {
    case 'subjects/Site':
      return item => {
        return `${getTitleWithFewNames(item)}`
      }
    case 'subjects/Layer':
      return item => {
        const image = <MapIcon icon="layer" {...getLayer(item)} />
        const culture = getLayerCulture(item, lang)

        return (
          <span>
            {image}
            {`${getTitleWithFewNames(item)}`}
, ({culture}
)
</span>
        )
      }
    case 'subjects/Monument':
      return item => {
        return `${getTitleWithFewNames(item)}`
      }
    case 'subjects/Excavation':
      return item => {
        const Lang = lang.charAt(0).toUpperCase() + lang.slice(1)

        const layer = getPropertyByNameString(item.node, 'layer.edges.0')
        const site = getPropertyByNameString(layer, 'node.site.edges.0')
        const siteName = getPropertyByNameString(site, `node.common${Lang}Name`)
        const sName = siteName && siteName.length ? `- ${siteName}` : ''


        let supervisors = []
        if (item.node.supervisor) {
          supervisors = item.node.supervisor.edges.map(v => {
            if (v.node.publicName) {
              return `${v.node.publicName}`
            }
            return ''
          })
        }
        const supervisorsNames = supervisors.length
          ? `(${supervisors.join(', ')})`
          : ''
        return `${item.node[`${lang}Name`]} ${sName} ${supervisorsNames}`
      }
    case 'subjects/Locality':
      return item => {
        const districts = []
        let districtsInfo = ''
        const dist = getPropertyByNameString(
          item,
          `node.district.edges.0.node.${lang}Name`,
        )
        const reg = getPropertyByNameString(
          item,
          `node.district.edges.0.node.region.edges.0.node.${lang}Name`,
        )

        if (dist) {
          districts.push(dist)
        }
        if (reg) {
          districts.push(reg)
        }
        if (districts.length) {
          districtsInfo = `(${districts.join(', ')})`
        }

        return `${item.node[`${lang}Name`]} ${districtsInfo}`
      }
    case 'subjects/District':
      return item => {
        let region = getPropertyByNameString(
          item,
          `node.region.edges.0.node.${lang}Name`,
        )
        if (region) {
          region = `(${region})`
        }

        return `${item.node[`${lang}Name`]} ${region}`
      }
    case 'subjects/Person':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/EncOrganization':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/Term':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/Lexeme':
      return item => {
        return `${item.node.name}`
      }
    case 'subjects/Vernacular':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'linguistic/variant/search':
      return item => {
        return `${item.node.names.join(', ')} (${item.node.sense})`
      }
    case 'research':
      return item => {
        const authorDefinition = relayDefinition(item.node, 'author')
        const author = authorDefinition[0]
          ? authorDefinition[0].publicName
          : 'Нет автора'
        return `${item.node[`${lang}Name`]} (${cyrillicToTranslit().t(
          author,
          i18n.language,
        )}, ${item.node.year})`
      }
    case 'source/Publication':
      return item => {
        return getSourceName(item)
      }
    case 'source/Report':
      return item => {
        return getSourceName(item)
      }
    case 'source/ScientificProjectDocumentation':
      return item => {
        return getSourceName(item)
      }
    case 'source/Book':
      return item => {
        return getSourceName(item)
      }
    case 'source/Periodical':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'source/ArticlePeriodical':
      return item => {
        return getSourceName(item)
      }
    case 'author':
      return item => {
        return `${cyrillicToTranslit().t(item.node.publicName, i18n.language)}`
      }
    case 'subjects/Culture':
      return item => {
        const field = `${lang}Name`
        const commonNames = item.node[`common${capitalize(lang)}Names`]
        let name = item.node[field]
        const alternatives = removeEmptyElements(
          commonNames.map(i => i.name).filter(altName => altName !== name),
        ).join(', ')
        if (alternatives && alternatives.length > 0) {
          name += ` (${alternatives})`
        }
        return name
      }
    case 'subjects/Heritage':
      return item => {
        return item.node[`${lang}Name`]
      }
    case 'subjects/Radiocarbon':
      return item => {
        return item.node.displayName
      }
    case 'subjects/Artifact':
      return item => {
        const author = getPropertyByNameString(
          item,
          'node.research.edges.0.node.author.edges.0.node.publicName',
        )
        const year = getPropertyByNameString(
          item,
          'node.research.edges.0.node.year',
        )
        return `${item.node[`${i18n.language}Name`]} (${author}, ${year})`
      }
    case 'subjects/FolkVariant':
      return item => {
        return `${getTitleWithFewNames(item)}`
      }
    case 'subjects/FolkPerson':
      return item => {
        return `${getTitleWithFewNames(item)}`
      }
    case 'subjects/FolkCharacter':
      return item => {
        return `${getTitleWithFewNames(item)}`
      }
    case 'subjects/FolkTale':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/FolkGenre':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/FolkGenreGroup':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/FolkType':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/FolkClass':
      return item => {
        return `${item.node[`${lang}Name`]}`
      }
    case 'subjects/Assemblage':
      const Lang = lang.charAt(0).toUpperCase() + lang.slice(1)
      return item => {
        const layer = getPropertyByNameString(item.node, 'layer.edges.0')
        const layerName = getPropertyByNameString(
          layer,
          `node.common${Lang}Name`,
        )
        const site = getPropertyByNameString(layer, 'node.site.edges.0')
        const siteName = getPropertyByNameString(site, `node.common${Lang}Name`)

        const culture = getLayerCulture(layer, lang)

        const imageSite = <MapIcon icon="site" {...getLayer(layer)} />
        const imageLayer = <MapIcon icon="layer" {...getLayer(layer)} />
        return (
          <span>
            {`${item.node[`${i18n.language}Name`]}`}
{' '}
({imageSite}
            {`${siteName}`}
,{imageLayer}
            {`${layerName}`}
,{culture}
)
</span>
        )
      }
    default:
      return () => {
        return 'NA'
      }
  }
}

export default getRenderTitle
