import React from 'react'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { Table, Tag, Icon } from 'antd'
import { getPropertyByNameString } from 'utility/transformData/constants'
import { getResearchUrl } from 'utility/transformData/constants'

import './index.scss'


const LANG = i18n.language || 'ru'

const TABLE_CELL_WIDTH = 180

const getLinkByLabel = (label, uid) => {
  switch (label) {
    case 'Author':
      return `/show/author/${uid}`
    case 'ArchaeologyResearch':
    case 'Research':
    case 'EncyclopediaResearch':
      return `/show/${getResearchUrl(label)}/${uid}`
    default:
      return `/show/subjects/${label}/${uid}`
  }
}

const SORT_ICON = {
  ascend: <Icon type="sort-ascending" />,
  descend: <Icon type="sort-descending" />,
}

export const AdvancedSearchResultTable = props => {
  const {
    schema,
    subject,
    t,
    isDataLoading,
    sorter,
    selected,
    pagination,
    removeSortField,
    handleTableChange,
  } = props
  const { fields = {} } = schema.get(subject.toLowerCase()) || {}

  const getColumns = () =>
    props.selected.map(item => {
      const isOrder = fields[item] ? fields[item].order : true
      const name = getPropertyByNameString(fields[item], `t.${LANG}`)

      return {
        title: name || item,
        dataIndex: item,
        key: item,
        sorter: isOrder,
        render: (text, record) => (
          <Link to={getLinkByLabel(subject, record.uid)}>{text}</Link>
        ),
      }
    })

  const getSource = () =>
    props.data.map((item, index) => ({
      ...item,
      key: `${props.currentPage}-${index}`,
    }))

  const removeSortTag = (e, item) => {
    e.stopPropagation()
    removeSortField({
      field: item[0],
      order: item[1],
    })
  }

  const handleTagClick = item => {
    handleTableChange(
      pagination,
      {},
      {
        field: item[0],
        order: item[1] === 'ascend' ? 'descend' : 'ascend',
      },
    )
  }

  const renderSorter = () => {
    const tags = Object.entries(sorter)
    return tags.map((item: [string, any]) => {
      const name = getPropertyByNameString(fields[item[0]], `t.${LANG}`)
      return (
        <Tag
          className="result_table_sorter_tag"
          closable
          color="blue"
          onClick={() => handleTagClick(item)}
          onClose={e => removeSortTag(e, item)}
        >
          {`${name} `}
          {SORT_ICON[item[1]]}
        </Tag>
      )
    })
  }

  return (
    <div className="result_table">
      <div className="result_table_sorter">{renderSorter()}</div>
      <Table
        columns={getColumns()}
        dataSource={getSource()}
        style={{ whiteSpace: 'pre' }}
        loading={isDataLoading}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: TABLE_CELL_WIDTH * selected.length }}
        footer={() => `${t('selection.table.num')}: ${pagination.total || 0}`}
      />
    </div>
  )
}

export default AdvancedSearchResultTable
