import i18n from 'i18next';

export function getFullName(user) {
  if (user.last_name) {
    return `${user.last_name} ${user.first_name} ${user.parent_name}`
  } else if (user.ru_name) {
    return user[`${i18n.language}_name`] || user.ru_name
  }
  return '-'
}

export function getEmail(user) {
  return user.email || ''
}

export function getItemName(item, type) {
  if (type === 'Radiocarbon') {
    if (item.subject) {
      return `BP: ${item.subject.r_date} ± ${item.subject.dispersion}`
    }
    if (item.r_date && item.dispersion) {
      return `BP: ${item.r_date} ± ${item.dispersion}`
    }
  }
  if (item.name) {
    return item.name
  }
  if (item.ru_name) {
    return item.ru_name
  }
  if (item.node) {
    if (item.node.name) {
      return item.node.name
    }

    if (item.node.names) {
      return item.node.names.join(', ')
    }

    if (item.node[`${i18n.language}Name`]) {
      return item.node[`${i18n.language}Name`]
    }

    if (item.node.commonName) {
      return item.node.commonName[`${i18n.language}Name`]
    }

    if (item.node.interpretation && item.node.interpretation[0]) {
      return item.node.interpretation[0][`${i18n.language}Name`]
    }
  }
  if (item.names) {
    if (typeof item.names === 'object') {
      return item.names.map(d => d.ru_name).join(', ')
    }
    return item.names.join(', ')
  }
  if (item.interpretations && item.interpretations[0] && item.interpretations[0].ru_name) {
    return item.interpretations[0].ru_name
  }
  if (item.research && item.research.ru_name) {
    return item.research.ru_name
  }
  if (item.subject) {
    return item.subject.ru_name
  }
  return '-'
}

export function getItemArea(item, type) {
  if (type === 'Excavation') {
    if (item.subject) {
      return item.subject.area
    }
    if (item.area) {
      return item.area
    }
    return null;
  }
  return null;
}

export function getName(response, index, type) {
  const results = response.results || response.result || response
  const item = results[index]
  return getItemName(item, type)
}
