import { put, fork, takeLatest, call, all } from 'redux-saga/effects'
import {
  getResearch,
  getResearchLabelList,
  getResearchSpatialReference,
} from 'redux/api/research'
import {
  getSubjectById,
  getSubjectByLabelBody,
  getSubjectSpatials,
  getSubjectTabs,
} from 'redux/api/subject'
import { hashHistory } from 'index'
import { graphqlPost } from 'redux/api/graphql'
import termQueries from 'utility/graphql/term'
import lexemeQueries from 'utility/graphql/lexeme'
import localityQueries from 'utility/graphql/locality'
import researchQueries from 'utility/graphql/research'
import { getSiteLayers } from 'redux/api/site'
import {
  constants as exampleConstants,
  actions as exampleActions,
} from 'redux/modules/example'
import { getItemName, getName, getItemArea } from 'utility/name'

const queries = {
  Term: termQueries,
  Lexeme: lexemeQueries,
  Locality: localityQueries,
  Research: researchQueries,
}
// don't show 'culture not defined'
const DONT_SHOW = ['189478e0-ea8e-4d27-9dfc-d88a36ab90bb']
const BookSeriesData = () =>
  import('utility/transformData/objects/bookSeries/index')
const AuthorData = () => import('utility/transformData/objects/author/index')
const ResearchData = () =>
  import('utility/transformData/objects/research/index')
const ResearchEncData = () =>
  import('utility/transformData/objects/researchEnc/index')
const ResearchArchData = () =>
  import('utility/transformData/objects/researchArch/index')
const FolkResearchData = () =>
  import('utility/transformData/objects/folklore/folkResearch/index')
const HistResearchData = () =>
  import('utility/transformData/objects/writing/histResearch/index')

const subjectData = {
  Site: () => import('utility/transformData/objects/site/index'),
  Culture: () => import('utility/transformData/objects/culture/index'),
  Layer: () => import('utility/transformData/objects/layer/index'),
  Excavation: () => import('utility/transformData/objects/excavation/index'),
  Assemblage: () => import('utility/transformData/objects/assemblage/index'),
  Artifact: () => import('utility/transformData/objects/artifact/index'),
  Radiocarbon: () => import('utility/transformData/objects/radiocarbon/index'),
  Heritage: () => import('utility/transformData/objects/heritage/index'),
  Monument: () => import('utility/transformData/objects/monument/index'),
  Locality: () => import('utility/transformData/objects/locality/index'),
  TopographicPlan: () =>
    import('utility/transformData/objects/topographicPlan/index'),
  District: () => import('utility/transformData/objects/district/index'),
  EncOrganization: () =>
    import('utility/transformData/objects/encOrganization/index'),
  HistOrganization: () =>
    import('utility/transformData/objects/histOrganization/index'),
  Person: () => import('utility/transformData/objects/person/index'),
  FolkTale: () => import('utility/transformData/objects/folklore/folkTale'),
  FolkVariant: () =>
    import('utility/transformData/objects/folklore/folkVariant/index'),
  FolkCharacter: () =>
    import('utility/transformData/objects/folklore/folkCharacter/index'),
  FolkPerson: () =>
    import('utility/transformData/objects/folklore/folkPerson/index'),
  WrMonument: () =>
    import('utility/transformData/objects/writing/wrMonument/index'),
  WrMonAddon: () =>
    import('utility/transformData/objects/writing/wrMonAddon/index'),
  WrMonNote: () =>
    import('utility/transformData/objects/writing/wrEntriesLitters/index'),
  WrMonDesign: () =>
    import('utility/transformData/objects/writing/wrMonDesign/index'),
  WrCountermark: () =>
    import('utility/transformData/objects/writing/wrCountermark/index'),
  WrEpi: () => import('utility/transformData/objects/writing/wrEpi/index'),
  WrCodex: () => import('utility/transformData/objects/writing/wrCodex/index'),
  WrFiligree: () =>
    import('utility/transformData/objects/writing/wrFiligree/index'),
  WrManuscript: () =>
    import('utility/transformData/objects/writing/wrManuscript/index'),
  WrWork: () => import('utility/transformData/objects/writing/wrWork/index'),
}

const sourceData = {
  Report: () => import('utility/transformData/objects/report'),
  ResearchReport: () => import('utility/transformData/objects/researchReport'),
  ScientificProjectDocumentation: () =>
    import('utility/transformData/objects/scienceDoc'),
  Book: () => import('utility/transformData/objects/book'),
  ThesisAbstract: () => import('utility/transformData/objects/thesisAbstract'),
  ConferenceMaterial: () =>
    import('utility/transformData/objects/conferenceMaterial'),
  ArticlePeriodical: () =>
    import('utility/transformData/objects/articlePeriodical'),
  Periodical: () => import('utility/transformData/objects/periodical'),
  Issue: () => import('utility/transformData/objects/issue'),
  BookSection: () => import('utility/transformData/objects/bookSection'),
  ArchaeologicalMap: () =>
    import('utility/transformData/objects/archaeologicalMap'),
}

export function* getSiteTabLayers(tabs, tabResponse) {
  return yield all(
    tabs.map((item, index) => {
      if (tabResponse.tabs[index].label === 'Site') {
        return item.response.results.map(site => call(getSiteLayers, site.uid))
      }
      return []
    }),
  )
}

function needTab(type, label) {
  if (type === 'Locality' && label === 'Variant') {
    return false
  }
  if (type === 'Term' && label === 'Lexeme') {
    return false
  }
  return true
}

export function* setTabsContent(tabs, type, layers, tabResponse) {
  yield all(
    tabs.map((item, index) => {
      const tabItem = {
        ...item.response,
        mainTabType: type,
        label: tabResponse.tabs[index].label,
      }
      if (tabResponse.tabs[index].label === 'Site') {
        tabItem.layers = layers[index]
      }
      if (tabResponse.tabs[index].label === 'Layer') {
        return null
      }
      if (tabResponse.tabs[index].label === 'District') {
        return null
      }
      if (tabResponse.tabs[index].label === 'Culture') {
        return null
      }
      if (type === 'research' && tabResponse.tabs[index].label === 'Artifact') {
        return null
      }
      if (
        type === 'research' &&
        tabResponse.tabs[index].label === 'Assemblage'
      ) {
        return null
      }
      if (type === 'research' && tabResponse.tabs[index].label === 'Culture') {
        return null
      }
      // if (type === 'research' && tabResponse.tabs[index].label === 'Site') {
      //   return null
      // }
      if (
        type === 'research' &&
        tabResponse.tabs[index].label === 'Excavation'
      ) {
        return null
      }
      if (
        type === 'research' &&
        tabResponse.tabs[index].label === 'Radiocarbon'
      ) {
        return null
      }
      if (type === 'Layer' && tabResponse.tabs[index].label === 'Site') {
        return null
      }
      if (type === 'Layer' && tabResponse.tabs[index].label === 'Assemblage') {
        return null
      }
      if (type === 'Layer' && tabResponse.tabs[index].label === 'Artifact') {
        return null
      }
      if (type === 'Layer' && tabResponse.tabs[index].label === 'Monument') {
        return null
      }
      if (
        type === 'Excavation' &&
        tabResponse.tabs[index].label === 'Assemblage'
      ) {
        return null
      }
      if (
        type === 'Excavation' &&
        tabResponse.tabs[index].label === 'Artifact'
      ) {
        return null
      }
      if (
        type === 'Assemblage' &&
        tabResponse.tabs[index].label === 'Radiocarbon'
      ) {
        return null
      }
      if (type === 'Lexeme' && tabResponse.tabs[index].label === 'Term') {
        return null
      }
      if (type === 'Term' && tabResponse.tabs[index].label === 'Lexeme') {
        return null
      }
      if (type === 'Lexeme' && tabResponse.tabs[index].label === 'Variant') {
        return null
      }
      if (type === 'Variant' && tabResponse.tabs[index].label === 'Lexeme') {
        return null
      }
      if (type === 'Locality' && tabResponse.tabs[index].label === 'Variant') {
        return null
      }
      if (type === 'Monument' && tabResponse.tabs[index].label === 'Site') {
        return null
      }
      if (type === 'Site' && tabResponse.tabs[index].label === 'Monument') {
        return null
      }
      return put(exampleActions.getShowPageDataSuccess(tabItem))
    }),
  )
}

// export function* fetchResearchSpatials(type: string, id) {
//   const spatials = yield call(getResearchLabelSpatialReference, id, type)
//   const pointsObj = {
//     points: spatials.response.reduce((result, item, index) => {
//       result.push({
//         title: getItemName(item, type),
//         area: getItemArea(item, type),
//         subject_id: item.subject_id,
//         points: item.points,
//         attributes: item.attributes,
//         uid: item.uid,
//       })
//       return result
//     }, []),
//     name: type,
//     type: `subjects/${type}`,
//   }
//   if (type === 'Site') {
//     pointsObj.isHidden = true
//   }
//   console.info('pointsObj', pointsObj)
//   if (pointsObj.points.length > 0) {
//     yield put(exampleActions.getShowPagePointsSuccess(pointsObj))
//   }
// }

export function* fetchSpatials(type, response) {
  if (type === 'research') {
    const spatials = yield all(
      response.results.map(item => call(getResearchSpatialReference, item.uid)),
    )
    // console.info('spatials', spatials)
    const pointsObj = {
      points: spatials.reduce((result, item, index) => {
        return result.concat(
          item.response.results.map(point => ({
            title: getName(response, index, type),
            subject_id: response.results[index].uid,
            attributes: point.attributes,
            gradation_type: point.gradation_type,
            points: point.points,
            uid: point.uid,
          })),
        )
      }, []),
      name: 'research',
      type: 'research',
    }
    // console.info('pointsObj', pointsObj);
    if (pointsObj.points.length > 0) {
      yield put(exampleActions.getShowPagePointsSuccess(pointsObj))
    }
    return pointsObj
  }
  const results = response.results || response.result
  const spatials = yield all(
    results.map(item => call(getSubjectSpatials, item.uid, type)),
  )
  console.info('SPATIAL spatials', spatials)
  const pointsObj = {
    points: spatials.reduce((result, item, index) => {
      if (!item.response) {
        return []
      }
      const points = item.response.results || item.response
      const localPoints =
        points[0] && points[0].localities ? points[0].localities : points
      return result.concat(
        localPoints.map(point => ({
          title: getName(response, index, type),
          subject_id: results[index].uid,
          attributes: point.attributes,
          gradation_type: point.gradation_type,
          group_number: point.group_number || '',
          index,
          points: point.points,
          uid: point.uid,
          area: getItemArea(results[index], type),
        })),
      )
    }, []),
    name: type,
    isHidden: false,
    type: `subjects/${type}`,
  }
  if (type === 'Site') {
    pointsObj.isHidden = true
  }
  // console.info('pointsObj', pointsObj);
  if (pointsObj.points.length > 0) {
    yield put(exampleActions.getShowPagePointsSuccess(pointsObj))
  }
  return pointsObj
}

export function* getResearchPreviewCultures(id, label = 'Culture') {
  const items = yield call(getResearchLabelList, id, label)
  // console.info(label, items);
  if (items.response && items.response.count > 0) {
    return items
  }
  return null
}

// export function* getResearchSubject(id, label) {
//   const items = yield call(getResearchLabelList, id, label)
//   // console.info(label, items);
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: 'Research',
//         label,
//       })
//     )
//   }
// }

// export function* getResearchExc(id) {
//   const items = yield call(getResearchExcavations, id)
//   // console.info('Excavation', items);
//   if (
//     items.response &&
//     items.response.results &&
//     items.response.results.length > 0
//   ) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: 'Research',
//         label: 'Excavation',
//       })
//     )
//   }
// }

export function* graphqlQuery(parentS, childS, vars, needSpatials = true) {
  const query = queries[parentS][childS].q
  const items = yield call(graphqlPost, {
    query,
    vars,
  })
  const dPath = queries[parentS][childS].dataPath
  const d = items.response.data[dPath[0]]
  let viewData = d
  for (let i = 1; i < dPath.length; i++) {
    if (viewData.edges[0]) {
      viewData = viewData.edges[0].node[dPath[i]]
    }
  }
  if (viewData.edges.length) {
    yield put(
      exampleActions.getShowPageDataSuccess({
        results: viewData.edges,
        mainTabType: parentS,
        label: childS,
      }),
    )
  }

  if (needSpatials) {
    const sPath = queries[parentS][childS].spatialPath
    const dSpatial = queries[parentS][childS].dataForSpatial
    const tmpData = {}
    const spatials = []

    const rec = function(data, path, depth) {
      if (path.length - 1 === depth) {
        const spatial = Object.assign({}, data.edges[0].node)
        spatial.points = JSON.parse(spatial.points)
        dSpatial.forEach((param, id) => {
          if (param.fieldPath === 'index') {
            spatial[param.title] = tmpData[param.node].index
          } else {
            let value = tmpData[param.node].node
            for (let i = 0; i < param.fieldPath.length; i++) {
              value = value[param.fieldPath[i]]
            }
            spatial[param.title] =
              typeof value === 'object' ? value.join(', ') : value
          }
        })
        spatials.push(spatial)
      } else {
        data.edges.forEach((dataItem, id) => {
          tmpData[path[depth]] = { node: dataItem.node, index: id }
          rec(dataItem.node[path[depth + 1]], path, depth + 1)
        })
      }
    }

    rec(d, sPath, 0)

    if (spatials.length > 0) {
      yield put(
        exampleActions.getShowPagePointsSuccess({
          points: spatials,
          name: childS,
          type: `subjects/${childS}`,
        }),
      )
    }
  }
}

// export function* fetchResearch(action) {
//   const { category, type } = action.payload
//   const { response } = yield call(getResearchInfo, type)
//   if (response) {
//     response.mainTabType = category
//     yield put(exampleActions.getShowPageDataSuccess(response))
//   } else {
//     yield put(exampleActions.getShowPageDataFailed())
//   }

//   const { response: tabResponse } = yield call(getResearchTabs, type)
//   if (tabResponse) {
//     tabResponse.tabs = tabResponse.tabs.filter(item => item.label !== 'Lexeme')
//     // get tabs content
//     const tabs = yield all(
//       tabResponse.tabs.map(item => call(getResearchLabelList, type, item.label))
//     )
//     // get spatials for tabs
//     yield all(
//       tabResponse.tabs.map(item =>
//         item.label !== 'Site'
//           ? call(fetchResearchSpatials, item.label, type)
//           : null
//       )
//     )
//     yield call(fetchResearchSpatials, 'Layer', type)

//     // get site's layers
//     const layers = yield getSiteTabLayers(tabs, tabResponse)
//     // console.info('layers', layers);

//     yield getResearchSubject(type, 'Assemblage')
//     yield getResearchSubject(type, 'Artifact')
//     yield getResearchSubject(type, 'Culture')
//     yield getResearchSubject(type, 'Radiocarbon')
//     // yield getResearchSubject(type, 'Site');
//     yield getResearchExc(type)

//     yield setTabsContent(tabs, category, layers, tabResponse)
//     yield graphqlQuery('Research', 'Lexeme', { uid: type }, false)
//   } else {
//     yield put(exampleActions.getShowPageDataFailed())
//   }
// }

// export function* getLayers(id, subject = 'Site') {
//   const siteLayers = yield call(getSubjectByLabelBody, 'Layer', {
//     [subject]: { uid: id },
//   })
//   if (siteLayers.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         ...siteLayers,
//         mainTabType: subject,
//         label: 'Layer',
//       })
//     )
//   }
//   yield call(fetchSpatials, 'Layer', siteLayers.response)
// }

// export function* getBuildings(id, subject = 'Site') {
//   const items = yield call(getSubjectByLabelBody, 'Monument', {
//     [subject]: { uid: id },
//   })
//   if (items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         ...items.response,
//         mainTabType: subject,
//         label: 'Monument',
//       })
//     )
//   }
//   yield call(fetchSpatials, 'Monument', items.response)
// }

// export function* getSites(id, subject = 'Excavation') {
//   const items = yield call(getSubjectByLabelBody, 'Site', {
//     [subject]: { uid: id },
//   })
//   console.info('sites', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: subject,
//         label: 'Site',
//       })
//     )
//   }
// }

// export function* getAssemblages(id, subject = 'Excavation') {
//   const items = yield call(getSubjectByLabelBody, 'Assemblage', {
//     [subject]: { uid: id },
//   })
//   console.info('assemblages', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: subject,
//         label: 'Assemblage',
//       })
//     )
//   }
// }

// export function* getRadiocarbons(id, subject = 'Layer') {
//   const items = yield call(getSubjectByLabelBody, 'Radiocarbon', {
//     [subject]: { uid: id },
//   })
//   console.info('Radiocarbons', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: subject,
//         label: 'Radiocarbon',
//       })
//     )
//   }
// }

// export function* getExcavationsWithLayers(id, subject = 'Site') {
//   const excavations = yield call(getExcavationLayers, id)
//   console.info('excavations', excavations)
//   if (excavations.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: excavations.response.result,
//         mainTabType: subject,
//         label: 'Excavation',
//       })
//     )
//   }
//   yield call(fetchSpatials, 'Excavation', excavations.response)
// }

// export function* getPublications(id, subject = 'Site') {
//   const items = yield call(getSubjectPulbications, id)
//   console.info('publication', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.result,
//         mainTabType: subject,
//         label: 'Publication',
//       })
//     )
//   }
// }

// export function* getReports(id, subject = 'Site') {
//   const items = yield call(getSubjectArchive, id)
//   console.info('reports', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.result,
//         mainTabType: subject,
//         label: 'Report',
//       })
//     )
//   }
// }

// export function* getTopo(id, subject = 'Site') {
//   const items = yield call(getSubjectTopo, id)
//   console.info('topo', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.results,
//         mainTabType: subject,
//         label: 'Topo',
//         tabType: 'gallery',
//       })
//     )
//   }
// }

// export function* getHeritages(id, subject = 'Site', response) {
//   console.info('getHeritages', response)
//   const heritages = yield call(getHeritage, id)
//   console.info('Heritages', heritages)
//   if (heritages.response.results.length) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: heritages.response.results,
//         mainTabType: subject,
//         label: 'Heritage',
//       })
//     )
//   }
// }

export function* getOldResearches(interpretations, mainType, response) {
  const researches = yield all(
    interpretations.map(interpretation =>
      call(getResearch, interpretation.research_id),
    ),
  )
  console.info('researches', researches)
  const researchMap = new Map()
  researches.forEach((research, id) => {
    const type = research.response.type.ru_name
    const etim_desc = interpretations[id].etim_description
    if (!researchMap.has(type)) {
      researchMap.set(type, [])
    }
    researchMap.get(type).push({ ...research.response, etim_desc })
  })
  if (researchMap.size > 0) {
    const researchObj = {
      data: researchMap,
      mainTabType: mainType.toLowerCase(),
      label: 'lingresearches',
      count: response.interpretations.length,
    }
    yield put(exampleActions.getShowPageDataSuccess(researchObj))
  }
}

export function* getPopulations(pops, mainType, response) {
  const popsData = []
  pops.forEach((pop, id) => {
    popsData.push({
      date: pop.date,
      population: pop.population,
      description: pop.description,
    })
  })
  if (popsData.length > 0) {
    const popsObj = {
      data: popsData,
      mainTabType: mainType.toLowerCase(),
      label: 'population',
      count: pops.length,
    }
    yield put(exampleActions.getShowPageDataSuccess(popsObj))
  }
}

export function* getResearches(interpretations, mainType, response) {
  const researches = interpretations.map(inter => inter.research)
  const descriptions = interpretations.map(inter => inter.ru_desc)
  const cultures = yield all(
    researches.map(res => getResearchPreviewCultures(res.uid)),
  )
  const researchMap = new Map()
  researches.forEach((research, i) => {
    const type = research.type.ru_name
    if (!researchMap.has(type)) {
      researchMap.set(type, [])
    }
    researchMap.get(type).push({
      ...research,
      ru_desc: descriptions[i],
      culture: cultures[i],
    })
  })
  if (researchMap.size > 0) {
    const researchObj = {
      data: researchMap,
      mainTabType: mainType.toLowerCase(),
      label: 'researchtype',
      count: response.interpretations.length,
    }
    yield put(exampleActions.getShowPageDataSuccess(researchObj))
  }
}

// export function* fetchDistrict(id, subject = 'Site') {
//   const siteDistrict = yield call(getSubjectByLabelBody, 'District', {
//     [subject]: { uid: id },
//   })
//   console.info('siteDistrict', siteDistrict)
//   return siteDistrict.response.results ? siteDistrict.response.results[0] : null
// }

// export function* getCulture(id, subject = 'Layer') {
//   const culture = yield call(getSubjectByLabelBody, 'Culture', {
//     [subject]: { uid: id },
//   })
//   console.info('Culture', culture)
//   return culture.response.results
// }

export function* getLocalities(id, subject = 'Site') {
  const items = yield call(getSubjectByLabelBody, 'Locality', {
    [subject]: { uid: id },
    mainSubjectLabel: subject,
    uid: id,
  })
  console.info('fetchLocalities', items)
  if (items.response.length > 0) {
    yield put(
      exampleActions.getShowPageDataSuccess({
        results: items.response[0].localities,
        mainTabType: subject,
        label: 'Locality',
      }),
    )
  }
}

// export function* getLexemes(id, subject = 'Site') {
//   const items = yield call(getSubjectByLabelBody, 'Lexeme', {
//     [subject]: { uid: id },
//     mainSubjectLabel: subject,
//     uid: id,
//   })
//   console.info('fetchLexemes', items)
//   if (items.response.length > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response,
//         mainTabType: subject,
//         label: 'Lexeme',
//       })
//     )
//   }
// }

// export function* getArtifact(id, subject = 'Layer') {
//   const items = yield call(getSubjectByLabelBody, 'Artifact', {
//     [subject]: { uid: id },
//   })
//   console.info('Artifact', items)
//   if (items.response && items.response.count > 0) {
//     yield put(
//       exampleActions.getShowPageDataSuccess({
//         results: items.response.result,
//         mainTabType: subject,
//         label: 'Artifact',
//       })
//     )
//   }
// }

// export function* fetchRadiocarbonSummary(id, subject = 'Layer') {
//   const { response, error } = yield call(getRadiocarbonSummary, id)
//   //  tabType
//   yield put(
//     exampleActions.getShowPageDataSuccess({
//       result: error ? [] : response,
//       mainTabType: subject,
//       label: 'RadiocarbonSummary',
//       tabType: 'radio',
//       mainTabUid: id,
//     })
//   )
// }

export function* fetchGraphSubject(subject) {
  const mainContent = yield call([subject, 'init'])
  yield put(exampleActions.getShowPageDataGraphqlSuccess(mainContent))

  const tabs = yield call([subject, 'getTabs'])
  yield all(
    tabs.map(item => {
      if (item) {
        return put(exampleActions.getShowPageDataGraphqlSuccess(item))
      }
      return null
    }),
  )

  const mainPoints = yield call(subject.getSpatials)
  if (mainPoints && mainPoints.points && mainPoints.points.length > 0) {
    yield put(exampleActions.getShowPagePointsSuccess(mainPoints))
  }

  const tabsPoints = yield call(subject.getTabsSpatials)
  yield all(
    tabsPoints.map(item => {
      if (item) {
        return put(exampleActions.getShowPagePointsSuccess(item))
      }
      return null
    }),
  )
}

function* getTabsInfo(tab, id) {
  const content = yield call(tab.init)
  if (content) {
    content.mainTabUid = id
    yield put(exampleActions.getShowPageDataGraphqlSuccess(content))
  }

  const spatials = yield call(tab.getSpatials)
  const isArray = Array.isArray(spatials)
  if (isArray) {
    yield all(
      spatials.map(spatial => {
        if (spatial && spatial.points && spatial.points.length > 0) {
          return put(exampleActions.getShowPagePointsSuccess(spatial))
        }
        return null
      }),
    )
  } else if (spatials && spatials.points && spatials.points.length > 0) {
    yield put(exampleActions.getShowPagePointsSuccess(spatials))
  }
}

export function* fetchGraphSubjectByTab(subject, id) {
  try {
    const mainContent = yield call([subject, 'init'])
    const leftPanelButtons = yield call([subject, 'getLeftPanelButtons'])
    mainContent.leftPanelButtons = leftPanelButtons
    // check for new class method in transformData
    if (subject.getPointHistory) {
      const spatialHistory = yield call([subject, 'getPointHistory'])
      if (mainContent) {
        mainContent.settings = {
          points: spatialHistory,
        }
        yield put(exampleActions.getShowPageDataGraphqlSuccess(mainContent))
      }
    } else if (mainContent) {
      yield put(exampleActions.getShowPageDataGraphqlSuccess(mainContent))
    }

    const mainPoints = yield call(subject.getSpatials)
    if (mainPoints && mainPoints.points && mainPoints.points.length > 0) {
      yield put(exampleActions.getShowPagePointsSuccess(mainPoints))
    }

    const tabs = yield call([subject, 'getTabs'])
    yield all(
      tabs.map(tab => {
        return call(getTabsInfo, tab, id)
      }),
    )
  } catch (e) {
    yield put(exampleActions.getShowPageDataFailed())
  }
}

export function* fetchOtherSubject(action) {
  const { type, id } = action.payload
  const { response } = yield call(getSubjectById, id)
  if (response) {
    response.mainTabType = type.toLowerCase()
    response.label = type.toLowerCase()
    // get main spatials
    if (type !== 'Variant') {
      const mainSpatials = yield call(fetchSpatials, type, {
        results: [response],
      })
      if (
        mainSpatials &&
        mainSpatials.points &&
        mainSpatials.points.length > 0
      ) {
        response.gradation_type = mainSpatials.points[0].gradation_type
      }
    }

    // if (type === 'Site') {
    //   response.district = yield fetchDistrict(id)
    // }
    // if (type === 'Layer') {
    //   response.culture = yield getCulture(id)
    // }
    // if (type === 'Excavation') {
    //   // const res = yield call(getResearch, response.interpretations[0].research_id)
    //   response.research = response.interpretations[0].research
    // }
    console.info('response', response)
    yield put(exampleActions.getShowPageDataSuccess(response))
    const { response: tabResponse } = yield call(getSubjectTabs, id)
    if (tabResponse) {
      console.info('tabResponse', tabResponse)
      // get tabs content
      const tabs = yield all(
        tabResponse.tabs.map(item => {
          if (needTab(type, item.label)) {
            return call(getSubjectByLabelBody, item.label, {
              [type]: { uid: id },
            })
          }
          return []
        }),
      )

      // get site's layers
      const layers = yield getSiteTabLayers(tabs, tabResponse)

      // load separate tab for layers if site
      // if (type === 'Monument') {
      //   yield getResearches(response.interpretations, type, response)
      //   yield getHeritages(id, type, response)
      // }
      // if (type === 'Site') {
      //   yield getLayers(id)
      //   yield getBuildings(id)
      //   yield getResearches(response.interpretations, type, response)
      //   yield getExcavationsWithLayers(id)
      //   yield getReports(id)
      //   yield getPublications(id)
      //   yield getTopo(id)
      //   yield getRadiocarbons(id, 'Site')
      // }
      // if (type === 'Layer') {
      //   yield getResearches(response.interpretations, type, response)
      //   yield getExcavationsWithLayers(id, 'Layer')
      //   yield getPublications(id, 'Layer')
      //   yield getReports(id, 'Layer')
      //   yield getAssemblages(id, 'Layer')
      //   yield getArtifact(id, 'Layer')
      //   yield getRadiocarbons(id, 'Layer')
      //   yield fetchRadiocarbonSummary(id)
      // }
      if (type === 'Term') {
        yield getOldResearches(response.interpretations, type, response)
        yield graphqlQuery('Term', 'Lexeme', { uid: id })
      }
      if (type === 'Lexeme') {
        yield getOldResearches(response.interpretations, type, response)
        yield getLocalities(id, 'Lexeme')
        yield graphqlQuery('Lexeme', 'Variant', { uid: id })
      }
      if (type === 'Locality') {
        yield getResearches(response.interpretations, type, response)
        if (response.attributes.Population) {
          yield getPopulations(response.attributes.Population, type, response)
        }
        // yield getLexemes(id, 'Locality')
        yield graphqlQuery(
          'Locality',
          'VariantWithoutGroup',
          { uid: id },
          false,
        )
      }
      // if (type === 'District') {
      //   yield getResearches(response.interpretations, type, response)
      // }
      if (type === 'Variant') {
        const interpretations = [
          { research_id: response.lexeme.edge.research_id },
        ]
        yield getOldResearches(interpretations, type, response)
      }
      // if (type === 'Excavation') {
      //   yield getLayers(id, 'Excavation')
      //   // yield getResearches(response.results[0].interpretations, type, response)
      //   yield getPublications(id, 'Excavation')
      //   yield getAssemblages(id, 'Excavation')
      //   yield getArtifact(id, 'Excavation')
      //   yield getRadiocarbons(id, 'Excavation')
      //   yield getTopo(id, 'Excavation')
      // }
      // if (type === 'Culture') {
      //   yield getResearches(response.interpretations, type, response)
      //   yield getSites(id, 'Culture')
      //   yield getArtifact(id, 'Culture')
      //   yield getPublications(id, 'Culture')
      //   yield getRadiocarbons(id, 'Culture')
      // }
      // if (type === 'Assemblage') {
      //   yield getResearches(response.interpretations, type, response)
      //   yield getArtifact(id, 'Assemblage')
      //   yield getReports(id, 'Assemblage')
      //   yield getPublications(id, 'Assemblage')
      //   yield getRadiocarbons(id, 'Assemblage')
      //   yield getTopo(id, 'Assemblage')
      // }
      console.info('layers', layers)
      yield setTabsContent(tabs, type, layers, tabResponse)
      // get spatial reference for tabs
      yield all(
        tabs.map((item, index) => {
          if (
            type === 'Variant' &&
            tabResponse.tabs[index].label === 'Lexeme'
          ) {
            return []
          }
          if (type === 'Term' && tabResponse.tabs[index].label === 'Lexeme') {
            return []
          }
          if (
            type === 'Lexeme' &&
            tabResponse.tabs[index].label === 'Variant'
          ) {
            return []
          }
          // if (
          //   type === 'Locality' &&
          //   tabResponse.tabs[index].label === 'Variant'
          // ) {
          //   return []
          // }
          // if (type === 'Site' && tabResponse.tabs[index].label === 'Monument') {
          //   return []
          // }
          // if (
          //   type === 'Layer' &&
          //   tabResponse.tabs[index].label === 'Monument'
          // ) {
          //   return []
          // }
          return call(
            fetchSpatials,
            tabResponse.tabs[index].label,
            item.response,
          )
        }),
      )
    } else {
      yield put(exampleActions.getShowPageDataFailed())
    }
  } else {
    yield put(exampleActions.getShowPageDataFailed())
  }
}

export function* fetchSubject(action) {
  const { type, id } = action.payload
  if (!DONT_SHOW.includes(id)) {
    const subjectDataType = yield call(subjectData[type])
    if (subjectDataType) {
      const subject = new subjectDataType.default(id)
      yield call(fetchGraphSubjectByTab, subject, id)
    } else {
      yield call(fetchOtherSubject, action)
    }
  } else {
    hashHistory.push('/')
  }
}

export function* fetchSource(action) {
  const { type, id } = action.payload
  const sourceDataType = yield call(sourceData[type])
  const subject = new sourceDataType.default(id)

  yield call(fetchGraphSubjectByTab, subject, id)
}

export function* fetchAuthor(action) {
  const { type, id } = action.payload
  const authorData = yield call(AuthorData)
  const subject = new authorData.default(id)

  yield call(fetchGraphSubjectByTab, subject, id)
}
// export function* fetchBookSeries(action) {
//   const { type, id } = action.payload
//   const bookSeriesData = yield call(BookSeriesData)
//   const subject = new bookSeriesData.default(id)
//
//   yield call(fetchGraphSubjectByTab, subject, id)
// }

export function* fetchResearchGraph(action) {
  const { type, category } = action.payload
  let model
  switch (category) {
    case 'research_arch':
      model = ResearchArchData
      break
    case 'folk_research':
      model = FolkResearchData
      break
    case 'hist_research':
      model = HistResearchData
      break
    case 'research_enc':
      model = ResearchEncData
      break
    case 'book_series':
      model = BookSeriesData
      break
    default:
      model = ResearchData
  }
  const researchData = yield call(model)
  const subject = new researchData.default(type)

  yield call(fetchGraphSubjectByTab, subject, type)
  yield graphqlQuery('Research', 'Lexeme', { uid: type }, false)
}

export function* fetchShowData(action) {
  const { category } = action.payload
  switch (category) {
    case 'research':
    case 'research_arch':
    case 'research_enc':
    case 'hist_research':
    case 'folk_research':
    case 'book_series':
      yield call(fetchResearchGraph, action)
      break
    case 'subjects':
      yield call(fetchSubject, action)
      break
    case 'source':
      yield call(fetchSource, action)
      break
    case 'author':
      yield call(fetchAuthor, action)
      break
    // case 'BookSeries':
    //   yield call(fetchBookSeries, action)
    //   break
    default:
      break
  }
}

function* watchUserAccountActions() {
  yield takeLatest(exampleConstants.GET_SHOW_PAGE_DATA, fetchShowData)
}

export const showSaga = [fork(watchUserAccountActions)]
