import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import fetch from 'isomorphic-fetch'

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        ref.current.contains(event.target.firstChild.children[1])
      ) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

const Statistics = () => {
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [models, setModels] = useState([
    { name: 'Artifact', label: 'Артефакт' },
    { name: 'Assemblage', label: 'Археологический комплекс' },
    { name: 'Culture', label: 'Археологическая культура' },
    { name: 'Excavation', label: 'Раскопки' },
    { name: 'Layer', label: 'Археологический слой' },
    { name: 'Radiocarbon', label: 'Радиоуглеродная датировка' },
    { name: 'Site', label: 'Археологический памятник' },
    { name: 'ArticlesDigest', label: 'Сборник статей' },
    { name: 'Сonference', label: 'Конференция' },
    { name: 'FolkVariant', label: 'Фольклорный вариант' },
    { name: 'FolkTale', label: 'Фольклорное произведение' },
    { name: 'FolkCharacter', label: 'Фольклорный образ' },
    { name: 'FolkPerson', label: 'Фольклорный персонаж' },
    { name: 'FolkGenreGroup', label: 'Группа жанров' },
    { name: 'FolkGenre', label: 'Жанр' },
    { name: 'Term', label: 'Термины' },
    { name: 'Variant', label: 'Варианты' },
    { name: 'Dialect', label: 'Диалект' },
    { name: 'Lexeme', label: 'Лексемы' },
    { name: 'Vernacular', label: 'Говоры' },
    { name: 'Monument', label: 'Архитектурные памятники' },
    { name: 'Person', label: 'Персоналии' },
    { name: 'Country', label: 'Страны' },
    { name: 'District', label: 'Районы' },
    { name: 'Locality', label: 'Населенные пункты' },
    { name: 'Organization', label: 'Организация (историческая)' },
    { name: 'EncOrganization', label: 'Организация' },
    { name: 'Files', label: 'Файлы' },
    { name: 'Photo', label: 'фото' },
    { name: 'SourceFiles', label: 'Файлы источников' },
    { name: 'TopographicPlan', label: 'Топоплан' },
    { name: 'Report', label: 'Отчет' },
    { name: 'Book', label: 'Книга' },
    { name: 'ArticlePeriodical', label: 'Статья' },
  ])
  const [model, setModel] = useState(models[0])
  const ref = useRef()
  const [count, setCount] = useState(null)
  const [isHideModels, setIsHideModels] = useState(true)
  const [isHideResult, setIsHideResult] = useState(true)

  useOnClickOutside(ref, () => {
    setIsHideModels(true)
    setIsHideResult(true)
  })

  const fetchStatistic = () => {
    fetch(
      `https://www.culturetat.info/api/get_stats_by_model/${model.name}/?start=${dateStart}&end=${dateEnd}`,
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        setCount(data.result)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    fetchStatistic()
  }, [dateStart, dateEnd, model])
  console.log('count', count)
  return (
    <div className="stat_container">
      <div className="title_stat">Cтатистика</div>
      <div className="input_block">
        <div className="input">
          <div className="input">
            <div className="input_title">Выберите начало периода</div>
            <input
              className="date_input"
              type="date"
              value={dateStart}
              onChange={event => setDateStart(event.target.value)}
              onClick={() => {
                setIsHideResult(true)
                setIsHideModels(true)
              }}
            />
          </div>
        </div>
        <div className="input">
          <div className="input">
            <div className="input_title">Выберите конец периода</div>
            <input
              className="date_input"
              type="date"
              value={dateEnd}
              onChange={event => setDateEnd(event.target.value)}
              onClick={() => {
                setIsHideResult(true)
                setIsHideModels(true)
              }}
            />
          </div>
        </div>
        <div className="input model">
          <div className="input">
            <div className="input_title">Выберите модель</div>
            <div
              ref={ref}
              onClick={() => {
                setIsHideResult(true)
                isHideModels && setIsHideModels(false)
                !isHideModels && setIsHideModels(true)
              }}
              className="model_input"
            >
              {model.label}
              <div
                className={`models_list ${isHideModels ? 'display_none' : ''}`}
              >
                {models.map(model => {
                  return (
                    <div
                      onClick={() => {
                        setModel(model)
                        setIsHideModels(true)
                      }}
                      className="models_list_item"
                    >
                      {model.label}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="button_container">
        <button
          disabled={!model || !dateStart || !dateEnd}
          className="button_stat"
          onClick={() => {
            setIsHideResult(false)
          }}
        >
          Показать статистику
        </button>
      </div>
      <div ref={ref} className={`result ${isHideResult ? 'display_none' : ''}`}>
        {`За период ${dateStart} - ${dateEnd} было внесено ${count} объект(ов) "${model.label}"`}
      </div>
    </div>
  )
}

export default Statistics
