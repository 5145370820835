import React, { Component } from 'react'
import { Tabs, Alert, Spin } from 'antd'
import { connect } from 'react-redux'
import { CRS } from 'leaflet'
import LeafletMap from 'components/Map'
import MapLegend from 'components/MapLegend'
import { actions } from 'redux/modules/map'
import { url } from 'utility/constants'
import {
  createGalleryField
} from 'utility/transformData/constants'
import ListContent from '../TabContent/ListContent/index'
import '../TabContent/GalleryField/index.css'
import './index.scss'
import { fetchGrapqlRequest } from 'utility/request'
// eslint-disable-next-line import/no-cycle
import { TileLayerList, OverlayTileLayerList } from '../Map/tileLayerList'


const mapStateToProps = state => ({
  user: state.user,
  map: state.map,
})

const mapDispatchToProps = {
  handleMapsFetch: actions.fetchMaps,
}

@connect(mapStateToProps, mapDispatchToProps)
class MapPanel extends Component {
  state = {
    isMap: true,
    isSnapshot: false,
    is3D: false,
    isPhoto: false,
    photos: [],
    uid: '',
    i: 0
  }

  getUid = () => {
    const currentUrl = window.location.href.split('/')
    const uid = currentUrl[6] || currentUrl[5]
    this.setState({
      uid: uid,
    })
  }

  getPhoto = async (id) => {
    const res = fetch(`https://www.culturetat.ru/api/get_photo/${id}/`)
    return (await res).json()
  }

  componentDidMount() {
    this.props.handleMapsFetch()
    this.getPhoto(this.state.uid).then(data => {
      const arr = []
      data.result.forEach(item => {
        const split = item.link.split('/')
        const index = split[split.length - 1]
        const src = item.link.length - index.length
        const link = `${item.link.substring(0, src)}preview-${index}`
        arr.push({ link: item.link, name: item.ru_name, preview: link })
      })
      this.setState(oldState => ({
        photos: arr,
      }))
    })
  }

  componentWillMount() {
    this.getUid()
  }

  toggleSnapshot = () => {
    this.setState(oldState => ({
      isSnapshot: !oldState.isSnapshot,
    }))
  }

  setNext = () => {
    this.setState(oldState => ({
      i: (oldState.i += 1),
    }))
  }
  setPrev = () => {
    this.setState(oldState => ({
      i: (oldState.i -= 1),
}))
}

  setIsMap = () => {
    this.setState(oldState => ({
      isMap: true,
      is3d: false,
      isPhoto: false
    }))
  }
  setIs3d = () => {
    this.setState(oldState => ({
      isMap: false,
      is3d: true,
      isPhoto: false
    }))
  }
  setIsPhoto = () => {
    this.setState(oldState => ({
      isPhoto: true,
      isMap: false,
      is3d: false
    }))
  }

  render() {
    const {
      t,
      content,
      handleChangeInteractiveMarker,
      model3d,
      user: { isAuthenticated },
      isPointsLoading,
      initialPolygon,
      className,
      settings,
      map: { mapTabs, overlayMapTabs, isOk: mapsIsOk },
    } = this.props
    const { TabPane } = Tabs
    const interactiveMarkers = this.props.interactiveMarkers || []
    const model3dTab = t('map_panel.tabs.3d')
    if (document.location.pathname ===
      '/show/research_arch/7d74f00c-1a0e-437d-9375-d3068ee2fdb9/'
    ) {
      return (
        <>
          <div className="rel">
            <div className="btnBlock">
              <div onClick={() => {
                this.setIsMap()
              }} className="mapToggleButton map"><img src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/25/000000/external-map-hotel-services-and-city-elements-flatart-icons-solid-flatarticons.png"/></div>
              {model3d && <div onClick={() => {
                this.setIs3d()
              }} className="mapToggleButton threeD"><img src="https://img.icons8.com/ios-filled/25/000000/3d.png"/></div>}
              {this.state.photos.length > 0 && <div onClick={() => {
                this.setIsPhoto()
              }} className="mapToggleButton photo"><img src="https://img.icons8.com/ios-filled/25/000000/pictures-folder.png"/></div>}
              {this.state.isPhoto && <div className="mapToggleButton photo">
                <a
                  href={`${url}/${this.state.photos[this.state.i].link}`}
                  target="_blank"
                  download={this.state.photos[this.state.i].name}
                >
                  <img src="https://img.icons8.com/ios-glyphs/30/000000/download--v1.png"/>
                </a>
              </div>}
              {this.state.isPhoto && <div className="mapToggleButton photo">
                <a
                  target="_blank"
                  href={`${url}/${this.state.photos[this.state.i].link}`}
                >
                  <img src="https://img.icons8.com/ios-filled/30/000000/external-link.png" />
                </a>
              </div>}
            </div>
            {this.state.isMap && <div className={className || 'map-panel'}>
              {isPointsLoading && <Spin size="large"/>}
              <Tabs type="card" className="map-tab-pane">
                {Object.keys(mapTabs).map((tab, id) => (
                  <TabPane
                    tab={t(`map_panel.tabs.${tab}`)}
                    key={tab}
                    className="map-tab-pane"
                  >
                    <div className="map-tab">
                      {this.state.isSnapshot && (
                        <div className="map-is-snapshot">
                          <Spin size="large"/>
                        </div>
                      )}
                      {!isAuthenticated && (
                        <div className="map-top-alert">
                          <Alert
                            message={t('common.mapAlert')}
                            type="warning"
                            closable
                            showIcon
                          />
                        </div>
                      )}
                      <LeafletMap
                        content={content}
                        t={t}
                        maps={mapTabs[tab]}
                        overlayMaps={overlayMapTabs[tab]}
                        interactiveMarkers={interactiveMarkers}
                        initialPolygon={initialPolygon}
                        toggleSnapshot={this.toggleSnapshot}
                        handleChangeInteractiveMarker={handleChangeInteractiveMarker}
                      />
                    </div>
                  </TabPane>
                ))}
                {model3d && this.state.is3D && (
                  <div className="rel">
                    <TabPane tab={model3dTab} key={model3dTab} className="map-tab-pane">
                      <div className="map-tab">
                        <div className="sketchfab-embed-wrapper">
                          <iframe
                            title={model3dTab}
                            src={`${model3d}?autospin=0.2&amp;preload=1`}
                            frameBorder="0"
                            allow="autoplay; fullscreen; vr"
                            mozallowfullscreen="true"
                            webkitallowfullscreen="true"
                          />
                        </div>
                      </div>
                    </TabPane>
                  </div>
                )}
              </Tabs>

              <MapLegend settings={settings}/>
              <div className="icon-container">
                <img className="icon icon-b" src="https://www.culturetat.info/swmedias/banners/img1.png"/>
                <img className="icon" src="https://www.culturetat.info/swmedias/banners/img2.jpeg"/>
              </div>
            </div>}
          </div>
          <div>

          </div>
          {this.state.photos.length > 0 && this.state.isPhoto && <>
            <div className='gallery'>
              {this.state.photos[this.state.i].name && <span>{this.state.photos[this.state.i].name}</span>}
            </div>
            <div className="gallery-cont">
              <div onClick={() => {
                if (this.state.i > 0) {
                  this.setPrev()
                }
              }} className="gallery-btn btnLeft">{'<'}</div>
              <div className="gallery-img-cont">
                <img className="gallery-img" src={`${url}/${this.state.photos[this.state.i].preview}`} alt="img" />
              </div>
              <div onClick={() => {
                if (this.state.i < this.state.photos.length - 1) {
                  this.setNext()
                }
              }} className="gallery-btn btnRight">{'>'}</div>
            </div>
          </>
          }
        </>
      )
    }
      return (
        <>
        <div className="rel">
          <div className="btnBlock">
            <div onClick={() => {
              this.setIsMap()
            }} className="mapToggleButton map"><img src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/25/000000/external-map-hotel-services-and-city-elements-flatart-icons-solid-flatarticons.png"/></div>
            {model3d && <div onClick={() => {
              this.setIs3d()
            }} className="mapToggleButton threeD"><img src="https://img.icons8.com/ios-filled/25/000000/3d.png"/></div>}
            {this.state.photos.length > 0 && <div onClick={() => {
              this.setIsPhoto()
            }} className="mapToggleButton photo"><img src="https://img.icons8.com/ios-filled/25/000000/pictures-folder.png"/></div>}
            {this.state.isPhoto && <div className="mapToggleButton photo">
              <a
                href={`${url}/${this.state.photos[this.state.i].link}`}
                target="_blank"
                download={this.state.photos[this.state.i].name}
              >
                <img src="https://img.icons8.com/ios-glyphs/30/000000/download--v1.png"/>
              </a>
            </div>}
            {this.state.isPhoto && <div className="mapToggleButton photo">
              <a
                  target="_blank"
                  href={`${url}/${this.state.photos[this.state.i].link}`}
              >
                <img src="https://img.icons8.com/ios-filled/30/000000/external-link.png" />
              </a>
            </div>}
          </div>
          {this.state.isMap && <div className={className || 'map-panel'}>
            {isPointsLoading && <Spin size="large"/>}
            <Tabs type="card" className="map-tab-pane">
              {Object.keys(mapTabs).map((tab, id) => (
                <TabPane
                  tab={t(`map_panel.tabs.${tab}`)}
                  key={tab}
                  className="map-tab-pane"
                >
                  <div className="map-tab">
                    {this.state.isSnapshot && (
                      <div className="map-is-snapshot">
                        <Spin size="large"/>
                      </div>
                    )}
                    {!isAuthenticated && (
                      <div className="map-top-alert">
                        <Alert
                          message={t('common.mapAlert')}
                          type="warning"
                          closable
                          showIcon
                        />
                      </div>
                    )}
                    <LeafletMap
                      content={content}
                      t={t}
                      maps={mapTabs[tab]}
                      overlayMaps={overlayMapTabs[tab]}
                      interactiveMarkers={interactiveMarkers}
                      initialPolygon={initialPolygon}
                      toggleSnapshot={this.toggleSnapshot}
                      handleChangeInteractiveMarker={handleChangeInteractiveMarker}
                    />
                  </div>
                </TabPane>
              ))}
              {model3d && this.state.is3D && (
                <div className="rel">
                  <TabPane tab={model3dTab} key={model3dTab} className="map-tab-pane">
                    <div className="map-tab">
                      <div className="sketchfab-embed-wrapper">
                        <iframe
                          title={model3dTab}
                          src={`${model3d}?autospin=0.2&amp;preload=1`}
                          frameBorder="0"
                          allow="autoplay; fullscreen; vr"
                          mozallowfullscreen="true"
                          webkitallowfullscreen="true"
                        />
                      </div>
                    </div>
                  </TabPane>
                </div>
              )}
            </Tabs>

            <MapLegend settings={settings}/>
          </div>}
        </div>
          <div>

          </div>
          {this.state.photos.length > 0 && this.state.isPhoto && <>
          <div className='gallery'>
            {this.state.photos[this.state.i].name && <span>{this.state.photos[this.state.i].name}</span>}
          </div>
            <div className="gallery-cont">
              <div onClick={() => {
                if (this.state.i > 0) {
                  this.setPrev()
                }
              }} className="gallery-btn btnLeft">{'<'}</div>
              <div className="gallery-img-cont">
                <img className="gallery-img" src={`${url}/${this.state.photos[this.state.i].preview}`} alt="img" />
              </div>
              <div onClick={() => {
                if (this.state.i < this.state.photos.length - 1) {
                  this.setNext()
                }
              }} className="gallery-btn btnRight">{'>'}</div>
            </div>
          </>
          }
    </>
      )
  }
}

export default MapPanel
