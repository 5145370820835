import React, { Component, Fragment } from 'react'
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd'
import encQueries from 'components/GSearchPanel/Queries/encQueries'
import { listQueries } from 'components/CreatePanel/queries/getList'
import { textFieldFormatters } from 'components/CreatePanel/functions/textFieldFormatters'
import folkloreQueries from '../../../GSearchPanel/Queries/folkloreQueries'
import archQueries from '../../../GSearchPanel/Queries/archQueries'
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';

import '../../index.scss'
import AutoInputGQL from '../fields/autoInputGQL'
import FileUpload from '../fields/fileUpload'
import CoordinatesInput from '../fields/coordinatesInput'
import RelationComponent from '../relations/relations'
import { commonFunctions } from '../../functions/commonFunctions'

const FormItem = Form.Item
const { Panel } = Collapse
const { Option } = Select
const { TextArea } = Input

class FolkTaleTab extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 1
    this.state = {
      newName: [],
    }
  }

  addTab = () => {
    const key = this.newTabIndex++
    this.props.handleTabAddSubj(this.props.title, key)
    this.forceUpdate()
  }

  handleSelect = (data, key) => {
    const { newName } = this.state
    newName[key] = data.typed
    this.setState({
      newName,
    })
    commonFunctions.handleAutoInputChange(data)
  }

  handleChangeName = input => {
    this.props.handleChangeStateTab({
      title: this.props.title,
      key: input.target.dataset.objkey,
      value: input.target.value,
    })
  }

  render() {
    const {
      handleAddExtraRelation,
      filelistChanged,
      getFieldDecorator,
      formInputLayout,
      stateTabs,
      subjects,
      createData,
      title,
      interactiveMarkers,
      handleAddMarker,
      form,
      t,
    } = this.props

    const config = {
      req: {
        rules: [
          {
            required: true,
            message: t('common.default_error'),
          },
        ],
      },
    }

    return (
      <div>
        <Collapse>
          {subjects.map((item, key) => (
            <Panel
              header={`${t('create_panel.tab.folktale.folktale')} #${1 + key}`}
              key={title + item.key}
            >
              <AutoInputGQL
                key={`folktale_select_${key}`}
                handleSelect={data => {
                  this.handleSelect(data, key)
                }}
                handleTabAddRelation={this.props.handleTabAddRelation}
                handleChangeStateTab={this.props.handleChangeStateTab}
                getFieldDecorator={getFieldDecorator}
                formLayout={formInputLayout}
                title={this.props.title}
                label={t('create_panel.tab.folktale.choose')}
                selectkey={`folktale_select_${key}`}
                objkey={key}
                addOptionText={t('create_panel.tab.folktale.add')}
                query={folkloreQueries.taleSearch()}
                params={[{ key: 'search', val: 'value' }]}
                researchConnectedSubjects={createData.researchConnectedSubjects}
                leftLabel="FolkTale"
                t={t}
              />  
              {(item.status === 'new' || item.status === 'selected') &&   (
                <div>                    
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folktale.runame')}
                  >
                    {getFieldDecorator(`folktale_ru_name_${key}`, {
                      ...config.req,
                      initialValue: this.state.newName[key],
                    })(
                      <Input
                        onChange={this.handleChangeName}
                        data-objkey={key}
                      />,
                    )}
                  </FormItem>                  
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folktale.tatname')}
                  >
                    {getFieldDecorator(`folktale_tat_name_${key}`, config.req)(
                      <Input />,
                    )}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('create_panel.tab.folktale.enname')}
                  >
                    {getFieldDecorator(
                      `folktale_en_name_${key}`,
                      {},
                    )(<Input />)}
                  </FormItem>
                  <FormItem
                    {...formInputLayout}
                    label={t('common.genrevariety')}
                  >
                    {getFieldDecorator(
                      `folktale_genrevariety_${key}`,
                      {},
                    )(<Input />)}
                  </FormItem>                  
                  <FormItem
                  {...formInputLayout}
                  label={t('common.genrevarietytype')}
                  >
                  {getFieldDecorator(`folktale_genrevarietytype_${key}`, {
                  })(<Input />)}
                  </FormItem>
                  <FormItem
                  {...formInputLayout}
                  label={t('common.motive')}
                  >
                  {getFieldDecorator(`folktale_motive_${key}`, {
                  })(<Input />)}
                  </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.thematicgroup')}
                >
                  {getFieldDecorator(`folktale_thematicgroup_${key}`, {
                  })(<Input />)}
                  </FormItem>
                  <FormItem
                  {...formInputLayout}
                  label={t('common.rudescription')}
                  >
                  {getFieldDecorator(`folktale_ru_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folktale_description_${key}`)} />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.endescription')}
                >
                  {getFieldDecorator(`folktale_en_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folktale_description_${key}`)} />)}
                </FormItem>
                <FormItem
                  {...formInputLayout}
                  label={t('common.tatdescription')}
                >
                  {getFieldDecorator(`folktale_tat_desc_${key}`, {
                  })(
                    <TextArea onPaste={(e) => textFieldFormatters.onTextAreaPaste(e, form, `folktale_description_${key}`)} />)}
                </FormItem>
                {item.status === 'new' &&
                  <Fragment>
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folktale_epoch_${key}`}
                      label={t('common.epoch')}
                      selectQuery={attributeQueries.defaultSearch('epoches')}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folktale_genre_${key}`}
                      label={t('common.folkgenre')}
                      selectQuery={folkloreQueries.genreSearch()}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folktale_genre_group_${key}`}
                      label={t('common.folkgenregroup')}
                      selectQuery={folkloreQueries.genreGroupSearch()}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folktale_class_${key}`}
                      label={t('common.folkclass')}
                      selectQuery={folkloreQueries.folkClassSearch()}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                    <CommonSelect
                      getFieldDecorator={getFieldDecorator}
                      formLayout={formInputLayout}
                      inputName={`folktale_type_${key}`}
                      label={t('common.folktype')}
                      selectQuery={folkloreQueries.folkTypeSearch()}
                      dontNeedEmptyOption="true"
                      t={t}
                    />
                  </Fragment>
                }
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="Culture"
                    dontShowOnMap
                    connectionField="culture"
                    label={t('common.culture')}
                    selectkey={`folktale_culture_${key}`}
                    selectType="cultures"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.culture.add')}
                    query={archQueries.cultureSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="FolkVariant"
                    dontShowOnMap
                    connectionField="folkvariant"
                    label={t('create_panel.tab.folkvariant')}
                    selectkey={`folktale_folkvariant_${key}`}
                    selectType="folkvariant"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.folkvariant.folkvariant')}
                    query={folkloreQueries.variantSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  {/* <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="FolkGenreGroup"
                    connectionField="folk_genre_group"
                    label={t('common.folkgenregroup')}
                    selectkey={`folktale_folkgenregroup_${key}`}
                    selectType="folkgenregroup"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.folkgenregroup.add')}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.genreGroupSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="FolkGenre"
                    connectionField="folk_genre"
                    label={t('common.folkgenre')}
                    selectkey={`folktale_folkgenre_${key}`}
                    selectType="folkgenre"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.folkgenre.add')}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.genreSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="FolkClass"
                    connectionField="folk_class"
                    label={t('common.folkclass')}
                    selectkey={`folktale_folkclass_${key}`}
                    selectType="folkclass"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.folkclass.add')}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.folkClassSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />
                  <AutoInputGQL
                    handleSelect={commonFunctions.handleAutoInputChange}
                    handleTabAddRelation={this.props.handleTabAddRelation}
                    handleChangeStateTab={this.props.handleChangeStateTab}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    title={this.props.title}
                    leftLabel="FolkTale"
                    rightLabel="FolkType"
                    connectionField="folk_type"
                    label={t('common.folktype')}
                    selectkey={`folktale_folktype_${key}`}
                    selectType="folktype"
                    stateTabs={stateTabs}
                    objkey={key}
                    addOptionText={t('create_panel.tab.folktype.add')}
                    newKeyForNew
                    saveTypedText
                    query={folkloreQueries.folkTypeSearch()}
                    params={[{ key: 'search', val: 'value' }]}
                    t={t}
                  />  */}
                  <FileUpload
                    getFieldDecorator={getFieldDecorator}
                    formInputLayout={formInputLayout}
                    filelistChanged={filelistChanged}
                    fileLists={createData.sourceFilelist}
                    label={`folktale_files_${key}`}
                    fileType="photo"
                    title={t('common.photo.title')}
                    t={t}
                  />
                  <RelationComponent
                    handleAddExtraRelation={handleAddExtraRelation}
                    getFieldDecorator={getFieldDecorator}
                    formLayout={formInputLayout}
                    objkey={key}
                    title="FolkTale"
                    tabName="folktales"
                    stateTabs={stateTabs}
                    form={form}
                    t={t}
                  />
                </div>
              )}
            </Panel>
          ))}
        </Collapse>

        <FormItem wrapperCol={{ span: 8, offset: 8 }}>
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.tab.folktale.add_button')}
          </Button>
        </FormItem>
      </div>
    )
  }
}

export default FolkTaleTab
