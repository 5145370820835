import React from 'react'
import PropTypes from 'prop-types'
import AdvSearchFieldInput from 'components/AdvSearch/Field/Input'
import AdvSearchFieldSelect from 'components/AdvSearch/Field/Select'
import AdvSearchSelect from 'components/AdvSearch/Select'
import { AdvSearchGroup } from 'components/AdvSearch'
import { Button, Checkbox, Popconfirm } from 'antd'
import './index.scss'

const FIELD_TYPES = {
  input: AdvSearchFieldInput,
  select: AdvSearchFieldSelect,
  group: AdvSearchGroup,
}

const FIELD_TYPE_ID = {
  input: '',
  select: '',
  group: 'selections',
}

function FieldComponent(props) {
  const Component = FIELD_TYPES[props.type]

  return <Component {...props} />
}

const AdvSearchField = props => {
  const {
    t,
    isRoot,
    data,
    schema,
    id,
    fields,
    isPreLoad: pIsPreLoad,
    preLoadData: pPreLoadData,
  } = props

  const setSelectedField = (fieldName, selectedElem) => {
    const { fields: curFields } = selectedElem
    if (isRoot) {
      const selectedFields = Object.keys(curFields).filter(
        key => curFields[key].type !== 'Relationship' && curFields[key].view,
      )
      const allSelectedFields = Object.keys(curFields).filter(
        key =>
          curFields[key].type !== 'Relationship' &&
          curFields[key].view &&
          curFields[key].column === 1,
      )
      if (selectedFields && selectedFields.length > 0) {
        props.selectFields({
          selected: allSelectedFields,
          selectedFields,
        })
      }
    }
    const item = {
      fieldName,
      type: undefined,
      model: undefined,
      operator: undefined,
      selections: undefined,
      selectValue: undefined,
    }

    const isGroup =
      selectedElem && selectedElem.model && selectedElem.type !== 'EnumProperty'
    const isSelect = selectedElem && selectedElem.type === 'EnumProperty'
    if (isGroup) {
      item.type = 'group'
      item.operator = 'all'
      item.selections = {}
      item.model = selectedElem.model
    } else if (isSelect) {
      item.type = 'select'
      item.model = selectedElem.model
    } else if (selectedElem.type === 'StringProperty') {
      item.type = 'input'
      item.selectValue = 'icontains'
    } else {
      item.type = 'input'
      item.selectValue = 'exact'
    }

    props.onFieldChange({
      id: props.id,
      item,
    })
  }

  const onFieldChange = (newState: any = {}) => {
    const curId = newState.id ? `${id}.${newState.id}` : id
    props.onFieldChange({
      ...newState,
      id: curId,
    })
  }

  const handleSelect = (fieldName: string) => {
    if (pIsPreLoad) {
      onFieldChange({
        item: {
          fieldName: 'uid',
          blockType: 'field',
          isNot: false,
          selectValue: 'exact',
          type: 'input',
          inputValue: fieldName,
          isPreLoad: true,
          model: undefined,
          operator: undefined,
          selections: undefined,
        },
      })
    } else {
      let subject
      const selectedElem = data.get(fieldName)
      const isModel = selectedElem.model
      if (isModel) {
        subject = schema.get(selectedElem.model.toLowerCase())
      }
      if (!isModel || subject) {
        setSelectedField(fieldName, selectedElem)
      } else {
        // TODO show notification that subject not exists in schema
      }
    }
  }

  const handleNot = e => {
    props.onFieldChange({
      id: props.id,
      item: {
        isNot: e.target.checked,
      },
    })
  }

  const handleOperatorChange = e => {
    props.onFieldChange({
      id: props.id,
      item: {
        operator: e.target.value,
      },
    })
  }

  const handleRemove = () => {
    props.handleRemove(id)
  }

  const confirmRemove = () => {
    props.onFieldChange({
      id,
      item: {
        type: null,
        isNot: false,
        fieldName: null,
      },
      isRemove: true,
    })
  }

  const currentField = fields
  const isNot = currentField ? currentField.isNot : undefined
  const type = currentField ? currentField.type : undefined
  let isPreLoad = false
  let preLoadData = []
  let fieldData = null
  let searchSelectData = data
  if (pIsPreLoad) {
    searchSelectData = pPreLoadData
  } else if (currentField.type === 'group' || currentField.type === 'select') {
    fieldData = new Map()
    const schemaItem = schema.get(currentField.model.toLowerCase())
    const modelFields = schemaItem.fields
    Object.entries(modelFields).forEach(([key, value]) => {
      fieldData.set(key.toLowerCase(), value)
    })
    if (schemaItem.pre_load) {
      isPreLoad = true
      preLoadData = schemaItem.pre_load_data
    }
  } else if (currentField.type === 'input') {
    fieldData = data.get(currentField.fieldName)
  }

  const renderRemoveBtn = () => {
    return isRoot ? (
      <Popconfirm
        title={t('advanced.button.remove')}
        onConfirm={confirmRemove}
        okText={t('advanced.button.yes')}
      >
        <Button
          type="primary"
          shape="circle"
          icon="close"
          className="field-remove-button"
        />
      </Popconfirm>
    ) : (
      <Button
        type="primary"
        shape="circle"
        icon="close"
        className="field-remove-button"
        onClick={handleRemove}
      />
    )
  }

  return (
    <div className={`adv-search-field ${type || 'not_choosen'}`}>
      {!isRoot && (
        <Checkbox onChange={handleNot} checked={isNot}>
          {t('advanced.not')}
        </Checkbox>
      )}
      <AdvSearchSelect
        t={t}
        data={searchSelectData}
        isPreLoad={pIsPreLoad}
        onSelect={handleSelect}
        value={pIsPreLoad ? currentField.inputValue : currentField.fieldName}
      />
      {type && !pIsPreLoad && (
        <FieldComponent
          t={t}
          id={FIELD_TYPE_ID[type]}
          data={fieldData}
          isPreLoad={isPreLoad}
          preLoadData={preLoadData}
          schema={schema}
          type={type}
          fields={currentField}
          parentModel={currentField.model && currentField.model.toLowerCase()}
          onFieldChange={onFieldChange}
          handleOperatorChange={handleOperatorChange}
        />
      )}
      {renderRemoveBtn()}
    </div>
  )
}

AdvSearchField.propTypes = {
  schema: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  isRoot: PropTypes.bool,
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onFieldChange: PropTypes.func.isRequired,
}

AdvSearchField.defaultProps = {
  isRoot: false,
}

export { AdvSearchField }
