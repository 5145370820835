import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import GalleryField from '../GalleryField'
import './index.css'

const settings = {
  dots: true,
  adaptiveHeight: true,
}

const GalleryTab = props => {
  const { content } = props
  return (
    <Slider {...settings}>
      {content.map(item => (
        <GalleryField {...item} />
      ))}
    </Slider>
  )
}

export default GalleryTab
