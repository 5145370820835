import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Slider, Button, Spin } from 'antd';
import { actions } from 'redux/modules/heatmap';
import { connect } from 'react-redux';

import './index.css';

const Line = React.lazy(() => import('distLibs/Chart'))

const mapStateToProps = state => ({
  mainChart: state.heatmap.mainChart,
});

const mapDispatchToProps = {
  setDate: actions.whenSubmit,
  getMainChart: actions.whenGetMainChart,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class WhenView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.data = (canvas) => {
      if (!this.props.mainChart.labels) {
        return {}
      }
      const ctx = canvas.getContext('2d')
      const { t } = props
      const gradient = ctx.createLinearGradient(500, 0, 500, 600);
      gradient.addColorStop(0, '#e0c592');
      gradient.addColorStop(1, '#ffffff');
      return {
        labels: this.props.mainChart.labels,
        datasets: [
          {
            label: t('when.objects'),
            backgroundColor: gradient,
            borderColor: '#e0c592',
            borderWidth: 4,
            data: this.props.mainChart.datasets,
          },
        ],
      }
    };
    this.options = () => {
      return {
      }
    }
  }

  state = {
    dateStart: 0,
    dateEnd: 1450,
  }

  componentDidMount = (e) => {
    this.props.getMainChart();
  }

  handleOnChange = (e) => {
    const chartData = this.props.mainChart;
    const maxRange = (chartData.labels) ? (chartData.labels.length - 1) * 10 : 100;

    const leftNum1 = chartData.labels[parseInt(e[0] / 10, 10)]
    const rightNum1 = (e[0] !== maxRange) ? chartData.labels[parseInt(e[0] / 10, 10) + 1] : 2018
    const dateStart = parseInt(leftNum1 + (((rightNum1 - leftNum1) / 10) * (e[0] % 10)), 10)

    const leftNum2 = chartData.labels[parseInt(e[1] / 10, 10)]
    const rightNum2 = (e[1] !== maxRange) ? chartData.labels[parseInt(e[1] / 10, 10) + 1] : 2018
    const dateEnd = parseInt(leftNum2 + (((rightNum2 - leftNum2) / 10) * (e[1] % 10)), 10)
    this.setState({
      dateStart,
      dateEnd,
    })
  }

  formatter = (e) => {
    const chartData = this.props.mainChart;
    const maxRange = (chartData.labels) ? (chartData.labels.length - 1) * 10 : 100;

    if (!chartData.labels) {
      return 0
    }
    const leftNum = chartData.labels[parseInt(e / 10, 10)]
    const rightNum = (e !== maxRange) ? chartData.labels[parseInt(e / 10, 10) + 1] : 2018
    const date = parseInt(leftNum + (((rightNum - leftNum) / 10) * (e % 10)), 10)
    return `${date}`
  }

  handleChoose = () => {
    this.props.setDate({
      date: {
        date_start: this.state.dateStart,
        date_end: this.state.dateEnd,
      },
      history: this.props.history,
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div className="when-container">
        <Suspense fallback={<Spin />}>
          <Line data={this.data} options={this.options()} />
          <Slider
            className="when-container-slider"
            defaultValue={[160, 200]}
            max={(this.props.mainChart.labels) ? (this.props.mainChart.labels.length - 1) * 10 : 300}
            min={0}
            range
            onChange={this.handleOnChange}
            tipFormatter={this.formatter}
          />
          <div className="when-button-container">
            <Button
              type="primary"
              icon="search"
              className="when-button"
              onClick={this.handleChoose}
            >
              {t('index.show_results')}
            </Button>
          </div>
        </Suspense>
      </div>
    )
  }
}

export default WhenView;
