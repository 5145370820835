import React, { Component } from 'react';
import { Select, Form, Spin, Tag } from 'antd';
import fetch from 'isomorphic-fetch';
import debounce from 'lodash/debounce';
import i18n from 'i18next';

import { formItemLayout } from '../../../utility/constants'

const { Option } = Select;
const FormItem = Form.Item;

class UserList extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 400);
    this.state = {
      data: [],
      value: props.value || [],
      fetching: false,
      selectedValue: props.value || [],
    };
  }

  getName = (obj) => (obj.public_name || `${obj.first_name || ''} ${obj.second_name || ''} ${obj.parent_name || ''}`);

  getLang = () => {
    const langs = ['ru', 'tat', 'en'];
    if (langs.includes(i18n.language)) {
      return `${i18n.language}_name`
    }
    return 'ru_name';
  }

  fetchUser = (value) => {
    const { path, getSearchUrl } = this.props;
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ data: [], fetching: true });
    fetch(getSearchUrl(path, value))
      .then(response => response.json())
      .then((d) => {
        if (fetchId !== this.lastFetchId) { // for fetch callback order
          return;
        }
        if (d.results) {
          const data = d.results.map(user => ({
            text: this.getName(user),
            value: user.uid,
            user,
          }));
          this.setState({ data, fetching: false });
        }
      });
  }

  handleChange = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
      selectedValue: value,
    });
  }

  handleSelect = (value, option) => {
    this.props.handleSelect(this.state.data[option.props.index].user, value);
  };

  render() {
    const options =
      this.state.data
        .map((d, index) => (
          <Option key={d.value} index={index}>
            {d.text}
            <div>{d.user.birthday}</div>
            <div>
              {d.user.scientific_interests && d.user.scientific_interests.map(item =>
                <Tag key={item}>{this.props.tags.get(item)[this.getLang()]}</Tag>
              )}
            </div>
          </Option>)
        );

    const {
      getFieldDecorator,
      inputName,
      title,
    } = this.props;

    return (
      <FormItem
        {...formItemLayout}
        label={title}
        className="select-turn-off-selected"
      >
        <Select
          mode="multiple"
          labelInValue
          placeholder={this.props.placeholder}
          style={this.props.style}
          defaultActiveFirstOption={false}
          filterOption={false}
          value={this.state.value}
          notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
          onSearch={this.fetchUser}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {options}
        </Select>
        {
          getFieldDecorator && getFieldDecorator(inputName,
            { rules: [], initialValue: this.state.selectedValue })(<input
              type="text"
              name={inputName}
              hidden
            />)}
      </FormItem>
    );
  }
}

export default UserList;
