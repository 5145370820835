import React from 'react';
import { Modal, Form, Button, Input } from 'antd';
import { withFormik } from 'formik';
import RelationComponent from 'components/CreatePanel/templates/relations/relations';
import AutoInputGQL from 'components/CreatePanel/templates/fields/autoInputGQL';
import { listQueries } from 'components/CreatePanel/queries/getList';

import './index.scss';

const FormItem = Form.Item;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     visible,
                     loading,
                     hide,
                     objUid,
                     addConnection,
                     options,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  const formInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const newConnection = (title, objkey, id, relation) => {
    addConnection({ id, relation})
  }

  const handleSelectResearch = (e) => {
    form.setFieldsValue({ research: e.value });
  }

  const config = {
    req: {
      rules: [{
        required: true, message: t('common.default_error'),
      }],
    },
  };

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t(`dataPanel.button.add_connection`)}
        </h2>
      </div>

      <AutoInputGQL
        handleSelect={handleSelectResearch}
        getFieldDecorator={getFieldDecorator}
        formLayout={formInputLayout}
        label={t('create_panel.tab.research.choose')}
        selectkey="research_select"
        noAddOption
        query={listQueries.researchSearch()}
        params={[{ key: 'search', val: 'value' }]}
        rules={config.req}
        t={t}
      />

      <FormItem>
        {getFieldDecorator('research', {})(
          <Input hidden />)}
      </FormItem>

      <RelationComponent
        handleAddExtraRelation={newConnection}
        getFieldDecorator={getFieldDecorator}
        formLayout={formInputLayout}
        objkey={objUid}
        title={options.label}
        tabName={options.label}
        stateTabs={false}
        form={form}
        t={t}
      />

      <FormItem
        wrapperCol={{ offset: 8, span: 8 }}
      >
        <Button
          type="primary"
          onClick={handleSubmit}
          className="create-form-button"
          disabled={loading}
        >
          {t('common.add')}
        </Button>
      </FormItem>
    </Modal>
  );
}

const AddConnectionModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      const relations = [];
      props.newConnections.forEach((val, i) => {
        relations[i] = val
        relations[i].research = fieldsValue.research
        relations[i].left.uid = props.objUid
      })


      props.createConnections({relations})
      setTimeout(setSubmitting(false), 1000);
      props.hide()
    })
  },
})(InnerForm));

export default AddConnectionModal;
