import React, { useEffect, useState } from 'react'
import { graphql } from 'relay-runtime'
import { Button, message, Table } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { fetchGrapqlMutation } from 'utility/request'
import useDataApi from 'utility/fetchGraphHook'
import { getPropertyByNameString as allocator } from 'utility/transformData/constants'

import { getLinkByModel } from '../utils'

const QUERY = graphql`
  query ToMeQuery {
    me {
      accessRequestObjects {
        uid
        model
        name
        label
        requests {
          createdAt
          author {
            uid
            publicName
          }
        }
      }
    }
  }
`

export const TO_ME_QUERY = graphql`
  query ToMeCountQuery {
    me {
      accessRequestObjects {
        uid
      }
    }
  }
`

const MUTATION = graphql`
  mutation ToMeMutation($data: MakeDecisionInputType!) {
    makeDecisionRequest(data: $data) {
      permission
    }
  }
`

const getMutationParams = (record, decision) => {
  return {
    model: record.model,
    uid: record.uid,
    decision,
    requester: record.author.uid,
  }
}

const excludeFunction = val => `${val.uid}-${val.author.uid}`

export const toMeMapper = ({ me: data }) => {
  const requestToMe = allocator(data, 'accessRequestObjects') || []
  return requestToMe
}

export const ProfileTabMessagesToMe = props => {
  const { t } = props
  const [filtered, setFiltered] = useState([])

  const { data, isLoading, isError } = useDataApi(QUERY, null)
  const [toMe, setToMe] = useState([])

  useEffect(() => {
    const requestToMe = allocator(data, 'me.accessRequestObjects') || []
    const formatedReqToMe = []
    requestToMe.forEach(req => {
      const requests = req.requests || []
      requests.forEach(author => {
        formatedReqToMe.push({
          uid: req.uid,
          model: req.model,
          name: req.name,
          label: req.label,
          createdAt: author.createdAt,
          author: author.author,
        })
      })
    })
    setToMe(formatedReqToMe)
    // setMy(mySharedSelections.map(({ node }) => node));
  }, [data])

  const confirm = record => {
    const body = {
      data: getMutationParams(record, true),
    }
    return fetchGrapqlMutation(MUTATION, body, response => {
      if (response.makeDecisionRequest) {
        setFiltered([...filtered, excludeFunction(record)])
        message.success(t('request_access.action.confirmed'))
      }
    })
  }

  const decline = record => {
    const body = {
      data: getMutationParams(record, false),
    }
    return fetchGrapqlMutation(MUTATION, body, response => {
      if (response.makeDecisionRequest) {
        setFiltered([...filtered, excludeFunction(record)])
        message.success(t('request_access.action.declined'))
      }
    })
  }

  const COLUMNS = [
    {
      title: t('request_access.table.name'),
      dataIndex: 'name',
      key: 'title',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Link
          key={record.uid}
          to={`/show/${getLinkByModel(record.model, record.label)}/${
            record.uid
          }`}
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: t('request_access.table.type'),
      dataIndex: 'model',
      key: 'model',
      sorter: (a, b) => a.model.localeCompare(b.model),
    },
    {
      title: t('request_access.table.author'),
      dataIndex: 'author',
      render: (text, record) => (
        <Link key={record.author.uid} to={`show/author/${record.author.uid}`}>
          {record.author.publicName}
        </Link>
      ),
      sorter: (a, b) => a.author.publicName.localeCompare(b.author.publicName),
      key: 'author',
    },
    {
      title: t('request_access.table.date'),
      dataIndex: 'createdAt',
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      key: 'created',
      render: created => moment(created).format('LLL'),
    },
    {
      title: t('request_access.action.confirm'),
      key: 'confirm',
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={() => confirm(record)}>
            {t('request_access.action.confirm')}
          </Button>
        </span>
      ),
    },
    {
      title: t('request_access.action.decline'),
      key: 'decline',
      render: (text, record) => (
        <span>
          <Button type="danger" onClick={() => decline(record)}>
            {t('request_access.action.decline')}
          </Button>
        </span>
      ),
    },
  ]

  return (
    <Table
      loading={isLoading}
      columns={COLUMNS}
      dataSource={toMe.filter(
        item => !filtered.includes(excludeFunction(item)),
      )}
      rowKey="uid"
    />
  )
}
