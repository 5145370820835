import React from 'react'
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  getDistinctNameArray,
} from 'utility/transformData/constants';
import getLayerFields from 'utility/transformData/fields/layer';
import { GRADATION_TYPE } from 'utility/constants';

export default function getSiteFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const item = d;
      const interpretationNameArray = getDistinctNameArray(item.interpretations);
      const result = [
        createCommonField('name', interpretationNameArray.map(name => (<div>{name}</div>))),
        createCommonField('gradation_type', d.gradation_type ? GRADATION_TYPE[d.gradation_type] : '-'),
      ];
      if (d.district) {
        result.push(
          createLinkField('district', d.district.uid, 'subjects/District',
            getLangTitle(d.district))
        )
      }
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      const array = d.results || d.data
      return array.map((item, index) => {
        const itemObj = {}
        itemObj.content = []
        if (item.interpretations && item.interpretations[0]) {
          itemObj.content.push(
            createLinkField('name', item.uid, 'subjects/Site',
              getLangTitle(item.interpretations[0])),
          )
          itemObj.name = getLangTitle(item.interpretations[0]);
          if (d.layers) {
            itemObj.childrenItem = {
              subjectType: 'layer',
              content:
                getLayerFields(d.layers[index], TAB_TYPE.COLLAPSE,
                  getLangTitle(item.interpretations[0])),
            }
          }
        } else if (item.interpretation) {
          // site tab in research
          const interpretation = item.interpretation[0] ?
            item.interpretation[0] : item.interpretation;
          itemObj.content.push(
            createLinkField('name', item.uid, 'subjects/Site',
              getLangTitle(interpretation)),
          )
          itemObj.name = getLangTitle(interpretation);
          if (item.layers) {
            itemObj.childrenItem = {
              subjectType: 'layer',
              content:
                getLayerFields({ response: { results: item.layers } }, TAB_TYPE.COLLAPSE,
                  getLangTitle(interpretation)),
            }
          }
        } else if (item.research) {
          itemObj.content.push(
            createLinkField('name', item.uid, 'subjects/Site',
              getLangTitle(item.research)),
          )
          itemObj.name = getLangTitle(item.research);
          if (d.layers) {
            itemObj.childrenItem = {
              subjectType: 'layer',
              content: getLayerFields(d.layers[index], TAB_TYPE.COLLAPSE,
                getLangTitle(item.research)),
            }
          }
        }
        return itemObj;
      }
      )
    }
    default:
      return [];
  }
}
