export const Options = [
  'research',
  'layer',
  'assemblage',
  'site',
  'heritage',
  'author',
  'culture',
  'excavation',
  'radiocarbon',
  'artifact',
  'person',
  'monument',
  'district',
  'locality',
  'encorg',
  'vernacular',
  'lexeme',
  'variant',
  'term',
]

export const TypePolygonSearch = [
  'author',
  'artifact',
  'layer',
  'assemblage',
  'site',
  'research',
  'radiocarbon',
  'heritage',
  'excavation',
  'culture',
  'districts',
  'locality',
  'encorg',
  'monument',
]

export const Forms = {
  author: [
    {
      type: 'common',
      title: 'author_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  layer: [
    {
      type: 'common',
      title: 'layer_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'select',
      title: 'layer_epoch',
      inputName: 'epoch',
      selectType: 'epoches',
    },
    {
      type: 'select',
      title: 'layer_type',
      inputName: 'siteType',
      selectType: 'sitetypes',
    },
  ],
  assemblage: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'select',
      title: 'assemblage_type',
      inputName: 'assemblageType',
      selectType: 'assemblageTypes',
    },
  ],
  site: [
    {
      type: 'common',
      title: 'site_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  research: [
    {
      type: 'common',
      title: 'research_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'research_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  culture: [
    {
      type: 'common',
      title: 'culture_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  excavation: [
    {
      type: 'common',
      title: 'excavation_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'supervisorName',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'research_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  radiocarbon: [
    {
      type: 'common',
      title: 'radiocarbon_index',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  artifact: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  monument: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  heritage: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const EForms = {
  author: [
    {
      type: 'common',
      title: 'author_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  research: [
    {
      type: 'common',
      title: 'research_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'research_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  person: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  district: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  locality: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  monument: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  encorg: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const LForms = {
  author: [
    {
      type: 'common',
      title: 'author_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  research: [
    {
      type: 'common',
      title: 'research_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'research_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  lexeme: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  term: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  variant: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'sense',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  vernacular: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  locality: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const ArchiveForms = {
  bookSeries: [
    {
      type: 'common',
      title: 'series_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  author: [
    {
      type: 'common',
      title: 'author_name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  research: [
    {
      type: 'common',
      title: 'research_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'research_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  report: [
    {
      type: 'common',
      title: 'report_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'report_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  publication: [
    {
      type: 'common',
      title: 'publication_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'publication_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  book: [
    {
      type: 'common',
      title: 'book_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'publication_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  articlePeriodical: [
    {
      type: 'common',
      title: 'article_name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'author_name',
      inputName: 'author',
      inputType: 'text',
    },
    {
      type: 'number',
      title: 'publication_year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  periodical: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const FolkForms = {
  folkVariant: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'common',
      title: 'year',
      inputName: 'year',
      inputType: 'number',
    },
  ],
  tale: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
    {
      type: 'select',
      title: 'tale_genre',
      inputName: 'tale_genre',
      selectType: 'folkGenres',
    },
    {
      type: 'select',
      title: 'tale_type',
      inputName: 'tale_type',
      selectType: 'folkTypes',
    },
    {
      type: 'common',
      title: 'tale_variety',
      inputName: 'tale_variety',
      selectType: 'folkTypes',
    },
  ],
  character: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  folkPerson: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const WrForms = {
  wrMonument: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  wrCodex: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  wrManuscript: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  wrWork: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
  wrEpigraphic: [
    {
      type: 'common',
      title: 'name',
      inputName: 'search',
      inputType: 'text',
    },
  ],
}

export const defaultKnowledgeType = 'archaeology'

export const knowledgeTypes = {
  archaeology: Forms,
  encyclopedia: EForms,
  linguistics: LForms,
  archive: ArchiveForms,
  folklore: FolkForms,
  writing: WrForms,
}

export const knowledgeTypesSpatials = {
  archaeology: Forms,
  encyclopedia: EForms,
}

export default Options
