/* eslint-disable no-unneeded-ternary */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'

import { formItemLayout as formItemLayoutConst } from '../../utility/constants'
import { sleep } from '../../redux/sagas/lib'
import { email } from '../../utility/validation'
import emailForm from '../EmailForm'

const FormItem = Form.Item

class Initials extends Component {
  static propTypes = {
    formItemLayout: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  // opened_flag = this.props.opened_flag;

  state = {
    last_name: '',
    first_name: '',
    parent_name: '',
    public_name: '',
    email: '',
  }

  handleChange = (e, name) => {
    global.isHandleEvent = 'true'
    document.getElementById('first_name').style.color = 'rgba(0, 0, 0, 0.65)'
    document.getElementById('last_name').style.color = 'rgba(0, 0, 0, 0.65)'
    document.getElementById('parent_name').style.color = 'rgba(0, 0, 0, 0.65)'
    // document.getElementById('birthday').style.color = 'rgba(0, 0, 0, 0.65)'
    document.getElementById('public_name').style.color = 'rgba(0, 0, 0, 0.25)'
    document.getElementById('email').style.color = 'rgba(0, 0, 0, 0.65)'

    if (name === 'public_name') {
      this.setState({ [name]: e.target.value })
    } else {
      const currentState = this.state
      currentState[name] = e.target.value
      // const publicName = `${currentState.last_name} ${currentState.first_name.substring(0, 1)}.${currentState.parent_name.substring(0, 1)}.`;
      const publicName =
        currentState.parent_name.substring(0, 1) !== ''
          ? `${currentState.last_name} ${currentState.first_name.substring(
              0,
              1,
            )}.${currentState.parent_name.substring(0, 1)}.`
          : `${currentState.last_name} ${currentState.first_name.substring(
              0,
              1,
            )}.${currentState.parent_name.substring(0, 1)}`
      this.setState({
        [name]: e.target.value,
        public_name: publicName,
      })
      this.props.form.setFields({
        public_name: {
          value: publicName,
        },
      })
    }
  }

  render() {
    const {
 getFieldDecorator, disablePublicName, labels, t
} = this.props

    if (this.props.opened_flag === false) {
      document.getElementById('first_name').style.color = '#FFFFFF00'
      document.getElementById('last_name').style.color = '#FFFFFF00'
      document.getElementById('parent_name').style.color = '#FFFFFF00'
      // document.getElementById('birthday').style.color    = '#FFFFFF00'
      document.getElementById('public_name').style.color = '#FFFFFF00'
      document.getElementById('email').style.color = '#FFFFFF00'

      global.AutoClearNewAuthor = 0
      global.isHandleEvent = 'false'
    } else {
      const id_fn = document.getElementById('first_name')
      const id_ln = document.getElementById('last_name')
      const id_pn = document.getElementById('parent_name')
      // let id_bd = document.getElementById('birthday');
      const id_pu = document.getElementById('public_name')
      const id_em = document.getElementById('email')

      if (id_ln !== null) {
        if (global.AutoClearNewAuthor === '0') {
          setTimeout(() => {
            id_fn.value = ''
            id_ln.value = ''
            id_pn.value = ''
            // id_bd.value = '';
            id_pu.value = ''
            id_em.value = ''
            global.AutoClearNewAuthor = '1'
          }, 10)
        }
      }
    }

    const firstNameRequired = this.props.dontNeedFirstName ? false : true

    const formItemLayout = this.props.formItemLayout || formItemLayoutConst

    const config = {
      first_name: {
        rules: [
          {
            required: firstNameRequired,
            message: labels
              ? labels.first_name.error
              : t('signUp.form.first_name.error'),
          },
        ],
        initialValue: this.state.first_name,
      },
      last_name: {
        rules: [
          {
            required: true,
            message: labels
              ? labels.last_name.error
              : t('signUp.form.last_name.error'),
          },
        ],
        initialValue: this.state.last_name,
      },
      public_name: {
        rules: [
          {
            required: !disablePublicName,
            message: labels
              ? labels.public_name.error
              : t('signUp.form.public_name.error'),
          },
        ],
        initialValue: this.state.public_name,
      },
      parent_name: {
        rules: [],
        initialValue: this.state.parent_name,
      },
      email: {
        rules: [
          {
            type: 'email',
            message: labels
              ? labels.email.error
              : t('signUp.form.email.error.valid'),
          },
        ],
        initialValue: this.state.email,
      },
    }
    return (
      <Fragment>
        <FormItem label={t('signUp.form.last_name.title')} {...formItemLayout}>
          {getFieldDecorator(
            'last_name',
            config.last_name,
          )(<Input onChange={e => this.handleChange(e, 'last_name')} />)}
        </FormItem>
        <FormItem label={t('signUp.form.first_name.title')} {...formItemLayout}>
          {getFieldDecorator(
            'first_name',
            config.first_name,
          )(<Input onChange={e => this.handleChange(e, 'first_name')} />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.parent_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator(
            'parent_name',
            config.parent_name,
          )(<Input onChange={e => this.handleChange(e, 'parent_name')} />)}
        </FormItem>
        <FormItem
          label={t('signUp.form.public_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator(
            'public_name',
            config.public_name,
          )(
            <Input
              placeholder={t('signUp.form.public_name.placeholder')}
              onChange={e => this.handleChange(e, 'public_name')}
              disabled={disablePublicName}
            />,
          )}
        </FormItem>
        <FormItem label={t('signUp.form.email.title')} {...formItemLayout}>
          {getFieldDecorator(
            'email',
            config.email,
          )(<Input onChange={e => this.handleChange(e, 'email')} />)}
        </FormItem>
      </Fragment>
    )
  }
}

export default Initials
