import React from 'react';
import { Modal, Button, Form, Input, InputNumber, Upload, Icon, message, Select } from 'antd';
import { withFormik } from 'formik';
import { listQueries } from 'components/CreatePanel/queries/getList';

import '../../index.scss';
import config from './config';

const FormItem = Form.Item;
const { OptGroup, Option } = Select;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     bookSeriesCreated,
                     visible,
                     loading,
                     hide,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  if (bookSeriesCreated) {
    hide()
  }

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t('create_panel.modal.addNewBookSeries.title')}
        </h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <FormItem
          wrapperCol={{ offset: 10, span: 6 }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
}

const AddBookSeriesModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      params.tat_name = params.ru_name;
      params.source_type = 'bookseries';
      props.handleSubmit(params);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));

export default AddBookSeriesModal;
