import { put, fork, takeLatest, call } from 'redux-saga/effects'

import {
  getNotification,
  errorNotify,
  successNotify,
} from '../../utility/notification'
import { actions as notification } from '../modules/notification'
import {
  postOrganization,
  postOrganizationAccount,
  getOrganization,
  editOrganization,
  getOrgUid,
} from '../api/organization'
import {
  constants as organizationConstants,
  actions as organizationActions,
} from '../modules/organization'
// eslint-disable-next-line import/no-cycle

export function* fetchCreateOrganizationAccount(action) {
  const loadNotification = getNotification(
    'organizationAdd',
    'loading',
    'creating'
  )
  yield put(notification.show(loadNotification))
  const { account, organization } = action.payload

  const { response, error } = yield call(postOrganization, account)

  if (response) {
    const { uid } = response
    const organizationObject = { ...organization }
    organizationObject.organization = `${uid}`
    yield put(
      organizationActions.createOrganization({
        organization: organizationObject,
      })
    )

    yield put(notification.hide(loadNotification.id))
    yield call(successNotify, 'organizationAdd', 'created')
  } else {
    yield put(notification.hide(loadNotification.id))
    yield call(errorNotify, 'organizationAdd', 'error')
    yield put(organizationActions.createOrganizationAccountFail(error))
  }
}

export function* fetchCreateOrganization(action) {
  const loadNotification = getNotification(
    'organizationAdd',
    'loading',
    'saving'
  )
  yield put(notification.show(loadNotification))
  const { organization } = action.payload
  const { response, error } = yield call(postOrganizationAccount, organization)

  if (response) {
    yield put(organizationActions.createOrganizationSuccess())
    yield put(notification.hide(loadNotification.id))
    yield call(successNotify, 'organizationAdd', 'saved')
  } else {
    yield put(notification.hide(loadNotification.id))
    yield call(errorNotify, 'organizationAdd', 'saving_error')
    yield put(organizationActions.createOrganizationFail(error))
  }
}
export function* fetchOrgUid() {
  const { response } = yield call(getOrgUid)

  yield put(organizationActions.getOrgUidSuccess(response.uid))
}
export function* fetchOrganization() {
  const res = yield call(getOrgUid)
  const { response, error } = yield call(getOrganization, res.response.uid)
  const loadNotification = getNotification('profile', 'loading')
  yield put(notification.show(loadNotification))
  if (response) {
    yield put(organizationActions.getOrganizationSuccess(response))
    yield put(notification.hide(loadNotification.id))
  } else {
    yield put(notification.hide(loadNotification.id))
    yield call(errorNotify, 'profile', 'error')
    yield put(organizationActions.getOrganizationFailed())
  }
}

export function* fetchEditOrganization(action) {
  const loadNotification = getNotification('profile', 'loading')
  yield put(notification.show(loadNotification))
  const { id, account } = action.payload
  const { response, error } = yield call(editOrganization, id, account)

  if (response) {
    yield put(organizationActions.editOrganizationSuccess(response))
    yield put(notification.hide(loadNotification.id))
  } else {
    yield put(notification.hide(loadNotification.id))
    yield call(errorNotify, 'profile', 'error')
    yield put(organizationActions.editOrganizationFailed(error))
  }
}

function* watchOrganizationActions() {
  yield takeLatest(
    organizationConstants.CREATE_ORGANIZATION,
    fetchCreateOrganization
  )
  yield takeLatest(
    organizationConstants.CREATE_ORGANIZATION_ACCOUNT,
    fetchCreateOrganizationAccount
  )
  yield takeLatest(organizationConstants.GET_ORGUID, fetchOrgUid)
  yield takeLatest(organizationConstants.GET_ORGANIZATION, fetchOrganization)
  yield takeLatest(
    organizationConstants.EDIT_ORGANIZATION,
    fetchEditOrganization
  )
}

export const organizationSaga = [fork(watchOrganizationActions)]
