import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import { Select } from 'antd'
import './index.scss'

const LANG = i18n.language || 'ru'
const { Option } = Select

const AdvSearchFieldSelect = props => {
  const handleChange = selectValue => {
    props.onFieldChange({
      item: {
        selectValue,
      },
    })
  }

  const renderOptions = data => {
    const result = []
    data.forEach((item, key) => {
      const name = item.t ? item.t[LANG] : key
      const value = item.value !== undefined ? item.value : key
      result.push(
        <Option value={value} key={value}>
          {name}
        </Option>,
      )
    })
    return result
  }

  return (
    <Select
      showSearch
      style={{ width: 200, margin: '10px' }}
      placeholder={props.t('advanced.select.subject')}
      value={props.fields.selectValue}
      optionFilterProp="children"
      onChange={handleChange}
      filterOption={(input, option) =>
        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {renderOptions(props.data)}
    </Select>
  )
}

AdvSearchFieldSelect.propTypes = {
  data: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
}

AdvSearchFieldSelect.defaultProps = {}

export default AdvSearchFieldSelect
