import React from 'react';
import {
  TAB_TYPE,
  createCommonField,
  createLinkField,
  getLangTitle,
  fullNameToShort,
  getCoauthors,
  getResearches,
  addProperty,
} from 'utility/transformData/constants';

const generateCipher = (item) => {
  const keys = [
    'fond_number',
    'inventory_number',
    'save_number',
    'sheet_number',
  ]
  const { place_data } = item;
  const placeArr = [];
  if (!place_data) return '-';
  place_data.forEach(place => {
    let result = `${getLangTitle(place.organization)}. `;
    if (place) {
      keys.forEach(key => {
        if (place[key]) {
          if (key === 'inventory_number') {
            result += '№ '
          }
          result += `${place[key]}. `
        }
      })
    }
    placeArr.push(<span>{result}</span>);
  })
  return placeArr;
}

export default function getReportFields(d, tabType) {
  switch (tabType) {
    case TAB_TYPE.MAIN: {
      const { author } = d;
      const result = [
        createLinkField('author', author.uid, 'author',
          fullNameToShort(author)),
        createCommonField('coauthors', getCoauthors(d.coauthors)),
        createCommonField('research', getResearches(d.research)),
      ];
      addProperty('year', d, result);
      result.push(createCommonField('arch_cipher', generateCipher(d)))
      return result;
    }
    case TAB_TYPE.COLLAPSE: {
      return d.results.map((item) => {
        const itemObj = {}
        itemObj.content = [
          createLinkField('label', item.uid, `source/${item.label}`,
            'Перейти к отчету'),
          createCommonField('title', getLangTitle(item)),
          createCommonField('year', item.year),
        ]
        itemObj.content.push(createCommonField('arch_cipher', generateCipher(item)))
        itemObj.name = '';
        if (item.author.public_name) {
          itemObj.name += `${item.author.public_name}, `
        }
        if (item.year) {
          itemObj.name += `${item.year}, `
        }
        itemObj.name += getLangTitle(item);
        return itemObj;
      })
    }
    default:
      return [];
  }
}
