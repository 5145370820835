import React, { Component } from 'react';
import { Input, Select, Form, InputNumber, Collapse, Button } from 'antd';
import { url } from 'utility/constants';
import * as request from 'utility/request';

import '../../index.scss';
import AutoInputGQL from '../fields/autoInputGQL';
import { searchFields } from './subjects';

const FormItem = Form.Item;
const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

class RelationComponent extends Component {
  constructor(props) {
    super(props);
    this.newTabIndex = 0;
    this.state = {
      tabs: [],
      relations: [],
      defaultModel: '',
      defaultSelect: '',
    };
  }

  componentDidMount = () => {

    const getConnectionUrl = `${url}/api/v2/subjects/connection-fields/`
    const body = { label: this.props.title }
    const headers = {
      ...request.HEADERS_POST_AUTH(),
      method: 'POST',
    }
    headers.body = JSON.stringify(body);

    fetch(getConnectionUrl, headers)
      .then(response => response.json())
      .then(relations => {
        this.setState({
          relations,
          defaultModel: relations[0].connection_model,
          defaultSelect: relations[0].connection_field,
        })
      })
      .catch((error) => {
      });
  }

  addTab = () => {
    const key = this.newTabIndex++;
    this.state.tabs.push({
      key,
      model: this.state.defaultModel,
      relType: this.state.defaultSelect,
      selectedOption: 0,
    });
    this.forceUpdate()
  }

  handleChange = (id, relationType, option) => {
    const tabs = this.state.tabs;
    const { form, objkey, title } = this.props;
    tabs[id].model = option.props['data-model']
    tabs[id].selectedOption = option.props['data-id']
    tabs[id].relType = relationType
    this.setState({ tabs });
    form.setFieldsValue({
      [`relation-select-${title}-${objkey}-${id}`]: '',
    })
  }

  handleRelationChange = (data, title, objkey, id) => {
    const { tabs } = this.state;
    const { tabName, handleAddExtraRelation } = this.props;
    const relation = {
      connection_field: tabs[id].relType,
      left: {
        label: title,
      },
      right: {
        uid: data.value,
        label: tabs[id].model,
      },
      base: {},
      tabName,
    }
    if (handleAddExtraRelation) {
      handleAddExtraRelation(title, objkey, id, relation);
    }
  }

  createField = (fields, fieldKey, relKey) => {
    const { getFieldDecorator, formLayout, objkey, title } = this.props;
    const fieldLabel = fields[fieldKey].help_text

    if (fieldKey !== "uid") {
      return (
        <FormItem
          {...formLayout}
          label={fieldLabel}
        >
          {getFieldDecorator(`relation-${title}-${objkey}-${relKey}-${fieldLabel}`)(
            <Input/>)}
        </FormItem>
      )
    }

    return (
      <span></span>
    )
  }

  render() {
    const {
      title,
      objkey,
      getFieldDecorator,
      stateTabs,
      formLayout,
      t,
    } = this.props;

    const {
      tabs,
      defaultSelect,
      relations,
    } = this.state;

    const connectionFields = [];

    const options = relations.map((rel, id) => {
      connectionFields[id] = rel.base;

      if (!searchFields[rel.connection_model]) {
        return false
      }

      if (rel.connection_field === 'child_localities') {
        return false
      }

      const text = rel.connection_field_label

      return <Option value={rel.connection_field} data-id={id} data-model={rel.connection_model}>
          {text}
        </Option>
    })

    return (
      <div>
        <div className="create-panel-relations-title">{t('create_panel.relations.title')}</div>
        <Collapse marginTop={50}>
          {tabs.map((item, id) => (
            <Panel header={`#${1 + item.key}`} key={item.key}>
              <FormItem
                {...formLayout}
                label={t('create_panel.relations.subj_type')}
              >
                {getFieldDecorator(`relation-type-${title}-${objkey}-${item.key}`, {
                  initialValue: defaultSelect
                })(
                  <Select onSelect={(relationType, option) => {
                    this.handleChange(id, relationType, option)}
                  }>
                    {options}
                  </Select>)}
              </FormItem>
              <AutoInputGQL
                handleSelect={(data) => {
                  this.handleRelationChange(data, title, objkey, id)
                }}
                getFieldDecorator={getFieldDecorator}
                formLayout={formLayout}
                label={t('create_panel.relations.subj_name')}
                selectkey={`relation-select-${title}-${objkey}-${item.key}`}
                selectType={item.model}
                stateTabs={stateTabs}
                objkey={item.key}
                noAddOption
                query={searchFields[item.model].query}
                params={[{ key: searchFields[item.model].key || 'search', val: 'value' }]}
                t={t}
              />
              {
                Object.keys(connectionFields[item.selectedOption]).length > 1 &&
                <div>
                  <div className="create-panel-relations-title create-panel-lower-title">{t('create_panel.relations.attributes')}</div>

                  {
                    Object.keys(connectionFields[item.selectedOption]).map((key) => (
                      this.createField(connectionFields[item.selectedOption], key, item.key)
                    ))
                  }
                </div>
              }
            </Panel>
          ))}
        </Collapse>

        <FormItem
          wrapperCol={{ span: 8, offset: 8 }}
        >
          <Button
            type="primary"
            onClick={this.addTab}
            className="create-form-button"
          >
            {t('create_panel.relations.add_button')}
          </Button>
        </FormItem>
      </div>
    );
  }
}

export default RelationComponent;
