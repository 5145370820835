import React, { Component } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-i18next'
import { Prompt } from 'react-router'
import { actions } from '../../redux/modules/create'
import { actions as actionsMap } from '../../redux/modules/example'
import MapPanel from '../../components/MapPanel'
import CreatePanel from '../../components/CreatePanel'
import ResearchTab from '../../components/CreatePanel/templates/tabs/research'

const mapStateToProps = state => ({
  mapContent: state.quickSearch.spatials || { isLoading: true, data: [] },
  interactiveMarkers: state.example.interactiveMarkers || { data: [] },
  createData: state.create,
})

const mapDispatchToProps = {
  handleCreateResearch: actions.createResearch,
  updateErrorsInSubjectFields: actions.updateErrorsInSubjectFields,
  handleCreateHeritage: actions.createHeritage,
  handleChangeInteractiveMarker: actionsMap.changeInteractiveMarker,
  filelistClear: actions.sourceFilelistClear,
  filelistChanged: actions.sourceFilelistChanged,
}

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class CreateView extends Component {
  constructor(props) {
    super(props)
    this.newTabIndex = 4
    const tabs = [
      {
        title: 'research',
        content: ResearchTab,
        key: '1',
        closable: false,
        render: true,
      },
      {
        title: 'culture',
        key: '2',
        render: false,
        subj: [],
        relations: {},
      },
      {
        title: 'ensemble',
        key: '3',
        render: false,
        subj: [],
        relations: {},
      },
    ]
    this.state = {
      activeKey: tabs[0].key,
      tabs,
      addTabVisible: false,
      extraRelations: {},
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', event => {
      event.returnValue = 'warning'
    })
  }

  componentDidMount() {
    this.setupBeforeUnloadListener()
  }

  onChange = activeKey => {
    this.setState({ activeKey })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  addNewTab = tab => {
    const { title, content } = tab
    if (this.state.tabs.filter(t => t.title === title).length === 0) {
      const activeKey = `${this.newTabIndex++}`
      this.state.tabs.push({
        title,
        content,
        key: activeKey,
        subj: [
          {
            status: 'nonselected',
            key: '0',
          },
        ],
        relations: {},
        render: true,
      })
      this.setState({ addTabVisible: false, activeKey })
    }
  }

  showModal = () => {
    this.setState({ addTabVisible: true })
  }

  hideModal = () => {
    this.setState({ addTabVisible: false })
  }

  remove = targetKey => {
    let { activeKey } = this.state
    let lastIndex = 0
    this.state.tabs.forEach((tab, i) => {
      if (tab.key === targetKey) {
        lastIndex = i - 1
      }
    })
    const tabs = this.state.tabs.filter(tab => tab.key !== targetKey)
    if (lastIndex >= 0 && activeKey === targetKey) {
      activeKey = this.state.tabs[lastIndex].key
    }
    this.setState({ tabs, activeKey })
  }

  handleChangeStateTab = newState => {
    const tab = this.state.tabs.filter(t => t.title === newState.title)[0]
    if (newState.key === -1) {
      tab.subj.push({
        ...newState,
        key: `${tab.subj.length}`,
      })
    } else {
      const othersubj = tab.subj.filter(t => t.key !== newState.key)
      const subj = tab.subj.filter(t => t.key === newState.key)[0]
      tab.subj[newState.key] = {
        ...subj,
        ...newState,
      }
    }
    return `${tab.subj.length - 1}`
  }

  handleTabAddRelation = rel => {
    const tab = this.state.tabs.filter(t => t.title === rel.title)[0]
    if (!tab.relations[rel.key]) {
      tab.relations[rel.key] = {}
    }

    tab.relations[rel.key][rel.with] = {
      ...rel,
    }
  }

  handleAddExtraRelation = (title, objKey, relKey, body) => {
    const { extraRelations } = this.state
    if (!extraRelations[title]) {
      extraRelations[title] = []
    }
    if (!extraRelations[title][objKey]) {
      extraRelations[title][objKey] = []
    }
    extraRelations[title][objKey][relKey] = body
    this.setState({ extraRelations })
  }

  handleTabAddSubj = (tabTitle, key) => {
    const { subj } = this.state.tabs.filter(t => t.title === tabTitle)[0]
    subj.push({
      status: 'nonselected',
      key,
    })
  }

  handleAddMarker = e => {
    this.props.handleChangeInteractiveMarker({
      key: e.target.dataset.key,
      title: e.target.dataset.title,
    })
  }

  render() {
    const {
      interactiveMarkers,
      handleChangeInteractiveMarker,
      filelistClear,
      filelistChanged,
      t,
    } = this.props
    const mapContent = this.props.mapContent.data
    return (
      <div>
        <Prompt message={t('common.are_you_sure')} />
        <CreatePanel
          t={t}
          searchResults={this.props.searchResults}
          onChangeTab={this.onChange}
          onEditTab={this.onEdit}
          showModal={this.showModal}
          hideModal={this.hideModal}
          addNewTab={this.addNewTab}
          state={this.state}
          handleAddExtraRelation={this.handleAddExtraRelation}
          handleChangeStateTab={this.handleChangeStateTab}
          handleTabAddSubj={this.handleTabAddSubj}
          handleTabAddRelation={this.handleTabAddRelation}
          createData={this.props.createData}
          handleCreateResearch={this.props.handleCreateResearch}
          updateErrorsInSubjectFields={this.props.updateErrorsInSubjectFields}
          handleCreateHeritage={this.props.handleCreateHeritage}
          handleChangeInteractiveMarker={
            this.props.handleChangeInteractiveMarker
          }
          interactiveMarkers={interactiveMarkers}
          handleAddMarker={this.handleAddMarker}
          filelistClear={filelistClear}
          filelistChanged={filelistChanged}
        />
        <MapPanel
          content={mapContent}
          interactiveMarkers={interactiveMarkers}
          handleChangeInteractiveMarker={handleChangeInteractiveMarker}
          t={t}
        />
      </div>
    )
  }
}

export default CreateView
