import React from 'react';
import { withFormik } from 'formik';
import { Form, DatePicker, Button, Alert, Spin } from 'antd';
import moment from 'moment'

import Initials from 'components/Initials'
import EmailForm from 'components/EmailForm'
import { formItemLayout } from 'utility/constants'

const FormItem = Form.Item;

const disabledDate = current => {
  // Can not select days after today
  return current && current > moment()
}

const renderErrors = (error, t) => {
  const keys = Object.keys(error);
  const result = [];
  keys.forEach(item => {
    result.push(
      <Alert key={item} message={`${t(`signUp.form.${item}.title`)}: ${error[item]}`} type="error" />,
    )
  });
  return result;
}

const InnerForm = ({
  handleSubmit,
  isLoading,
  isError,
  form,
  t,
}) => {
  const { getFieldDecorator } = form;

  const config = {
    email: {
      rules: [
        {
          type: 'email',
          message: t('signUp.form.email.error.valid'),
        },
      ],
    },
    birthday: {
      rules: [
        {
          type: 'object',
          required: true,
          message: t('signUp.form.birthday.error'),
        },
      ],
    },
  }

  return (
    <Form onSubmit={handleSubmit} className="" id="researcher-create">
      {isError && renderErrors(isError, t)}
      <Spin spinning={isLoading}>
        <Initials getFieldDecorator={getFieldDecorator} t={t} form={form} />
        <FormItem {...formItemLayout} label={t('signUp.form.birthday.title')}>
          {getFieldDecorator('birthday', config.birthday)(
            <DatePicker
              placeholder={t('signUp.form.birthday.placeholder')}
              format="DD.MM.YYYY"
              style={{ width: '100%' }}
              disabledDate={disabledDate}
            />
        )}
        </FormItem>
        <EmailForm getFieldDecorator={getFieldDecorator} t={t} handleSubmit={handleSubmit} />
      </Spin>
      <input type="submit" hidden id="user_create_btn" />
    </Form>
  )
};

// Wrap our form with the using withFormik HoC
const ResearcherCreate = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (values,
    {
      props,
      setSubmitting,
    }) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }
      const body = {}
      if (fieldsValue.email) {
        body.email = fieldsValue.email;
      }
      body.first_name = fieldsValue.first_name;
      body.last_name = fieldsValue.last_name;
      body.parent_name = fieldsValue.parent_name;
      body.public_name = fieldsValue.public_name;
      body.birthday = fieldsValue.birthday
        ? fieldsValue.birthday.format('DD.MM.YYYY')
        : ''
      props.handleSubmit(body);
      setTimeout(setSubmitting(false), 1000);
    });
  },
})(InnerForm));

export default ResearcherCreate;
