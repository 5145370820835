import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message, notification as notify } from 'antd';
import findIndex from 'lodash/findIndex';
import { translate } from 'react-i18next';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
// import 'antd/dist/antd.css';
import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

import NotFoundView from './views/notFound';
import LoginView from './views/login';
import SignUpView from './views/signup';
import ShowView from './views/show';
import ShowAuthorView from './views/showAuthor';
import CreateView from './views/create';
import ProfileView from './views/profile';
import LingAboutView from './views/lingAbout';
import RecoveryView from './views/recovery';
// import IndexView from './views/index';
import LingIndexView from './views/LingIndex';
import SearchView from './views/lingSearch';
import RoutePublic from './utility/RoutePublic';
import RoutePrivate from './utility/RoutePrivate';
import Header from './components/LingHeader';
import Footer from './components/LingFooter';
import { actions } from './redux/modules/user';
import { actions as utilsActions } from './redux/modules/utils';

import './index.scss';

const HeaderWithRouter = withRouter(props => <Header {...props} />);

const mapStateToProps = state => ({
  user: state.user,
  notification: state.notification,
});

const mapDispatchToProps = {
  getCurrentUser: actions.getCurrentUser,
  handleExit: actions.logOut,
  changeLanguage: utilsActions.changeLanguage,
};

@translate()
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class RoutesLing extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    handleExit: PropTypes.func.isRequired,
    getCurrentUser: PropTypes.func.isRequired,
    changeLanguage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.notifications = new Map();
  }

  componentDidMount() {
    this.props.getCurrentUser();
    i18n
      .use(LngDetector)
      .init({
        whitelist: ['ru', 'en'],
        fallbackLng: 'ru',
        preload: ['ru', 'en'],

      })
  }

  render() {
    const {
      user,
      handleExit,
      changeLanguage,
    } = this.props;
    return (
      <div className="container">
        <HeaderWithRouter
          isAuthenticated={user.isAuthenticated}
          handleExit={handleExit}
          role={user.role}
          changeLanguage={changeLanguage}
        />
        <div className="container__content">
          <Switch>
            <Route isAuthenticated={user.isAuthenticated} path="/" exact component={LingIndexView} />
            <RoutePublic isAuthenticated={user.isAuthenticated} path="/login" exact component={LoginView} />
            <RoutePublic isAuthenticated={user.isAuthenticated} path="/signup" exact component={SignUpView} />
            <RoutePublic isAuthenticated={user.isAuthenticated} path="/recovery" exact component={RecoveryView} />
            <Route isAuthenticated={user.isAuthenticated} path="/about" exact component={LingAboutView} />
            <Route isAuthenticated={user.isAuthenticated} path="/show/author/:objId" exact component={ShowAuthorView} />
            <Route isAuthenticated={user.isAuthenticated} path="/show/:objCategory/:objType/:objId" component={ShowView} />
            <Route isAuthenticated={user.isAuthenticated} path="/show/:objCategory/:objType" component={ShowView} />
            <Route isAuthenticated={user.isAuthenticated} path="/quick-search" exact component={SearchView} />
            <RoutePrivate isAuthenticated={user.isAuthenticated} path="/data-entry" exact component={CreateView} />
            <RoutePrivate isAuthenticated={user.isAuthenticated} path="/profile" exact component={ProfileView} />
            <Route component={NotFoundView} />
          </Switch>
        </div>
        <Footer />
      </div>
    )
  }
}

export default RoutesLing;
