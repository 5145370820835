import React from 'react'
import i18n from 'i18next'
import { getPropertyByNameString } from 'utility/transformData/constants'

export const getLangName = data =>
  getPropertyByNameString(data, `${i18n.language}Name`)

export const getidentifier = data =>
  getPropertyByNameString(data, 'identifier')

export const getItem = label => data => getPropertyByNameString(data, label)

export const getInterpretation = data => {
  const names = getPropertyByNameString(data, 'interpretation')
  const mainName = names.length > 0 ? names[0][`${i18n.language}Name`] : 'NA'
  const otherNames =
    names.length > 1
      ? names
          .slice(1)
          .map(i => i[`${i18n.language}Name`])
          .join(', ')
      : null
  return otherNames ? `${mainName} (${otherNames})` : `${mainName}`
}

export const getLayerCulture = (layerNode, lang) => {
  const culture = getPropertyByNameString(layerNode, `node.commonCultures.0.culture.${lang}Name`) || i18n.t('common.culture_unknown')
  const cultureCount = getPropertyByNameString(layerNode, `node.commonCultures.0.count`)
  const otherText = ' ' + i18n.t('common.and_other')
  return `${culture}${cultureCount > 1 ? otherText : ''}`
}
