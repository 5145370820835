import React, { Component } from 'react'
import { LayersControl, Map, TileLayer } from 'react-leaflet'
import i18n from 'i18next'
import { connect } from 'react-redux'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { isUdmurt, UDMURT_CENTER } from 'utility/constants'
import 'leaflet-draw/dist/leaflet.draw.css'
import { transformCoords } from 'utility/markers'

import PolylineMeasure from 'utility/leaflet-measure'
import ControlGeocoder from 'utility/leaflet-control-geocoder'
import BetterScale from 'utility/leaflet-betterscale'
import MousePosition from 'utility/leaflet-mouse-position'
import MarkerClusterGroup from 'utility/leaflet-sub-group/markerClusterGroup'
import LayerAddMarkerComponent from './layerAddMarkerComponent'
import Snapshots from './snapshots'
import LeafletDraw from './leafletDraw'
import { actions } from '../../redux/modules/example'
import './index.css'

const { BaseLayer, Overlay } = LayersControl
const { CRS } = L

const mapStateToProps = state => ({
  mapState: state.example.mapState,
})

const mapDispatchToProps = {
  handleChangeMapPosition: actions.changeMapPosition,
}

// rewrite default marker for easyprint
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconUrl: require('../../distLibs/images/marker.svg'),
  iconRetinaUrl: require('../../distLibs/images/marker.svg'),
  shadowUrl: null,
})

@connect(mapStateToProps, mapDispatchToProps)
class LayersControlExample extends Component {
  // parentMapState from index view
  state = this.props.parentMapState || this.props.mapState
  layersControl = React.createRef()
  lMap = React.createRef()

  componentDidMount() {
    // try to fix change tab bug
    // on tab change update zoom to make points visible
    this.setState({
      zoom: this.state.zoom - 1,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.content.length !== this.props.content.length &&
      !this.props.isIndex
    ) {
      const pointsArray = []
      this.props.content.forEach(point => {
        if (point.markers) {
          point.markers.forEach(marker => {
            pointsArray.push([marker.coords.lat, marker.coords.lng])
          })
        }
        if (point.polygons) {
          point.polygons.forEach(polygon => {
            const transformedPolygon = transformCoords(polygon.coords)
            for (let i = 0; i < transformedPolygon.length; i += 2) {
              pointsArray.push([
                transformedPolygon[i],
                transformedPolygon[i + 1],
              ])
            }
          })
        }
      })
      if (this.lMap && this.lMap.current && pointsArray.length !== 0) {
        const bounds = L.latLngBounds(pointsArray)
        this.lMap.current.leafletElement.fitBounds(bounds)
      }
      /* Crunch for centering map on the point ( causes bug) */
      // if (this.lMap && this.lMap.current && pointsArray.length === 1) {
      //   setTimeout(() => {
      //     this.lMap.current.leafletElement.setView(pointsArray[0], 4)
      //   }, 500)
      // }
    }
  }

  handleChangeCrs = e => {
    const newCrs = e.layer.options.crs || CRS.EPSG3857
    const newView = e.layer.options.center
    if (newCrs !== this.state.crs) {
      this.setState({
        crs: newCrs,
        showContent: false,
      })
      e.target.options.crs = newCrs
      e.target.zoomOut()
    }
    if (newView) {
      e.target.setView(newView)
    }
  }

  handleViewChange = e => {
    const { zoom } = this.state
    if (e.zoom !== zoom) {
      this.setState({
        zoom: e.zoom,
      })
    }
  }

  handleViewChanged = e => {
    if (e.center && e.zoom) {
      this.props.handleChangeMapPosition({
        lat: e.center[0],
        lng: e.center[1],
        zoom: e.zoom,
      })
      this.setState({
        lat: e.center[0],
        lng: e.center[1],
        zoom: e.zoom,
        showContent: true,
      })
    }
  }

  handleFitBoundsLocality = (bounds, index) => {
    const { content } = this.props
    const { localities } = content[this.state.activeDistrict]
    const { buildings } = localities[index]
    if (this.lMap && this.lMap.current) {
      this.lMap.current.leafletElement.fitBounds(bounds)
    }
    this.setState({
      buildings,
    })
  }

  handleFitBounds = (bounds, index) => {
    const { content } = this.props
    const { sites, localities } = content[index]
    if (this.lMap && this.lMap.current) {
      this.lMap.current.leafletElement.fitBounds(bounds)
    }
    this.setState({
      sites,
      localities,
      activeDistrict: index,
    })
  }

  render() {
    const center = isUdmurt() ? UDMURT_CENTER : [this.state.lat, this.state.lng]

    const { zoom, crs, minZoom, maxZoom, maxBounds = null } = this.state
    const {
      content,
      t,
      maps,
      overlayMaps,
      interactiveMarkers,
      handleChangeInteractiveMarker,
      toggleSnapshot,
      initialPolygon,
    } = this.props
    return (
      <Map
        center={center}
        zoom={zoom}
        animate
        onBaseLayerChange={this.handleChangeCrs}
        onViewportChange={this.handleViewChange}
        onViewportChanged={this.handleViewChanged}
        crs={crs}
        maxZoom={maxZoom || 18}
        minZoom={minZoom}
        ref={this.lMap}
        maxBounds={maxBounds}
      >
        <LayersControl position="topright" ref={this.layersControl}>
          {maps &&
            maps.map(item => (
              <BaseLayer
                checked={item.checked}
                name={item[`${i18n.language}Name`] || item.name || 'unnamed'}
                key={item[`${i18n.language}Name`] || item.name || item.uid}
              >
                <TileLayer
                  className={item.isGrey ? 'grey' : ''}
                  minZoom={item.minZoom}
                  maxZoom={item.maxZoom}
                  zoomOffset={item.zoomOffset || 0}
                  subdomains={item.subdomains}
                  attribution={item.attribution}
                  url={item.url}
                  key={item.url}
                  crs={item.crs}
                  tms={item.tms}
                  center={item.center}
                  {...item.options}
                />
              </BaseLayer>
            ))}
          {overlayMaps &&
            overlayMaps.map(item => (
              <Overlay
                checked={item.checked}
                name={item[`${i18n.language}Name`] || item.name || item.uid}
                key={item[`${i18n.language}Name`] || item.name || item.uid}
              >
                <TileLayer
                  className={item.isGrey ? 'grey' : ''}
                  minZoom={item.minZoom}
                  maxZoom={item.maxZoom}
                  zoomOffset={item.zoomOffset || 0}
                  subdomains={item.subdomains}
                  attribution={item.attribution}
                  url={item.url}
                  key={item.url}
                  crs={item.crs}
                  tms={item.tms}
                  center={item.center}
                  {...item.options}
                />
              </Overlay>
            ))}
          <MarkerClusterGroup
            t={t}
            data={content}
            zoom={zoom}
            needCluster={!isUdmurt()}
            layersControl={this.layersControl}
          />
          <LayerAddMarkerComponent
            markers={interactiveMarkers}
            handleChangeInteractiveMarker={handleChangeInteractiveMarker}
          />
        </LayersControl>
        <LeafletDraw map={this.lMap} polygon={initialPolygon} />
        <PolylineMeasure map={this.lMap} />
        <BetterScale />
        <Snapshots map={this.lMap} toggleSnapshot={toggleSnapshot} />
        <MousePosition />
        <ControlGeocoder />
      </Map>
    )
  }
}

export default LayersControlExample
