import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { actions } from '../../redux/modules/example';
import MapPanel from '../../components/MapPanel';
import SearchPanel from '../../components/GSearchPanel';

const mapStateToProps = state => ({
  mapContent: state.example.spatials || { isLoading: true, data: [] },
});

const mapDispatchToProps = {
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class SearchView extends Component {
  constructor(props) {
    super(props);
    const { objType, objId } = this.props.match.params;
    const { search } = this.props.location;
    const params = search.substr(1);
    const searchMap = new Map();
    params.split('&').forEach(str => {
      const pair = str.split('=');
      searchMap.set(pair[0], pair[1]);
    })
    this.state = {
      type: searchMap.get('type') || null,
      search: searchMap.get('search') || null,
    }
  }

  render() {
    const { t } = this.props;
    const { type, search } = this.state;
    const mapContent = this.props.mapContent.data;
    const linguisticTypes = [
      'term',
      'lexeme',
      'variant',
      'locality',
      'vernacular',
    ]

    return (
      <div>
        <SearchPanel
          t={t}
          searchType={type}
          search={search}
          typeOptions={linguisticTypes}
        />
        <MapPanel
          content={mapContent}
          t={t}
        />
      </div>
    )
  }
}

export default SearchView;
