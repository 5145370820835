import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'i18next'

import { actions } from 'redux/modules/quickSearch'
import SearchForm from './SearchForm'
import ResultPanel from './Results'
import { knowledgeTypes } from './SearchForm/searchOptions'
import Pagination from './Pagination'
import ResultsNum from './Pagination/Num'
import catQueries from './Queries/categoryQueries'
import { getFirstLastElements } from './Pagination/utils'
import { transformSelectToQuery } from './Queries/queries'

const mapStateToProps = props => ({
  searchResults: props.quickSearch.searchResults,
  savedQuery: props.quickSearch.savedQuery,
  searchPolygon: props.example.drawnPolygon,
})

const mapDispatchFromProps = {
  handleSubmit: actions.simpleSearch,
  handleClean: actions.simpleSearchClean,
}

const categoryNames = [
  'search_panel.category.site',
  'search_panel.category.layer',
  'search_panel.category.monument',
  'search_panel.category.research',
  'search_panel.category.excavation',
  // 'search_panel.category.artifact',
  'search_panel.category.publication',
  'search_panel.category.report',
  'search_panel.category.documentation',
]

const locationNames = {
  BOLGAR: 'search_panel.location.bolgar',
  SVIYAZHSK: 'search_panel.location.sviyazhsk',
  FOLKLORE: 'search_panel.location.folklore',
}

const defaultPageData = {
  page: 1,
  pageSize: '25',
  sort: {},
}

const normalizePolygonSpatials = data => {
  const poly = []
  const spatials = data[0] || []

  spatials.forEach((s, i) => {
    poly[i] = []
    poly[i][0] = s.lng
    poly[i][1] = s.lat
  })

  return poly
}

const sortObjectToArray = obj => {
  const sort = Object.keys(obj).map(key => {
    const char = obj[key] === 'descend' ? '-' : ''
    return `${char}${key}`
  })

  return { sort }
}

@connect(
  mapStateToProps,
  mapDispatchFromProps,
)
class GSearchPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      panelData: this.configureInitialSearchFormData(this),
      showSearchForm: true,
      categoryTitle: false,
      resultFields: {},
      pageData: {
        ...defaultPageData,
      },
    }
  }

  componentDidMount = () => {
    this.props.handleClean()
    const { search, searchType } = this.props
    if (searchType) {
      const params = {}
      const pageProps = getFirstLastElements(this.state.pageData)
      params.first = pageProps.first
      params.last = pageProps.last
      params.search = search || ''

      const queryObject = transformSelectToQuery(
        this.state.panelData.searchType,
      )
      const body = {
        params,
        newQuery: true,
        query: queryObject.query,
        url: queryObject.url,
      }
      this.setState({ resultFields: queryObject.fields, })
      this.props.handleSubmit(body)
    }
    if (this.props.matchParams) {
      const {
        matchParams: { catId, location },
      } = this.props
      if (catId && location) {
        const query = catQueries[catId]()
        this.setState({ showSearchForm: false, categoryTitle: true, resultFields: query.fields, })
        this.props.handleSubmit({
          query: query.query,
          params: { location },
          url: query.url,
        })
      }
    }
  }

  componentDidUpdate = () => {
    if (
      !Object.keys(this.props.matchParams).length &&
      this.state.showSearchForm === false
    ) {
      this.setState({ showSearchForm: true, categoryTitle: false })
      this.props.handleClean()
    }
  }

  configureInitialSearchFormData = ({ props }) => {
    /*
    Setup initial search form parameters
     */
    let knowledgeType = null
    const data = {
      searchType: props.searchType,
      search: props.search,
    }
    if (props.searchType) {
      /*
      Define knowledgeType field in the form
       */
      const defineKnow = searchType => {
        return Object.keys(knowledgeTypes).find((elem, index) => {
          return Object.keys(knowledgeTypes[elem]).includes(searchType)
        })
      }
      knowledgeType = defineKnow(props.searchType)
      data.knowledgeType = knowledgeType
    }
    return data
  }

  handleChangeSelectSearchObject = e => {
    const {
      panelData: { knowledgeType },
    } = this.state
    this.setState({
      panelData: {
        knowledgeType,
        searchType: e,
        search: null,
      },
      pageData: {
        ...defaultPageData,
      },
    })
  }

  handleChangeKnowledgeSelect = (value, form, optionValue) => {
    form.setFieldsValue({
      select_object: optionValue,
    })
    this.setState({
      panelData: {
        knowledgeType: value,
        searchType: null,
        search: null,
      },
      pageData: {
        ...defaultPageData,
      },
    })
  }

  handleSort = sort => {
    const { pageData } = this.state
    const { page, pageSize } = pageData
    const newSort = { ...pageData.sort }

    if (sort.order) {
      newSort[sort.field] = sort.order
    } else {
      delete newSort[sort.field]
    }

    this.setState({
      pageData: {
        ...pageData,
        sort: newSort,
      },
    })
    const { savedQuery } = this.props
    const body = {
      params: {
        ...savedQuery.params,
        ...getFirstLastElements({ page, pageSize }),
        ...sortObjectToArray(newSort),
      },
      newQuery: false,
      query: savedQuery.query,
      url: savedQuery.url,
    }
    this.props.handleSubmit(body)
  }

  handleSubmit = (page, pageSize) => {
    const { pageData } = this.state
    this.setState({
      pageData: { ...pageData, page, pageSize },
    })
    const { savedQuery } = this.props
    const body = {
      params: {
        ...savedQuery.params,
        ...getFirstLastElements({ page, pageSize }),
        ...sortObjectToArray(pageData.sort),
      },
      newQuery: false,
      query: savedQuery.query,
      url: savedQuery.url,
    }
    this.props.handleSubmit(body)
  }

  handleFormSubmit = body => {
    this.setState({
      resultFields: body.fields,
    })
    this.props.handleSubmit(body)
  }

  render() {
    const {
      searchResults,
      handleClean,
      savedQuery,
      matchParams,
      searchPolygon,
      t,
    } = this.props

    const {
      showSearchForm,
      categoryTitle,
      pageData,
      resultFields,
      panelData: { searchType, knowledgeType, search, searchBy },
    } = this.state

    let header = categoryTitle
      ? `${t(locationNames[matchParams.location])}: ${t(
          categoryNames[matchParams.catId],
        )}`
      : this.props.header || t('search_panel.title')


    return (
      <div className="search-panel">
        <h1 className="search-panel-header">{header}</h1>
        {showSearchForm && (
          <SearchForm
            t={t}
            handleChangeKnowledgeSelect={this.handleChangeKnowledgeSelect}
            handleChangeSelectSearchObject={this.handleChangeSelectSearchObject}
            search={search}
            searchType={searchType}
            knowledgeType={knowledgeType}
            handleSubmit={this.handleFormSubmit}
            handleClean={handleClean}
            pageData={pageData}
            searchPolygon={normalizePolygonSpatials(searchPolygon)}
            handleChangeSelectSearchBy={this.handleChangeSelectSearchBy}
            searchBy={searchBy}
          />
        )}
        <ResultsNum counter={savedQuery.count} t={t} />
        {showSearchForm && (
          <Pagination
            pageData={pageData}
            handleSubmit={this.handleSubmit}
            savedQuery={savedQuery}
          />
        )}
        <ResultPanel
          results={searchResults}
          url={savedQuery.url}
          handleSort={this.handleSort}
          resultFields={resultFields}
          counter={savedQuery.count || 0}
          t={t}
        />
        {showSearchForm && (
          <Pagination
            pageData={pageData}
            handleSubmit={this.handleSubmit}
            savedQuery={savedQuery}
          />
        )}
      </div>
    )
  }
}

export default GSearchPanel
