// eslint-disable-next-line import/no-cycle
import * as request from 'utility/request';
// eslint-disable-next-line import/no-cycle
import { store } from 'index'
import { getPropertyByNameString } from 'utility/transformData/constants'

export const editInterpretation = async data => {
  const path = `api/v2/subject/uid/${data.subjectUid}/`
  const body = {
    research: {
      uid: data.researchUid,
      interpretation: {
        [data.fieldName]: data.newValue,
      },
    },
  }

  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const editSubjectNode = async data => {
  let path = `api/v2/subject/uid/${data.subjectUid}/`
  if (data.editType === 'photoNode') {
    path = `api/photo/uid/${data.subjectUid}/`
  }
  const body = {}
  if (data.attributes) {
    const state = getPropertyByNameString(
      store.getState(),
      'example.showPage.data.0.content',
    )
    const attributes = state.filter(
      field => field.editInfo && field.editInfo.attributes,
    )
    body.attributes = {}
    attributes.forEach(att => {
      const { editInfo } = att
      if (editInfo.attributeUid) {
        body.attributes[editInfo.fieldName] = [{ uid: editInfo.attributeUid }]
      }
    })
    body.attributes[data.fieldName] = [{ uid: data.newValue }]
  } else {
    body[data.fieldName] = data.newValue
  }

  const { response, error } = await request.safelyPost(
    path,
    body,
    request.HEADERS_POST_AUTH(),
    'PATCH',
  )
  return { response, error }
}

export const editResearchNode = async (data) => {
  const path = `api/research/uid/${data.researchUid}/`;
  const body = {
    [data.fieldName]: data.newValue,
  }

  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const editAuthorNode = async (data) => {
  const path = `api/author/uid/${data.authorUid}/`;
  const body = {
    [data.fieldName]: data.newValue,
  }

  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const editSourceNode = async (data) => {
  const path = `api/source/uid/${data.sourceUid}/`;
  const body = {
    [data.fieldName]: data.newValue,
  }

  const { response, error } = await request.safelyPost(path, body, request.HEADERS_POST_AUTH(), 'PATCH');
  return { response, error };
}

export const deleteInterpretation = async (data) => {
  const path = `api/v2/subject/uid/${data.subjectUid}/disconnect/research/uid/${data.researchUid}/`;
  const { response, error } = await request.safelyPost(path, {}, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const deleteSubjectConnection = async (data) => {
  const path = `api/v2/subjects/connect/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const deleteSpatial = async (data) => {
  const path = `api/spatial_reference/uid/${data.uid}/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH(), 'DELETE');
  return { response, error };
}

export const updateSpatial = async (data) => {
  const path = `api/polygon-recalculate/uid/${data.uid}/spatial_reference/`;
  const { response, error } = await request.safelyPost(path, data, request.HEADERS_POST_AUTH(), 'POST');
  return { response, error };
}
