import React from 'react'
import nanoid from 'nanoid'
import { Button, Radio } from 'antd'
import { AdvSearchField } from 'components/AdvSearch'
import './index.scss'

const AdvSearchBlock = props => {
  const handleRemove = () => {
    const { id, handleRemove: remove } = props
    remove(id)
  }

  const handleChildRemove = id => {
    const newElements = { ...props.fields.selections }
    delete newElements[id]
    props.onFieldChange({
      id: `${props.id}${props.isGroup ? '' : '.selections'}`,
      item: newElements,
      isRemove: true,
    })
  }

  const addField = () => {
    const newElements = { ...props.fields.selections }
    const newItemId = nanoid(3)
    newElements[newItemId] = {
      blockType: 'field',
      isNot: false,
      fieldName: null,
    }
    props.onFieldChange({
      id: `${props.id}${props.isGroup ? '' : '.selections'}`,
      item: newElements,
    })
  }

  const addBlock = () => {
    const newElements = { ...props.fields.selections }
    const newItemId = nanoid(3)
    newElements[newItemId] = {
      blockType: 'block',
      isNot: false,
      operator: 'all',
      selections: {},
    }
    props.onFieldChange({
      id: `${props.id}${props.isGroup ? '' : '.selections'}`,
      item: newElements,
    })
  }

  const onFieldChange = newState => {
    props.onFieldChange({
      ...newState,
      id: `${props.id}${props.isGroup ? '' : '.selections'}.${newState.id}`,
    })
  }

  const handleOperatorChange = e => {
    const { isGroup, id } = props
    if (isGroup) {
      props.handleOperatorChange(e)
    } else {
      props.onFieldChange({
        id,
        item: {
          operator: e.target.value,
        },
      })
    }
  }

  const renderElements = (selections = {}) => {
    // const { elements } = this.state;
    const result = []
    Object.entries(selections).forEach((item: [string, { blockType: any }]) => {
      result.push(
        <FieldComponent
          {...props}
          type={item[1].blockType}
          id={item[0]}
          fields={props.fields.selections[item[0]]}
          handleRemove={handleChildRemove}
          onFieldChange={onFieldChange}
          key={item[0]}
        />,
      )
    })
    return result
  }


  return (
    <div className="adv-search-block">
      <Radio.Group
        buttonStyle="solid"
        value={props.fields.operator}
        onChange={handleOperatorChange}
      >
        <Radio.Button value="all">{props.t('advanced.all')}</Radio.Button>
        <Radio.Button value="any">{props.t('advanced.any')}</Radio.Button>
      </Radio.Group>
      <div className="adv-search-block-body">
        <Button type="primary" className="field-add-button" onClick={addField}>
          {props.t('advanced.add.criteria')}
        </Button>
        <Button type="primary" className="field-add-button" onClick={addBlock}>
          {props.t('advanced.add.group')}
        </Button>
        {renderElements(props.fields.selections)}
      </div>
      {!props.isGroup && (
        <Button
          type="primary"
          shape="circle"
          icon="close"
          className="field-remove-button"
          onClick={handleRemove}
        />
      )}
    </div>
  )
}

const FIELD_TYPES = {
  field: AdvSearchField,
  block: AdvSearchBlock,
}

function FieldComponent(props) {
  const Component = FIELD_TYPES[props.type]
  const result = <Component {...props} />
  return result
}

export { AdvSearchBlock }
