import { graphql } from 'relay-runtime'
import i18n from 'i18next'

import archQueries from './archQueries'
import encQueries from './encQueries'
import lingQueries from './lingQueries'
import archiveQueries from './archiveQueries'
import { folkloreQueries } from './folkloreQueries'
import { writingQueries } from './writingQueries'

export const page = {
  def: '$first: Int=25, $last: Int=25, $sort: [String]=[]',
  params: 'first: $first, last: $last, sort: $sort',
}

export const pageWithNoSort = {
  def: '$first: Int=25, $last: Int=25',
  params: 'first: $first, last: $last',
}

export const searchPolygonArg = searchPolygon => {
  if (searchPolygon) {
    return '$searchPolygon: [[Float]],'
  }
  return ''
}

export const searchPolygonVar = searchPolygon => {
  if (searchPolygon) {
    return 'searchPolygon: $searchPolygon,'
  }
  return ''
}

export function transformSelectToQuery(type, searchPolygon = false) {
  switch (type) {
    case 'site':
      return archQueries.siteSearch(searchPolygon)
    case 'author':
      return archQueries.authorSearch(searchPolygon)
    case 'heritage':
      return archQueries.heritageSearch(searchPolygon)
    case 'assemblage':
      return archQueries.assemblageSearch(searchPolygon)
    case 'layer':
      return archQueries.layerSearch(searchPolygon)
    case 'culture':
      return archQueries.cultureSearch(searchPolygon)
    case 'excavation':
      return archQueries.excavationSearch(searchPolygon)
    case 'radiocarbon':
      return archQueries.radiocarbonSearch(searchPolygon)
    case 'artifact':
      return archQueries.artifactSearch(searchPolygon)
    case 'research':
      return archQueries.researchSearch(searchPolygon)
    case 'person':
      return encQueries.personSearch(searchPolygon)
    case 'monument':
      return encQueries.monumentSearch(searchPolygon)
    case 'locality':
      return encQueries.localitySearch(searchPolygon)
    case 'district':
      return encQueries.districtSearch(searchPolygon)
    case 'encorg':
      return encQueries.encOrgsSearch(searchPolygon)
    case 'term':
      return lingQueries.termSearch()
    case 'lexeme':
      return lingQueries.lexemeSearch()
    case 'language':
      return lingQueries.languageSearch()
    case 'epoch':
      return archQueries.epochSearch() 
    case 'dialect':
      return lingQueries.dialectSearch() 
    case 'vernacular':
      return lingQueries.vernacularSearch()
    case 'variant':
      return lingQueries.variantSearch()
    case 'report':
      return archiveQueries.reportSearch()
    case 'publication':
      return archiveQueries.publicationSearch()
    case 'periodical':
      return archiveQueries.periodicalSearch()
    case 'bookSeries':
      return archiveQueries.bookSeriesSearch()
    case 'book':
      return archiveQueries.bookSearch()
    case 'articlePeriodical':
      return archiveQueries.articleSearch()
    case 'folkVariant':
      return folkloreQueries.variantSearch()
    case 'tale':
      return folkloreQueries.taleSearch()
    case 'folkPerson':
      return folkloreQueries.personSearch()
    case 'character':      
      return folkloreQueries.characterSearch()
    case 'folkGenre':
      return folkloreQueries.genreSearch()
    case 'folkGenreGroup':
      return folkloreQueries.genreGroupSearch()
    case 'folkClass':      
      return folkloreQueries.folkClassSearch()
    case 'folkType':      
      return folkloreQueries.folkTypeSearch()
    case 'wrMonument':
      return writingQueries.wrMonSearch()
    case 'wrCodex':
      return writingQueries.wrCodexSearch()
    case 'wrWork':
      return writingQueries.wrWorkSearch()
    case 'wrEpigraphic':
      return writingQueries.wrEpigraphicSearch()
    case 'wrManuscript':
      return writingQueries.wrManuscriptSearch()
    default:
      return type
  }
}
