import React from 'react';
import getUrl from 'utility/images'
import './index.scss'

const an = getUrl('footer/an.jpg')
const tat = getUrl('footer/tat.jpg')
const crt = getUrl('footer/rf_crt.jpg')
const anW = getUrl('footer/an.webp')
const tatW = getUrl('footer/tat.webp')
const crtW = getUrl('footer/rf_crt.webp')


const Footer = (props) => {
  if (
    document.location.pathname ===
    '/show/research_arch/7d74f00c-1a0e-437d-9375-d3068ee2fdb9/'
  ) {
    return <div/>
  }
  if (
    document.referrer ===
    'https://www.culturetat.ru/show/research_arch/7d74f00c-1a0e-437d-9375-d3068ee2fdb9/'
  ) {
    return <div/>
  }
  return (
    <footer className="footer">
    <span className="copyright">
      <a href="http://www.antat.ru/ru/" target="_blank" rel="noopener noreferrer">
        {`© ${new Date().getFullYear()} ${props.t('footer.copyright')}`}
      </a>

    </span>
      <div className="logos">
        <a href="mailto:admin@culturetat.info">admin@culturetat.info</a>

        <div className="cert-link__wrapper">
          <a className="cert-link" href={'stuff/'} target="_blank">
            <img src={crt} alt={props.t('footer.crt')} title={props.t('footer.crt')}/>
          </a>
        </div>

        <picture>
          <source srcSet={crtW} type="image/webp"/>
          <source srcSet={crt} type="image/jpeg"/>
        </picture>
        <picture>
          <source srcSet={anW} type="image/webp"/>
          <source srcSet={an} type="image/jpeg"/>
          <img src={an} alt={props.t('footer.an')} title={props.t('footer.an')}/>
        </picture>
        <picture>
          <source srcSet={tatW} type="image/webp"/>
          <source srcSet={tat} type="image/jpeg"/>
          <img src={tat} alt={props.t('footer.rt')} title={props.t('footer.rt')}/>
        </picture>
      </div>
    </footer>
  )
}

export default Footer;
