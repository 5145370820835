import i18n from 'i18next'

export const attributeQueries = {
  defaultSearch: (type, needSort = true) => {
    const sort = needSort ? 'sort' : ''
    return {
      query: `
        query postQuery {
          results:${type} {
            edges {
              node {
                ${i18n.language}Name
                uid
                ${sort}
              }
            }
          }
        }`,
      render: item => {
        return `${item.node[`${i18n.language}Name`]}`
      },
    }
  },

  radioDatingMethods: () => {
    return {
      query: `
        query postQuery {
          results:radioDatingMethods {
            description
            uid: value
          }
        }`,
      render: item => {
        return item.description
      },
    }
  },
}
