import React from 'react'
import { withFormik } from 'formik'
import { Button, Form, Select, Checkbox } from 'antd'

import '../index.scss'
import { transformSelectToQuery } from '../Queries/queries'
import { attributeQueries } from '../Queries/attributeQueries'
import {
  defaultKnowledgeType,
  knowledgeTypes,
  knowledgeTypesSpatials,
  TypePolygonSearch,
} from './searchOptions'
import { commonInput, commonSelect, numberInput } from '../components'
import { generateConfig } from './searchFormLayers.py'
import { getFirstLastElements } from '../Pagination/utils'

const FormItem = Form.Item

const inputs = {
  common: commonInput,
  number: numberInput,
  select: commonSelect,
}

let sType = '-1'

function RenderFormComponent(props) {
  const { searchType, t, optionMap } = props

  // FIX
  // Можно использваоть this.props.history.push(`/quick-search?type=${selectValue}&search=${e}`) для
  // перехода в Быстрый поиск и полученияф нуждной информации.
  // Однако при таком подходе мы не получаем список в таблицы, а карта не всегда подгружается.
  // Если нажать НАЙТИ, то весь механизам отрабоатывает корректно.
  // По этому тут вставляем маленький фикс, который котозволяет через строку парамтров
  // задать нужные критерии поиска, а потом нажать на НАЙТИ и запустить механизм.
  // При таком подходе не лорматеся никакая логика уже существующая, а функционал расширяется позволяя
  // отрабатывать по обычной схеме.
  const btSubmit = document.querySelector('button[type="submit"]')
  let know_type = document.querySelectorAll('[title="Археология"]')[0]
  let know_option = document.querySelectorAll('[title="Автор"]')[0]

  const currentURL = window.location.href
  let url = new URL(currentURL)
  let isCmd = url.searchParams.has('sh_cmd')
  let cmdVal = -1

  if (global.QSBool === undefined) {
    global.QSBool = 'false'
  }

  if (isCmd === true) {
    cmdVal = url.searchParams.get('sh_cmd')
    if (know_type !== undefined) {
      know_type.innerHTML = "Фольклор"
    }
  }

  if ((btSubmit !== null) && (global.QSBool === 'false')) {
    // Нажимаем один раз и после этого нам уже не нужен этот механизм
    switch (cmdVal) {
      case "0":
        sType = 'folkVariant'
        if (know_option !== undefined) {
          know_option.innerHTML = "Вариант"
        }
        btSubmit.click()
        break
      case "1":
        sType = 'character'
        if (know_option !== undefined) {
          know_option.innerHTML = "Образ"
        }
        btSubmit.click()
        break
      case "2":
        sType = 'folkPerson'
        if (know_option !== undefined) {
          know_option.innerHTML = "Персона"
        }
        btSubmit.click()
        break
      case "3":
        sType = 'tale'
        if (know_option !== undefined) {
          know_option.innerHTML = "Произведение"
        }
        btSubmit.click()
        break
      default:
    }
  }

  ///// FIX END /////

  const FComponent = optionMap[searchType].map(field => {
    const params = { ...field }
    if (field.type === 'select') {
      params.selectQuery = attributeQueries.defaultSearch(params.selectType)
    }
    return { field: inputs[field.type], params }
  })


  return (
    <div>
      {FComponent.map((field, index) => (
        <field.field
          initialValue={index === 0 ? props.search : ''}
          key={field.params.inputName}
          text={props.searchType}
          getFieldDecorator={props.getFieldDecorator}
          formLayout={props.formItemLayout}
          title={field.params.title}
          inputName={field.params.inputName}
          inputType={field.params.inputType}
          selectQuery={field.params.selectQuery}
          searchType={searchType}
          t={t}
        />
      ))}
    </div>
  )
}

const InnerForm = ({
  values,
  touched,
  errors,
  handleChangeKnowledgeSelect,
  handleChangeSelectSearchObject,
  handleChangeResult,
  typeOptions,
  handleSubmit,
  handleClean,
  handleChange,
  handleBlur,
  isSubmitting,
  isError,
  searchPolygon,
  searchType,
  search,
  form,
  t,
  handleChangeSelect,
  knowledgeType,
}) => {
  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  let objectTypes = []
  let isKnowledgeTypeShow = true
  const { getFieldDecorator } = form
  knowledgeType = knowledgeType || defaultKnowledgeType
  const knowledges = Object.keys(knowledgeTypes)
  let optionMap = knowledgeTypes[knowledgeType]
  if (typeOptions) {
    isKnowledgeTypeShow = false
    objectTypes = typeOptions
    optionMap = knowledgeTypes.linguistics
  } else {
    objectTypes = Object.keys(optionMap)
  }
  searchType = searchType || objectTypes[0]
  // generate config params for form
  const config = generateConfig({
    knowledgeType,
    searchType,
    t,
    objectTypes,
  })



  // sort
  objectTypes = objectTypes.sort((a, b) => {
    return t(`search_panel.form.select.options.${a}`).localeCompare(
      t(`search_panel.form.select.options.${b}`),
    )
  })

  return (
    <Form onSubmit={handleSubmit} className="search-form">
      {isKnowledgeTypeShow ? (
        <FormItem
          label={t('search_panel.form.knowledge_type.label')}
          {...formItemLayout}
        >
          {getFieldDecorator('select_knowledge', config.selectKnowledge)(
            <Select
              onChange={value =>
                handleChangeKnowledgeSelect(
                  value,
                  form,
                  Object.keys(knowledgeTypes[value])[0],
                )
              }
            >
              {knowledges.map((item, index) => (
                <Select.Option
                  value={`${item}`}
                  key={`knowledge_option_${index}`}
                >
                  {t(`knowledge.${item}`)}
                </Select.Option>
              ))}
            </Select>,
          )}
        </FormItem>
      ) : null}
      <FormItem label={t('search_panel.form.select.label')} {...formItemLayout}>
        {getFieldDecorator('select_object', config.selectObject)(
          <Select
            placeholder={t('search_panel.form.select.placeholder')}
            onChange={handleChangeSelectSearchObject}
          >
            {objectTypes.map(item => (
              <Select.Option value={item} key={item}>
                {t(`search_panel.form.select.options.${item}`)}
              </Select.Option>
            ))}
          </Select>,
        )}
      </FormItem>

      <RenderFormComponent
        knowledgeType={knowledgeType}
        searchType={searchType}
        search={search === null ? '' : search}
        optionMap={optionMap}
        getFieldDecorator={getFieldDecorator}
        formItemLayout={formItemLayout}
        t={t}
      />

      <FormItem
        label={t('search_panel.form.select.spatialSearch.label')}
        {...formItemLayout}
      >
        {getFieldDecorator('spatialSearch', { initialValue: false })(
          <Checkbox disabled={!TypePolygonSearch.includes(searchType)} />,
        )}
      </FormItem>

      <FormItem wrapperCol={{ span: 6, offset: 9 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="search-form-button"
          disabled={isSubmitting}
        >
          {t('search_panel.form.submit')}
        </Button>
      </FormItem>
    </Form>
  )
}

// Wrap our form with the using withFormik HoC
const Index = Form.create()(
  withFormik({
    handleSubmit: (values, { props, setSubmitting }) => {
      values.form.validateFields((err, fieldsValue) => {
        if (err) {
          setSubmitting(false)
          return
        }

        const params = {}
        Object.keys(fieldsValue).forEach(key => {
          if (
            !key.includes('select') &&
            fieldsValue[key] &&
            fieldsValue[key] !== -1
          ) {
            params[key] = fieldsValue[key] || ''
          }
        })
        const pageProps = getFirstLastElements(props.pageData)
        params.first = pageProps.first
        params.last = pageProps.last
        params.searchPolygon = props.searchPolygon
        const { spatialSearch } = params.searchPolygon.length
          ? fieldsValue
          : false


        let queryObject = transformSelectToQuery(
          fieldsValue.select_object,
          spatialSearch,
        )



        if (global.QSBool === 'false') {
          global.QSBool = 'true'
          if (sType !== '-1') {
            // let kl = document.getElementsByClassName('ant-form ant-form-horizontal')[0]
            // kl.style.display = 'none'

            queryObject = transformSelectToQuery(
              sType,
              spatialSearch,
            )
          }
        }
        ///////////////////

        const body = {
          params,
          newQuery: true,
          query: queryObject.query,
          fields: queryObject.fields,
          url: queryObject.url,
        }
        props.handleClean()
        props.handleSubmit(body)
        setTimeout(setSubmitting(false), 1000)
      })
    },
  })(InnerForm),
)

export default Index
