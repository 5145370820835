/**
 * @flow
 * @relayHash b120a0b483fca9c55c768fb6fb0ca945
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type toolsSelectionQueryVariables = {||};
export type toolsSelectionQueryResponse = {|
  +selections: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +uid: ?string,
        +createdAt: ?any,
        +query: ?any,
        +description: ?string,
        +model: ?string,
        +name: ?string,
        +resultCount: ?number,
        +returnedFields: ?any,
        +creator: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +publicName: ?string
            |}
          |}>
        |},
      |}
    |}>
  |}
|};
export type toolsSelectionQuery = {|
  variables: toolsSelectionQueryVariables,
  response: toolsSelectionQueryResponse,
|};
*/


/*
query toolsSelectionQuery {
  selections {
    edges {
      node {
        uid
        createdAt
        query
        description
        model
        name
        resultCount
        returnedFields
        creator {
          edges {
            node {
              publicName
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "uid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "query",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "model",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resultCount",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "returnedFields",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "publicName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "toolsSelectionQuery",
  "id": null,
  "text": "query toolsSelectionQuery {\n  selections {\n    edges {\n      node {\n        uid\n        createdAt\n        query\n        description\n        model\n        name\n        resultCount\n        returnedFields\n        creator {\n          edges {\n            node {\n              publicName\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "toolsSelectionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selections",
        "storageKey": null,
        "args": null,
        "concreteType": "SelectionNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SelectionNode",
                "plural": false,
                "selections": [
                  v0,
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuthorSearchNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthorSearchNode",
                            "plural": false,
                            "selections": [
                              v8
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "toolsSelectionQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "selections",
        "storageKey": null,
        "args": null,
        "concreteType": "SelectionNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SelectionNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "SelectionNode",
                "plural": false,
                "selections": [
                  v0,
                  v1,
                  v2,
                  v3,
                  v4,
                  v5,
                  v6,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "creator",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AuthorSearchNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AuthorSearchNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AuthorSearchNode",
                            "plural": false,
                            "selections": [
                              v8,
                              v9
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  v9
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74e021ee76afc58d5ed057c860c8f7c9';
module.exports = node;
