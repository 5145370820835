import React from 'react';
import { Modal, Button, Form, Input, InputNumber, Upload, Icon, message, Select } from 'antd';
import { withFormik } from 'formik';
import { listQueries } from 'components/CreatePanel/queries/getList';
import archQueries from 'components/GSearchPanel/Queries/archQueries';
import CommonSelect from 'components/GSearchPanel/components/commonSelect';
import { attributeQueries } from 'components/GSearchPanel/Queries/attributeQueries';

import '../../index.scss';
import config from './config';
import AutoInputGQL from '../fields/autoInputGQL';
import AddBookSeriesModal from './newBookSeriesModal'
import i18n from "i18next";

const FormItem = Form.Item;
const { OptGroup, Option } = Select;

const InnerForm = ({
                     values,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     handleEdit,
                     isSubmitting,
                     isError,
                     form,
                     visible,
                     loading,
                     hide,
                     seriesVisible,
                     seriesHide,
                     seriesHandleSubmit,
                     seriesHandleChangeSelector,
                     newSeriesSelected,
                     createData,
                     newPublisher,
                     newPublisherName,
                     handleSelectPublisher,
                     t,
                   }) => {
  const { getFieldDecorator } = form;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  if (createData.newBook) {
    hide()
  }

  if (createData.newBookSeries) {
    form.setFieldsValue({
      series_select: createData.data.bookSeries[`${i18n.language}_name`],
      series: createData.data.bookSeries.uid,
    });
    newSeriesSelected();
  }

  const handlePublisherChange = (data) => {
    form.setFieldsValue({ publisher: data.uid });
  }

  const handleOrgChange = (data) => {
    form.setFieldsValue({ organization: data.uid });
  }

  const handleSeriesChange = (data) => {
    form.setFieldsValue({ series: data.uid });
  }

  const handleCityChange = (data) => {
    form.setFieldsValue({ city: data.uid });
  }

  const handleRedactorChange = (data) => {
    form.setFieldsValue({ editor: data.uid });
  }

  return (
    <Modal
      visible={visible}
      className="create-panel-modal"
      onCancel={hide}
      width={900}
      footer={null}
    >
      <div className="create-panel-modal-header">
        <h2>
          {t('create_panel.modal.addNewBook.title')}
        </h2>
      </div>

      <Form onSubmit={handleSubmit} className="search-form">
        <CommonSelect
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          inputName={`edition_type`}
          label={t('create_panel.modal.addNewReport.edition_type.title')}
          selectQuery={attributeQueries.defaultSearch('editionTypes', false)}
          dontNeedEmptyOption
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.ru_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('ru_name', config.ru_name
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.en_name.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('en_name', config.en_name
          )(<Input />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={seriesHandleChangeSelector}
          newObjChanged={handleRedactorChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="editor_select"
          label={t('create_panel.modal.addNewReport.redactor.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={archQueries.authorSearch()}
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.isbn.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('isbn', config.isbn
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.doi.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('doi'
          )(<Input />)}
        </FormItem>
        <AutoInputGQL
          handleSelect={seriesHandleChangeSelector}
          newObjChanged={handleSeriesChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="series_select"
          label={t('create_panel.modal.addNewReport.series.title')}
          addOptionText={t('create_panel.modal.addNewReport.create.bookSeries')}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.seriesSearch()}
          t={t}
        />
        <FormItem
          label={t('create_panel.modal.addNewReport.edition.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('edition', config.edition
          )(<Input />)}
        </FormItem>
        <FormItem
          label={t('create_panel.modal.addNewReport.volume.title')}
          {...formItemLayout}
        >
          {getFieldDecorator('volume', config.volume
          )(<Input />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={t('create_panel.modal.addNewReport.year_pub.title')}
        >
          {getFieldDecorator('year', config.year)(
            <InputNumber min={1700} max={new Date().getFullYear()} />)}
        </FormItem>
        <AutoInputGQL
          newObjChanged={handleCityChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="city_select"
          label={t('create_panel.modal.addNewReport.city.title')}
          noAddOption
          rules={config.req}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.citySearch()}
          t={t}
        />
        <AutoInputGQL
          handleSelect={handleSelectPublisher}
          newObjChanged={handlePublisherChange}
          getFieldDecorator={getFieldDecorator}
          formLayout={formItemLayout}
          selectkey="organization"
          label={t('create_panel.modal.addNewReport.publisher.title')}
          addOptionText={t('create_panel.modal.addNewReport.publisher.new')}
          rules={config.publisher}
          params={[{ key: 'search', val: 'value' }]}
          query={listQueries.publisherSearch()}
          t={t}
        />
        {newPublisher === true &&
          <div>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.ru_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_ru_name', { ...config.ru_name, initialValue: newPublisherName }
              )(<Input />)}
            </FormItem>
            <FormItem
              label={t('create_panel.modal.addNewReport.publisher.en_name')}
              {...formItemLayout}
            >
              {getFieldDecorator('publisher_en_name', { ...config.eb_name, initialValue: newPublisherName }
              )(<Input />)}
            </FormItem>
            <AutoInputGQL
              newObjChanged={handleOrgChange}
              getFieldDecorator={getFieldDecorator}
              formLayout={formItemLayout}
              selectkey="publisher-organization"
              label={t('create_panel.modal.addNewReport.publisher.organization')}
              noAddOption
              params={[{ key: 'search', val: 'value' }]}
              query={listQueries.organizationSearch()}
              t={t}
            />
          </div>
        }
        <FormItem>
          {getFieldDecorator('series', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('editor', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('city', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('publisher', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator('organization', {})(
            <Input hidden />)}
        </FormItem>
        <FormItem
          wrapperCol={{ offset: 10, span: 6 }}
        >
          <Button
            type="primary"
            onClick={handleSubmit}
            className="create-form-button"
            disabled={loading}
          >
            {t('common.add')}
          </Button>
        </FormItem>
      </Form>

      <AddBookSeriesModal
        visible={seriesVisible}
        hide={seriesHide}
        loading={createData.isLoading}
        bookSeriesCreated={createData.newBookSeries}
        handleSubmit={seriesHandleSubmit}
        t={t}
      />
    </Modal>
  );
}

const AddBookModal = Form.create()(withFormik({
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
    }
  ) => {
    values.form.validateFields((err, fieldsValue) => {
      if (err) {
        setSubmitting(false);
        return;
      }

      const params = {};

      Object.keys(fieldsValue).forEach(key => {
        if (fieldsValue[key]) {
          params[key] = fieldsValue[key]
        }
      });

      params.tat_name = params.ru_name;
      params.source_type = 'Book';
      // params.author = props.authorId;
      props.handleSubmit(params);
      setTimeout(setSubmitting(false), 1000);
    })
  },
})(InnerForm));

export default AddBookModal;
