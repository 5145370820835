import React from 'react'
import i18n from 'i18next'
import { Link } from 'react-router-dom'
import cyrillicToTranslit from 'distLibs/CyrillicToTranslit'
import { Tag } from 'antd'
import { LockTwoTone, UnlockTwoTone } from '@ant-design/icons'
import isNil from 'lodash/isNil'

const MAIN = 'main'
const GALLERY = 'gallery'
const RADIO = 'radio'
const LIST = 'list'
const COLLAPSE = 'collapse'

export const TAB_TYPE = {
  MAIN,
  GALLERY,
  RADIO,
  LIST,
  COLLAPSE,
}

export const RESEARCH_URL = {
  ArchaeologyResearch: 'research_arch',
  EncyclopediaResearch: 'research_enc',
  Research: 'research_arch',
  FolkResearch: 'folk_research',
  HistResearch: 'HistResearch',
  BookSeries: 'book_series',
}

function getAccessTitle(isOpen) {
  return i18n.t(`access.isOpen.${isOpen}`)
}

export function getAccessTag(isOpen) {
  if (!isOpen) {
    // @ts-ignore
    return (
      <LockTwoTone
        title={getAccessTitle(isOpen)}
        twoToneColor="#ef1212"
        style={{ paddingLeft: '5px' }}
      />
    )
  }
  // @ts-ignore
  return (
    <UnlockTwoTone
      title={getAccessTitle(isOpen)}
      twoToneColor="#2b7be5"
      style={{ paddingLeft: '5px' }}
    />
  )
}

export function getPermissionColor(permission) {
  let color
  switch (permission) {
    case 'PRIVATE':
      color = '#b62727'
      break
    case 'SHARED':
      color = '#3266aa'
      break
    default:
      color = '#4a9e2d'
  }
  return color
}

export function getAccessPermissionTags(object) {
  if (
    Object.keys(object).includes('permission') &&
    Object.keys(object).includes('isOpen')
  ) {
    return (
      <span>
        {object.permission ? (
          <Tag color={getPermissionColor(object.permission)}>
            {i18n.t(`access.${object.permission}`)}
          </Tag>
        ) : (
          ''
        )}
        {object.isOpen !== undefined ? getAccessTag(object.isOpen) : ''}
      </span>
    )
  }
  return <span />
}

export const newLineReg = /↵/gi

/**
 * Returns Tabs options for Map panel
 * @returns {object}
 */
export function getEmptyTabs() {
  return {
    map: [],
    topoplans: [],
    old: [],
    relief: [],
    special: [],
    aero: [],
  }
}

/**
 * Return params for dynamic initialize field
 * Support types:
 *    text, array, int
 * @param {string} name
 * @param {string} fieldText
 * @param editInfo edit information for field
 * @param {string} font
 * @returns {object}
 */
export function createCommonField(
  name: string,
  fieldText: any,
  editInfo?: any,
  font?: string,
): object {
  return {
    type: 'common',
    fieldName: name,
    text: fieldText,
    editInfo,
    font,
  }
}

/**
 * Return params for dynamic initialize field
 * Support types:
 *    text, array, int
 * @param {string} name
 * @param {string} fieldText
 * @param editInfo edit information for field
 * @returns {object}
 */
export function createEditCommonField(
  name: string,
  fieldText: any,
  editInfo?: any,
): object {
  const editDefault = {
    fieldName: name,
    fieldLabel: name,
  }
  return {
    type: 'common',
    fieldName: name,
    text: fieldText,
    editInfo: {
      ...editDefault,
      ...editInfo,
    },
  }
}

export function unionFields(fieldName: string, fields: object[]): object {
  return {
    type: 'vSplit',
    fieldName,
    fields,
  }
}
export function unionFieldsRow(fieldName: string, fields: object[]): object {
  return {
    type: 'rowline',
    fieldName,
    fields,
  }
}
export function createImageField(name: string, src: string) {
  return {
    type: 'image',
    fieldName: name,
    src,
  }
}

export function createGalleryField(src: string, tabType, fields) {
  return {
    type: 'gallery',
    src,
    fields,
  }
}

export function createLinkField(
  name: string,
  lid: string,
  ltype: string,
  fieldText: string | JSX.Element[],
  showLink: boolean = true,
  blank: boolean = false,
  editInfo: object = {},
) {
  const editDefault = {
    fieldName: name,
    fieldLabel: name,
  }
  return {
    type: 'link',
    fieldName: name,
    linkId: lid,
    linkType: ltype,
    text: fieldText,
    showLink,
    blank,
    editInfo: {
      ...editDefault,
      ...editInfo,
    },
  }
}

export function createChartField(name: string, data) {
  return {
    type: 'chart',
    fieldName: name,
    data,
  }
}

export const getChildrenPropArray = (path, data) => {
  let result = []
  if (!data || !path) return result
  if (path.length === 0) {
    return data
  }
  const current = getPropertyByNameString(data, path.shift()) || []
  current.forEach(item => {
    const i = getChildrenPropArray(path, item)
    result = [...result, i]
    if (Array.isArray(i)) {
      result = [...result, ...i]
    } else {
      result = [...result, i]
    }
  })
  return result
}

export function createReferenceField(name: string, data) {
  return {
    type: 'reference',
    fieldName: name,
    data,
  }
}

export function createButton(
  name: string,
  method,
  objUid,
  options = {},
  knowledge = 'ArchaeologySpatial',
) {
  return {
    type: 'button',
    buttonText: name,
    method,
    objUid,
    options,
    knowledge,
  }
}

export const getResearchUrl = (label: string = 'Research'): string =>
  RESEARCH_URL[label]

/**
 * Get object property if it exists
 * @param {object} object
 * @param {array} props - Array of props
 * @returns {*}
 */
export const getPropertyByName = (object: any, props: string[]) => {
  if (!object) return null
  if (props.length === 0) return object
  const name = props.shift()
  if (object[name]) {
    return getPropertyByName(object[name], props)
  }
  return null
}

/**
 * Generate props array from string 'a.b.c' => ['a', 'b', 'c']
 * @param {object} object
 * @param {string} nameStr
 * @returns {*}
 */
export const getPropertyByNameString = (
  object,
  nameStr: string,
): any | null => {
  const props = nameStr.split('.')
  return getPropertyByName(object, props)
}

/**
 * Return locale string or '-'
 * @param {object} item
 * @param {string} prefix
 * @returns {string}
 */
export const getLangTitle = (item: any, prefix: string = 'name'): string => {
  const title = item
    ? item[`${i18n.language}_${prefix}`] || item[`ru_${prefix}`] || '-'
    : '-'
  return title.trim()
}

export const getNodes = (arr: [object]) => {
  // @ts-ignore
  return arr.map(item => (item.node ? item.node : null)).filter(i => i !== null)
}

/**
 * Return locale string or '-'
 * @param {object} item
 * @param {string} prefix
 * @returns {string}
 */
export const getLangTitleGraph = (
  item: any,
  prefix: string = 'Name',
  defaultReturnValue: any = '',
): string => {
  const title = item
    ? item[`${i18n.language}${prefix}`] || item[`ru${prefix}`] || ''
    : defaultReturnValue
  if (isNil(defaultReturnValue) && title === defaultReturnValue) {
    return defaultReturnValue
  }
  return cyrillicToTranslit().t(title.trim(), i18n.language)
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getLangTitleGraphV2 = (
  item: any,
  prefix: string = '',
  suffix: string = 'Name',
): string => {
  if (prefix === '') {
    return item[`${i18n.language}${suffix}`]
  }
  return item[`${prefix}${capitalizeFirstLetter(i18n.language)}${suffix}`]
}

export function getAttribute(attrs: any, attrName: string, fieldName: string) {
  if (attrs && attrs[attrName] && attrs[attrName][0]) {
    return attrs[attrName][0][fieldName]
  }
  return ''
}

export function getAttributeLang(
  attrs: any,
  attrName: string,
  prefix: string = 'Name',
): string {
  if (attrs && attrs[attrName] && attrs[attrName][0]) {
    return (
      attrs[attrName][0][`${i18n.language}${prefix}`] ||
      attrs[attrName][0][`ru${prefix}`] ||
      ''
    )
  }
  return ''
}

export const fillResultWithFields = (result: any[], fieldSet) => {
  Object.keys(fieldSet).forEach(key => {
    const item = fieldSet[key]
    if (item.value) {
      const proccessedData = item.handler(item.value)
      if (proccessedData) {
        result.push(proccessedData)
      }
    }
  })
}

/**
 * Insert commonField to result array
 * @param {object} obj
 * @param {array} result
 */
export const addIfExist = (obj, result) => {
  const { item, path, name, prefix } = obj
  const field = getPropertyByNameString(item, path)
  if (field !== null) {
    result.push(createCommonField(name, getLangTitle(field, prefix)))
  }
}

export const addProperty = (name, item, result) => {
  if (item[name]) {
    result.push(createCommonField(name, item[name]))
  }
}

/**
 *
 * @param {array} array - arr of names
 * @return {Array}
 */
export const getDistinctNameArray = (array): string[] => {
  if (!array) return []
  const interpretationNameSet = new Set<string>()
  array.forEach(interpretation => {
    interpretationNameSet.add(getLangTitle(interpretation).trim())
  })
  return Array.from(interpretationNameSet)
}

/**
 *
 * @param {array} array - arr of names
 * @return {Array}
 */
export const getDistinctNameArrayGraph = (array): string[] => {
  if (!array) return []
  const interpretationNameSet = new Set<string>()
  array.forEach(interpretation => {
    interpretationNameSet.add(getLangTitleGraph(interpretation))
  })
  return Array.from(interpretationNameSet)
}

/**
 *
 * @param {array} array - arr of names
 * @return {Array}
 */
export const getNameV2Graph = (array): string[] => {
  if (!array) return []
  return array.map(obj => obj.name.trim()).sort((n1, n2) => n1.count < n2.count)
}

export const removeEmptyElements = array => {
  if (!array) return []
  return array.filter(item => {
    return item !== null && item.trim() !== ''
  })
}

export function fullNameToShort(names) {
  return (
    names.public_name ||
    `${names.last_name || ''} ${names.first_name[0] || ''}.${names
      .parent_name[0] || ''}.`
  )
}

export function getFullName(names) {
  if (names.last_name) {
    return `${names.last_name} ${names.first_name} ${names.parent_name}`
  }
  let name = getLangTitle(names)
  if (names.interpretations && names.interpretations[0]) {
    const interName = getLangTitle(names.interpretations[0])
    if (name === '-') {
      name = interName
    }
  }
  return name
}

export function getCoauthors(array) {
  if (!array || array.length === 0) return '-'
  const result = []
  array.forEach(item => {
    const author = item.node || item
    const publicName = author.public_name || author.publicName
    result.push(
      <Link key={author.uid} to={`/show/author/${author.uid}`}>
        {cyrillicToTranslit().t(publicName, i18n.language)}
      </Link>,
    )
  })
  return result
}

export function getResearches(array) {
  if (!array || array.length === 0) return '-'
  const result = []
  array.forEach(res => {
    result.push(
      <Link key={res.uid} to={`/show/${getResearchUrl(res.label)}/${res.uid}`}>
        {getLangTitle(res)}
      </Link>,
    )
  })
  return result
}

export function getCultures(array) {
  if (!array || array.length === 0) return '-'
  const result = []
  array.forEach(item => {
    result.push(
      <Link key={item.uid} to={`/show/subjects/Culture/${item.uid}`}>
        {getLangTitle(item)}
      </Link>,
    )
  })
  return result
}

export function getCultureUid(array) {
  if (!array || array.length === 0) return ''
  return array[0].node.uid
}

export function getCulturesGraph(array, isCommon, needCount = false) {
  const result = []
  const cultures = {}
  if (array && array.length) {
    array.forEach(culture => {
      const item = culture.node
      if (!cultures[item.uid]) {
        const title = isCommon
          ? getLangTitleGraph(item.commonName)
          : getLangTitleGraph(item)

        cultures[item.uid] = { count: 1, title }
      } else {
        cultures[item.uid].count++
      }
    })
  }

  Object.keys(cultures).map(key => {
    const title = `${cultures[key].title} ${
      needCount && cultures[key].count > 1 ? `(${cultures[key].count})` : ''
    }`
    result.push(
      <Link
        className="block-link"
        key={key}
        to={`/show/subjects/Culture/${key}`}
      >
        {title}
      </Link>,
    )
  })

  return result.length ? result : '-'
}

export function getSubjectsGraph(array, isCommon) {
  if (!array || array.length === 0) return ''
  const result = []
  array.forEach(subj => {
    const item = subj.node
    const title = isCommon
      ? getLangTitleGraph(item.commonName)
      : getLangTitleGraph(item)
    result.push(
      <Link key={item.uid} to={`/show/subjects/${item.label}/${item.uid}`}>
        {title}
      </Link>,
    )
  })
  return result
}

export function getSourceGraph(array) {
  if (!array || array.length === 0) return '-'
  const result = []
  array.forEach(subj => {
    const item = subj.node
    result.push(
      <Link key={item.uid} to={`/show/source/${item.label}/${item.uid}`}>
        {getLangTitleGraph(item)}
        {item.label ? (
          <Tag color="cyan" style={{ paddingLeft: '5px' }}>
            {i18n.t(`dataPanel.title.${item.label.toLowerCase()}`)}
          </Tag>
        ) : (
          ''
        )}
        {getAccessPermissionTags(item)}
      </Link>,
    )
  })
  return result
}

export function getLayerSortParamFromAttributes(item) {
  if (item.interpretation) {
    if (item.attributes) {
      item.attributes.forEach((attribute, attrIndex) => {
        if (attribute.type === 'Epoch') {
          return attribute.sort || -1
        }
        return -1
      })
    }
  } else if (item.interpretations) {
    if (item.attributes.Epoch) {
      return item.attributes.Epoch[0].sort || -1
    }
  }
  return -1
}

export function sortLayerByAttributeEpoch(a, b) {
  const aSort = getLayerSortParamFromAttributes(a)
  const bSort = getLayerSortParamFromAttributes(b)
  return aSort - bSort
}

export function sortReportByYear(a, b) {
  return b.year - a.year
}

export function getLayerSortParamFromAttributesGraph(item) {
  return getPropertyByNameString(item, 'node.epoch.edges.0.node.sort') || -1
}

export function sortLayerByAttributeEpochGraph(a, b) {
  const aSort = getLayerSortParamFromAttributesGraph(a)
  const bSort = getLayerSortParamFromAttributesGraph(b)
  return aSort - bSort
}

export function sortArray(data, fieldName = 'name') {
  return data.sort((a, b) => {
    if (a[fieldName] > b[fieldName]) {
      return 1
    }
    if (a[fieldName] < b[fieldName]) {
      return -1
    }
    return 0
  })
}

export function sortChrono(first, second) {
  const a = first[1]
  const b = second[1]
  const aStart = parseInt(a.dateStart, 10)
  const bStart = parseInt(b.dateStart, 10)
  const aEnd = parseInt(b.dateStart, 10)
  const bEnd = parseInt(b.dateStart, 10)
  if (aStart > bStart) {
    return -1
  }
  if (aStart < bStart) {
    return 1
  }
  if (aEnd > bEnd) {
    return -1
  }
  if (aEnd < bEnd) {
    return 1
  }
  return 0
}

export const getChrono = research => {
  const item = research.node

  const authors = [...(getPropertyByNameString(item, 'author.edges') || [])]
  let date = ''
  let chronologyCommon = getPropertyByNameString(item, 'edgeSubject.0')
  if (!chronologyCommon) {
    chronologyCommon = getPropertyByNameString(item, 'edgeResearch.0')
  }
  if (chronologyCommon && chronologyCommon.dateStart) {
    date += `${chronologyCommon.dateStart || ''} ~ ${chronologyCommon.dateEnd ||
      ''}`
  }
  const researchData =
    getPropertyByNameString(chronologyCommon, 'research') || item

  if (!authors.length && chronologyCommon && chronologyCommon.linkedByNode) {
    if (chronologyCommon.research.author) {
      authors.push({ node: chronologyCommon.research.author })
    } else {
      authors.push({ node: chronologyCommon.linkedByNode })
    }
  }
  let authorText = ''
  authors.forEach(author => {
    authorText += `${cyrillicToTranslit().t(
      author.node.publicName,
      i18n.language,
    )}, `
  })
  const year = (researchData && researchData.year) || '-'

  if (date) {
    return {
      uid: researchData.uid,
      label: researchData.label,
      name: `${authorText}${year}`,
      date,
      dateStart: chronologyCommon.dateStart || '',
      dateEnd: chronologyCommon.dateEnd || '',
    }
  }
  return null
}

export const getChronoMap = researches => {
  const chronoMap = new Map()
  researches.forEach(research => {
    const item = getChrono(research)
    if (item) {
      if (chronoMap.has(item.date)) {
        const current = chronoMap.get(item.date)
        current.authors.push(item)
      } else {
        chronoMap.set(item.date, {
          dateStart: item.dateStart,
          dateEnd: item.dateEnd,
          authors: [item],
        })
      }
    }
  })
  return chronoMap
}

export const getSortedChronoLinkArray = array => {
  const chronos = Array.from(array)
  const sorted = chronos.sort(sortChrono)
  return sorted.map(item => {
    const date = item[0]
    let { authors } = item[1]
    const uniqAuthors = {}
    authors.forEach(author => {
      uniqAuthors[author.uid] = author
    })
    authors = Object.values(uniqAuthors)

    const authorLinks = authors.map((element, index) => {
      const isLast = index === item[1].authors.length - 1
      return (
        <span className="inline-links">
          <Link
            key={element.uid}
            to={`/show/${getResearchUrl(element.label) || element.label}/${
              element.uid
            }`}
          >
            {` ${element.name}${isLast ? '' : ';'}`}
          </Link>
        </span>
      )
    })
    return createCommonField(
      null,
      <div>
        {date}
        {':'}
        {authorLinks}
      </div>,
    )
  })
}

export const generateCipher = item => {
  const keys = [
    'fond_number',
    'inventory_number',
    'save_number',
    'sheet_number',
  ]
  const { place_data: placeData } = item
  const placeArr = []
  if (!placeData) return '-'
  placeData.forEach(place => {
    let result = `${getLangTitle(place.organization)}. `
    if (place) {
      keys.forEach(key => {
        if (place[key]) {
          if (key === 'inventory_number') {
            result += '№ '
          }
          result += `${place[key]}. `
        }
      })
    }
    placeArr.push(<span>{result}</span>)
  })
  return placeArr
}

export const getDates = chronology => {
  const dateArray = []
  if (chronology) {
    chronology.forEach(item => {
      dateArray.push(
        <span>{`${item.dateStart || ''} ~ ${item.dateEnd || ''}; `}</span>,
      )
    })
    return dateArray
  }
  return dateArray
}

export const insertCollabInNode = (
  array: any[],
  key: string = 'collab',
): any[] => {
  if (array || array.length !== 0) {
    return array.map(item => {
      if (item && item.node) {
        return {
          node: {
            ...item.node,
            [key]: true,
          },
        }
      }
      return item
    })
  }
  return []
}

export const filterEdges = (array: any[]): any[] => {
  const uids = []
  return [...array].filter(item => {
    if (uids.includes(item.node.uid)) return false
    uids.push(item.node.uid)
    return true
  })
}

export const pushCount = (itemObj, item, fieldName) => {
  if (item[fieldName] && item[fieldName].totalCount !== undefined) {
    itemObj.content.push(
      createCommonField(
        i18n.t(`dataPanel.count.${fieldName}`),
        item[fieldName].totalCount || 0,
      ),
    )
  }
}

export const getCount = (item, fieldName, translitFieldName = null) => {
  if (item[fieldName] && typeof item[fieldName] === 'number') {
    const fieldLabel = i18n.t(
      `dataPanel.count.${translitFieldName || fieldName}`,
    )
    return <Tag>{`${fieldLabel}: ${item[fieldName] || 0}`}</Tag>
  }
  if (item[fieldName] && item[fieldName].totalCount !== undefined) {
    const fieldLabel = i18n.t(
      `dataPanel.count.${translitFieldName || fieldName}`,
    )
    return <Tag>{`${fieldLabel}: ${item[fieldName].totalCount || 0}`}</Tag>
  }
  return null
}
