import React, { useState, PureComponent } from 'react'
import { Drawer, Button, Switch, Icon, Tooltip } from 'antd'
import { translate } from 'react-i18next'
import getUrl from 'utility/images'
import { connect } from 'react-redux'
import { actions as actionsE } from 'redux/modules/example';

import LeftPanelButton from './LeftPanelButton'

const mapStateToProps = state => ({
  user: state.user,
  userEditMode: state.example.editMode,
  showPage: state.example.showPage
})

const mapDispatchToProps = {
  editModeToggle: actionsE.editToggle,
};

class DataLeftPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      drawerVisible: false
    }
  }

  toggleDrawer = () => {
    this.setState({ drawerVisible: !this.state.drawerVisible })
  }

  render() {
    const { t, buttons, editModeToggle, userEditMode, user } = this.props
    const { drawerVisible } = this.state

    return (
      <div className="data-left-panel">
        <Button type="link" onClick={this.toggleDrawer}>
          <img src={getUrl('common_icons/wrench.webp')} width={24}/>
        </Button>
        <Drawer
          width={50}
          placement="left"
          closable={false}
          onClose={this.toggleDrawer}
          visible={drawerVisible}
          className="data-left-panel-drawer"
        >
          <Tooltip placement="right" title={t(`dataPanel.button.edit_switcher`)}>
            <Switch checkedChildren={<Icon type="edit"/>}
                    unCheckedChildren={<Icon type="edit"/>}
                    className="edit-switcher"
                    onClick={editModeToggle}
                    checked={userEditMode}
            />
          </Tooltip>
          {buttons && buttons.map((item, key) => {
            if (item.method === 'delete' &&  user.role !== 'ad')
              return null
            else
              return (
                <LeftPanelButton t={t} content={item}/>
            )}
          )}
        </Drawer>
      </div>
    )
  }
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(DataLeftPanel))
